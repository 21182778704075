<header>
  <div class="wpart">
    <div class="top-header widget_edit_enabled">
      <div class="container">
        <div class="row title-bar">
          <div class="details-title">
            <div class="header-address">
              <a class="hide_table" href="tel://38501123321">
                <!-- <i class="fa fa-phone-square"></i> -->
                <span> </span>
              </a>
              <a class="hide_table">
                <i class="fa fa-envelope-o"></i>
                <span>contact@rentalreviews365.com</span>
              </a>
            </div>
          </div>

          <div class="social-links-title">
            <div class="header-social d-none d-sm-none d-md-block">
              <a (click)="onfacebook()"><i class="fa fa-facebook"></i></a>

              <a (click)="ontwitter()" target="_blank"
                ><i class="fa fa-twitter"></i
              ></a>

              <a (click)="onlinkedin()" target="_blank"
                ><i class="fa fa-linkedin"></i
              ></a>

              <a (click)="oninstagram()" target="_blank"
                ><i class="fa fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-country-list"
      id="country-modal"
      tabindex="-1"
      role="dialog"
    ></div>

    <div class="header widget_edit_enabled">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <nav class="navbar navbar-expand-lg navbar-light">
              <a (click)="openHomePage()">
                <img
                  src="assets/img/logo-rental.png"
                  alt="Company Logo"
                  class="img-fluid pointer image"
                  
                />
              </a>

              <button
                class="menu-button"
                type="button"
                (click)="onmenuclick()"
              >
                <span class="icon-spar"></span>
                <span class="icon-spar"></span>
                <span class="icon-spar"></span>
              </button>
              <div class="navbar-collapse collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-auto" id="main-menu1">
                  <li class="nav-item dropdown pointer nav-home pointer">
                    <a
                      class="nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="openHomePage()"
                      >Home</a
                    >
                  </li>
                  <li class="nav-item dropdown nav-search pointer">
                    <a
                      class="nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="openSearchPage()"
                      >Search</a
                    >
                  </li>
                  <li class="nav-item dropdown pointer nav-blog">
                    <a
                      class="nav-link"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="openBlogPage()"
                      >Blog</a
                    >
                  </li>

                  <li class="nav-item dropdown pointer nav-about">
                    <a
                      class="nav-link nav-about"
                      aria-haspopup="true"
                      aria-expanded="false"
                      (click)="openAboutPage()"
                      >About
                    </a>
                  </li>
                  <li class="nav-item dropdown pointer nav-contact">
                    <a class="nav-link" (click)="openContactPage()">Contact</a>
                  </li>
                </ul>
                <div class="d-inline my-2 my-lg-0">
                  <ul class="navbar-nav">
                    <li class="nav-item nav-sigin signin-btn">
                      <span class="nav-link link_dropdown">
                       
                        <span id="not_login">
                          <i class="fa fa-sign-in signin-style"></i>
                          <a (click)="openSignin()" class="login_popup_enabled">
                            <b class="signin-op pointer">Sign in</b>
                          </a>
                          or
                          <a (click)="openRegister()" class="">
                            <b class="reg-op pointer">Register</b>
                          </a>
                        </span>
                        <span id="already_login">
                            <span class="nav-item dropdown" ngbDropdown>
                              <a class="nav-link dropdown-toggle custom-dashboard-link pointer"  ngbDropdownToggle>Dashboard</a>
                              <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item pointer" (click)="onReviewClick()">My Review</a>
                                <a class="dropdown-item pointer" (click)="onProfileClick()">My Profile</a>
                                <a class="dropdown-item pointer" (click)="onChangePasswordClick()">Change Password</a>
                                <a class="dropdown-item pointer" (click)="onLogoutClick()">Logout</a>
                              </div>
                            </span>
                         
                        <!-- </ul> -->
                        </span>
                      </span>
                    </li>
                    <li class="nav-item submit-btn nav-submit">
                      <a
                        (click)="openSubmitReview()"
                        class="my-2 my-sm-0 nav-link sbmt-btn pointer"
                        style="background-color: #13a89e;"
                      >
                        <i
                          class="icon-plus pointer"
                          style="color: #fcfcfc;"
                        ></i>
                        <span style="color: #fcfcfc;">Submit Review</span>
                      </a>
                    </li>
                    <li class="nav-item signin-btn d-sm-block d-md-none"></li>
                  </ul>
                </div>
                <a (click)="closeMenu()" class="close-menu"><i class="fa fa-close"></i></a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
