import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css','../../assets/css/application.css']
})
export class MyProfileComponent {
  phoneNumber: any;
  username: any;
  email: any;
  password: any;
  dateOfBirth: any;
  authenticity: any = 0;
  twitter: any;
  isActive: any;
  isActiveKYC: any;
  facebook: any;
  snapchat: any;
  instagram: any;
  linkedIn: any;
  user_current_latitude: any;
  user_current_longitude: any;
  user_native_country_id: any;
  display_success_msg: any;
  display_failed_msg: any;
  country: any;
  created_by: any;
  updated_by: any;
  created_datetime: any;
  updated_datetime: any;
  user_documents: any;
  user_role_id: any;
  user_id: any;
  full_name: any;
  countries: any[] = [];
  draggedFiles: any;
  filelength: any;
  MapColumns: any[] = [];
  documentDetails: any[] = [];
  comments: any;
  document_type: any;
  document_number: any;
  is_disabled: boolean = false;
  approved: boolean = true;
  showDocumentTable: boolean = false;
  deletePopup: boolean = false;
  changevalue: any;
  errorMessages: string[] = [];
  @ViewChild('file')myInputVariable!: ElementRef;
  ngOnInit() {
    window.scrollTo(0,0);
    $('#user_alert_success').hide();
    $('#user_alert_danger').hide();
    $('.wrapper-loader').show();
    $('#user_alert_success').hide();
    $('#file_alert_success').hide();
    $('#user_alert_danger').hide();
    $('#file_alert_danger').hide();

    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    this.user_id = user.user_id;
  this.user_role_id=user.user_role_id;

    this.http
      .post(CommonService.api_url + '/user-edit', {
        user_id: this.user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          let responseDataUser = responseData['data'];
          this.username = responseDataUser.user_name;
          this.phoneNumber = responseDataUser.mobile_no;
          this.email = responseDataUser.user_email;
          this.password = responseDataUser.password;
          this.user_current_latitude = responseDataUser.user_current_latitude;
          this.user_current_longitude = responseDataUser.user_current_longitude;
          this.dateOfBirth = responseDataUser.user_birthdate;
          this.authenticity = responseDataUser.user_authenticity;
          this.user_native_country_id = responseDataUser.user_native_country_id;
          this.facebook = responseDataUser.facebook_profile_link;
          this.twitter = responseDataUser.twitter_profile_link;
          this.instagram = responseDataUser.instagram_profile_link;
          this.snapchat = responseDataUser.snapchat_profile_link;
          this.linkedIn = responseDataUser.linkedin_profile_link;
          this.isActiveKYC = responseDataUser.is_kyc_validated;
          this.isActive = responseDataUser.is_active;
          this.created_by = responseDataUser.created_by;
          this.updated_by = responseDataUser.updated_by;
          this.created_datetime = responseDataUser.created_datetime;
          this.updated_datetime = responseDataUser.updated_datetime;
          this.user_documents = responseDataUser.user_documents;
          this.user_role_id = responseDataUser.user_role_id;
          this.user_id = responseDataUser.user_id;
          this.full_name = responseDataUser.full_name;
          this.documentDetails = responseDataUser.user_documents;
          if (this.documentDetails.length > 0) {
            this.showDocumentTable = true;
          }
          else{
            this.showDocumentTable = false;
          }
          if (responseDataUser.is_active == 'Yes') {
            this.isActive = true;
          } else {
            this.isActive = false;
          }
          if (responseDataUser.is_kyc_validated == 'Yes') {
            this.isActiveKYC = true;
          } else {
            this.isActiveKYC = false;
          }
          // if (responseDataUser.is_active == 'Yes') {
          //   this.isActive = true;
          // } else {
          //   this.isActive = false;
          // }
          // if (responseDataUser.is_kyc_validated == 'Yes') {
          //   this.isActiveKYC = true;
          // } else {
          //   this.isActiveKYC = false;
          // }
          $('.wrapper-loader').hide();
          // this.documentDetails =[{
          //   document_url:"file1"
          // },{
          //   document_url:"file1"
          // },
          // {
          //   document_url:"file3"
          // },]
        } else {
          $('.wrapper-loader').hide();
          this.display_failed_msg = responseData['errors'];
          $('#user_alert_danger').html(responseData['errors']);
          $('#user_alert_success').hide();
          $('#user_alert_danger').show();
          window.scrollTo(0, 0);
        }
      });

    // $(document).ready(function () {
    //   setTimeout(() => {
    //     $('#user_douments_table').css('opacity', '1');
    //     var table = $('#user_douments_table').DataTable({});
    //   }, 2000);
    // });
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onUpdate() {
    $('#user_alert_success').hide();
    $('#user_alert_danger').hide();
    if (!this.validation()) {
      return;
    } else {
      let user_id1: any;
      let isActive: any;
      let isActiveKYC: any;
      // let login_details: any = localStorage.getItem('login_user_details');
      // let login = JSON.parse(login_details);
      // let updated_by = parseInt(login['data']['user_id']);
      // user_id1 = localStorage.getItem('user_id');
      if (this.isActive == true) {
        isActive = 'Yes';
      } else {
        isActive = 'No';
      }
      if (this.isActiveKYC == true) {
        isActiveKYC = 'Yes';
      } else {
        isActiveKYC = 'No';
      }
      let user_id = parseInt(user_id1);
      let   user_role_id = parseInt(this.user_role_id);
      this.http
        .post(CommonService.api_url + '/user-update', {
          user_role_id:this.user_role_id, 
          user_id: this.user_id,
          user_name: this.username,
          user_email: this.email,
          password: this.password,
          mobile_no: this.phoneNumber,
          user_birthdate: this.dateOfBirth,
          facebook_profile_link: this.facebook,
          twitter_profile_link: this.twitter,
          instagram_profile_link: this.instagram,
          snapchat_profile_link: this.snapchat,
          linkedin_profile_link: this.linkedIn,
          is_kyc_validated: isActiveKYC,
          user_native_country_id: this.user_native_country_id,
          user_authenticity: this.authenticity,
          is_active: isActive,
          full_name: this.full_name,
          updated_by: this.user_id,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((responseData: any) => {
          if (responseData['is_successful'] === '1') {
            $('.wrapper-loader').hide();
            this.display_success_msg = responseData['success_message'];
            $('#user_alert_success').html(responseData['success_message']);
            $('#user_alert_success').show();
            $('#user_alert_danger').hide();
            window.scrollTo(0, 0);

            setTimeout(() => {
              this.router.navigateByUrl('/home');
            }, 2000);
          } else {
            $('.wrapper-loader').hide();
            $('#relsult_arrange').hide();
            // this.display_failed_msg = responseData['errors'];
            // $('#user_alert_danger').html(responseData['errors']);
            this.errorMessages=responseData['errors'];
            $('#file_alert_success').hide();
            $('#error_new').show();
            window.scrollTo(0, 0);
          }
        });
    }
  }
  getFile(event: any) {
    $('#file_alert_success').hide();
    $('#file_alert_danger').hide();
        if (this.validationforFile() == true) {
      $('#file_alert_danger').hide();
      $('#file_alert_success').hide();
      CommonService.file = null;
      CommonService.uploadData = new FormData();

      if (event.target.files.length > 0) {
        $('.wrapper-loader').show();

        if (CommonService.file == null) {
          CommonService.uploadData = new FormData();
          $('#file_alert_danger').hide();
          $('#file_alert_success').hide();
          let approved: any;
          this.draggedFiles = true;
          const file: File = event.target.files[0];
          this.filelength = event.target.files.length;
          const uploadData = new FormData();
          if (this.approved == true) {
            approved = 'Yes';
          }
          // this.user_id = localStorage.getItem('user_id');
          CommonService.uploadData.append('user_id', this.user_id);
          CommonService.uploadData.append('document_type', this.document_type);
          CommonService.uploadData.append('files', file);
          CommonService.uploadData.append(
            'document_number',
            this.document_number
          );
          CommonService.uploadData.append('comments', this.comments);
          CommonService.uploadData.append('is_approved', approved);
          CommonService.uploadData.append('created_by', '1');
          let fileName: any;
          fileName = file.name;

          let result = fileName.lastIndexOf('.');
          let result1 = fileName.substring(result);
          if (
            result1 == '.jpg' ||
            result1 == '.png' ||
            result1 == '.bmp' ||
            result1 == '.gif' ||
            result1 == '.pdf'
          ) {
            // uploadData=this.filelength,

            this.http
              .post(
                CommonService.api_url + '/file-upload',
                CommonService.uploadData
              )
              .subscribe((res: any) => {
                if (res['is_successful'] == '1') {
                  CommonService.filename = 'File Name:' + '  ' + file.name;
                  $('.wrapper-loader').hide();
                  $('#file_alert_danger').hide();
                  $('#file_alert_success').show();
                  this.display_success_msg = 'File is uploded successfully.';
                  $('#file_alert_success').html('File is uploded successfully.');
                  CommonService.file = null;
                  CommonService.uploadData = new FormData();
                  // window.scrollTo(0, 0);
                  setTimeout(() => {
                  this.OnPage();
                  }, 1000);
                } else {
                  CommonService.file = null;
                  $('.wrapper-loader').hide();
                  $('#file_alert_success').hide();
                  $('#file_alert_danger').show();
                  $('.box-area').css('top', 190 + 'px');
                  $('#file_alert_danger').html(res['errors']);
                }
              });
          } else {
            CommonService.file = null;
            $('.wrapper-loader').hide();
            $('#file_alert_success').hide();
            $('#file_alert_danger').show();
            this.display_failed_msg =
              'Please select .jpg, .png, .gif, .bmp,.pdf file. <br/>';
            $('#file_alert_danger').html(
              'Please select .jpg, .png, .gif, .bmp,.pdf file. <br/>'
            );
            // window.scrollTo(0, 0);
          }
        } else {
          CommonService.file = null;
          $('.wrapper-loader').hide();
          $('#file_alert_success').hide();
          $('#file_alert_danger').show();
          $('#file_alert_danger').html('Only one file can uploaded. <br/>');
        }
      }
    } else {
      CommonService.file = null;
      return;
    }
  }
  updateTextInput(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.authenticity = +inputValue; // Convert to a number
  }
  onClose() {
    this.router.navigateByUrl('/home');
  }
  onDelete() {
    if (confirm("Are you sure you want to delete this record?")) {
    
    let user: any = localStorage.getItem('user_id');
    let user_id = parseInt(user);
    this.http
      .post(CommonService.api_url + '/user-delete', {
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          $('.wrapper-loader').hide();
          this.display_success_msg = responseData['success_message'];
          $('#user_alert_success').html(responseData['success_message']);
          $('#user_alert_success').show();
          $('#user_alert_danger').hide();
          setTimeout(() => {
            this.router.navigateByUrl('/home');
          }, 2000);
        } else {
          $('.wrapper-loader').hide();
          this.display_failed_msg = responseData['errors'];
          $('#user_alert_danger').html(responseData['errors']);
          $('#file_alert_success').hide();
          $('#user_alert_danger').show();
          window.scrollTo(0, 0);
        }
      });
    }
  }
  validation() {
    let username = this.username;
    let email = this.email;
    let password = this.password;
    let full_name = this.full_name;
    let errorMessage = '';
    if (full_name === '' || full_name === undefined || full_name === null) {
      errorMessage += 'Please enter Full Name.<br/>';
    }

    if (email === '' || email === undefined || email === null) {
      errorMessage += 'Please enter Email.<br/>';
    }
    // if (password === '' || password === undefined || password === null) {
    //   errorMessage += 'Please enter Password.<br/>';
    // }
    if (errorMessage != '') {
      this.display_failed_msg = errorMessage;
      $('#user_alert_danger').html(this.display_failed_msg);
      $('#user_alert_danger').show();
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }
  validationforFile() {
    let errorMessage = '';

    if (this.filelength == 0) {
      errorMessage += 'Please select file.<br/>';
    }
    if (
      this.document_type == '' ||
      this.document_type == undefined ||
      this.document_type == null
    ) {
      errorMessage += 'Please enter Document Type.<br/>';
    }
    if (
      this.document_number == '' ||
      this.document_number == undefined ||
      this.document_number == null
    ) {
      errorMessage += 'Please enter Document Number.<br/>';
    }
    if (
      this.comments == '' ||
      this.comments == undefined ||
      this.comments == null
    ) {
      errorMessage += 'Please enter Comments.<br/>';
    }

    if (errorMessage != '') {
      this.display_failed_msg = errorMessage;
      $('#file_alert_danger').html(this.display_failed_msg);
      $('#file_alert_danger').show();
      this.myInputVariable.nativeElement.value = '';
      // window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }
  deleteDocument(user_document_id: any) {

    $('#file_alert_success').hide();
    $('#file_alert_danger').hide();
    if (confirm("Are you sure you want to delete this record?")) {
    //  this.deletePopup=true;
    //  localStorage.setItem('user_document_id',user_document_id);
    this.http
      .post(CommonService.api_url + '/user-document-delete', {
        user_document_id: user_document_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          $('.wrapper-loader').hide();
          this.display_success_msg = responseData['success_message'];
          $('#file_alert_success').html(responseData['success_message']);
          $('#file_alert_success').show();
          $('#file_alert_danger').hide();
          // window.scrollTo(0, 0);
            setTimeout(() => {
            this.OnPage();
            },1000);
            
        } else {
          $('.wrapper-loader').hide();
          this.display_failed_msg = responseData['errors'];
          $('#file_alert_danger').html(responseData['errors']);
          $('#file_alert_success').hide();
          $('#file_alert_danger').show();
          this.myInputVariable.nativeElement.value = '';
          // window.scrollTo(0, 0);
          
        }

      });
    }
  }
  cancelDelete() {
    this.deletePopup = false;
  }
  deleteDocumentConfirm() {
    if (confirm("Are you sure you want to delete this record?")) {
    let user_document_id: any = localStorage.getItem('user_document_id');
    this.http
      .post(CommonService.api_url + '/user-document-delete', {
        user_document_id: user_document_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          $('.wrapper-loader').hide();
          this.display_success_msg = responseData['success_message'];
          $('#file_alert_success').html(responseData['success_message']);
          $('#file_alert_success').show();
          $('#file_alert_danger').hide();
          setTimeout(() => {
          this.OnPage();
          }, 1000);
        } else {
          $('.wrapper-loader').hide();
          this.display_failed_msg = responseData['errors'];
          $('#file_alert_danger').html(responseData['errors']);
          $('#file_alert_success').hide();
          $('#file_alert_danger').show();
        }
      });
    }
  }

  viewDocumnet(document_url: any) {
    window.open(document_url);
  }
  OnPage() {
    $('.wrapper-loader').show();
    $('#user_alert_success').hide();
    $('#user_alert_danger').hide();
    CommonService.file = null;
      CommonService.uploadData = new FormData();
    // let user: any = localStorage.getItem('user_id');
    // let user_id = parseInt(user);
    // this.http
    //   .post(CommonService.api_url + '/country-list-internal', {
    //     oauth_key: CommonService.oauth_key,
    //   })
    //   .subscribe((responseData: any) => {
    //     this.countries = responseData['data'];
    //   });

    this.http
      .post(CommonService.api_url + '/user-edit', {
        user_id: this.user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          let responseDataUser = responseData['data'];
          this.username = responseDataUser.user_name;
          this.phoneNumber = responseDataUser.mobile_no;
          this.email = responseDataUser.user_email;
          this.password = responseDataUser.password;
          this.user_current_latitude = responseDataUser.user_current_latitude;
          this.user_current_longitude = responseDataUser.user_current_longitude;
          this.dateOfBirth = responseDataUser.user_birthdate;
          this.authenticity = responseDataUser.user_authenticity;
          this.user_native_country_id = responseDataUser.user_native_country_id;
          this.facebook = responseDataUser.facebook_profile_link;
          this.twitter = responseDataUser.twitter_profile_link;
          this.instagram = responseDataUser.instagram_profile_link;
          this.snapchat = responseDataUser.snapchat_profile_link;
          this.linkedIn = responseDataUser.linkedin_profile_link;
          this.isActiveKYC = responseDataUser.is_kyc_validated;
          this.isActive = responseDataUser.is_active;
          this.created_by = responseDataUser.created_by;
          this.updated_by = responseDataUser.updated_by;
          this.created_datetime = responseDataUser.created_datetime;
          this.updated_datetime = responseDataUser.updated_datetime;
          this.user_documents = responseDataUser.user_documents;
          this.user_role_id = responseDataUser.user_role_id;
          this.user_id = responseDataUser.user_id;
          this.full_name = responseDataUser.full_name;
          this.documentDetails = responseDataUser.user_documents;
          if (this.documentDetails.length > 0) {
            this.showDocumentTable = true;
          }
          else{
            this.showDocumentTable = false;
          }
          if (responseDataUser.is_active == 'Yes') {
            this.isActive = true;
          } else {
            this.isActive = false;
          }
          if (responseDataUser.is_kyc_validated == 'Yes') {
            this.isActiveKYC = true;
          } else {
            this.isActiveKYC = false;
          }

          // this.documentDetails =[{
          //   document_url:"file1"
          // },{
          //   document_url:"file1"
          // },
          // {
          //   document_url:"file3"
          // },]
          $('.wrapper-loader').hide();
        } else {
          $('.wrapper-loader').hide();
          this.display_failed_msg = responseData['errors'];
          $('#user_alert_danger').html(responseData['errors']);
          $('#user_alert_success').hide();
          $('#user_alert_danger').show();
          window.scrollTo(0, 0);
        }
      });

      this.myInputVariable.nativeElement.value = '';
      this.document_type='';
      this.document_number='';
      this.comments='';
      // var table11= $('#user_douments_table').DataTable();

  }
  openHomePage(){
    this.router.navigateByUrl('/home');
  }
}
