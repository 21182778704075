<!doctype html>
<html class="no-js" lang="en">

<!-- Mirrored from geniuscript.com/selio-script/index.php/frontend/login by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 13 Jul 2023 07:22:02 GMT -->
<!-- Added by HTTrack -->


<head>
    <meta charset="UTF-8">
    <title>Register</title>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" ><!-- /Added by HTTrack -->
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="description" content="" >
    <meta name="keywords" content="" >
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="author" content="" >

    <meta property="og:site_name" content="Real estate point">
    <meta property="og:title" content="Real estate point - Login" >
    <meta property="og:url" content="login.html" >
    <meta property="og:description" content="" >
    <meta property="og:image" content="templates/selio/assets/img/default-image-og.webp" >

    <link rel="shortcut icon" href="templates/selio/assets/img/favicon.png" type="image/png" >
    <link rel="canonical" href="login.html" >
    <link
        href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
        rel="stylesheet">

</head>

<body>
    <div class="wrapper">
     <app-header></app-header>
        <main class="main-clear">
            <div class="selio_sw_win_wrapper">
                <div class="ci sw_widget sw_wrap">
                    <ul class="nav nav-tabs d-none sw-sign-form-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link log-in" href="#log-in-form" role="tab" data-toggle="tab">Log in</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link sign-up" href="#sign-up-form" role="tab" data-toggle="tab">Sign Up</a>
                        </li>
                    </ul>
                    <div class="sign-form-wr">
                        <div class="sign-form-inner tab-content">
                            <!-- Log In -->
                            <div class="form-wr sign-up-form tab-pane fade active show" role="tabpanel"
                                id="sign-up-form">
                                <h3>Register</h3>
                                <div class="form-wr-content">
                                    <div class="row row_align">
                                    <div class="alert alert-success" role="alert">
                                      </div>
                                      <div class="alert alert-danger" role="alert">
                                      </div>
                                    </div>
                                    <form method="post" action="#" id="register_form">
                                        <div class="form-field">
                                            <input type="text" name="name_surname" value="" class="form-control"
                                                id="inputNameSurname" placeholder="Full Name" [(ngModel)]="fullname"   [ngModelOptions]="{ standalone: true }">
                                        </div>
                                        <div class="form-field">
                                            <input type="email" name="mail" value="" class="form-control" id="inputMail"
                                                placeholder="Email" [(ngModel)]="email"    [ngModelOptions]="{ standalone: true }">
                                        </div>
                                        <div class="form-field">
                                            <input type="password" name="password" value="" class="form-control"
                                                id="inputPassword" placeholder="Password" autocomplete="new-password" [(ngModel)]="password"    [ngModelOptions]="{ standalone: true }">
                                        </div>
                                        <div class="form-field">
                                            <input type="password" name="password_confirm" value="" class="form-control"
                                                id="inputPasswordConfirm" placeholder="Confirm password"
                                                autocomplete="new-password" [(ngModel)]="confirmPassword">
                                        </div>
                                        <div class="form-field">
                                           <select class="form-select radius" [(ngModel)]="selected_role"    [ngModelOptions]="{ standalone: true }">
                                            <option value="Renter">Renter</option>
                                            <option value="Landlord">Landlord</option>
                                           </select>
                                        </div>

                                        <div class="form-field">
                                            <input type="text" name="phone" value="" class="form-control"
                                                id="inputPhone" placeholder="Phone"   [(ngModel)]="phone" [ngModelOptions]="{ standalone: true }">
                                        </div>


                                        <button type="submit" (click)="onCreateAccount()" class="btn2">Create Account</button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>
      <app-footer></app-footer>
        <!--footer end-->
    </div><!--wrapper end-->


    <div class="se-pre-con"></div>

    <!-- Start JS MAP  -->
    </body>

</html>

