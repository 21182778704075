<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Home</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="Fullscreen" />
    <meta name="keywords" content="Fullscreen" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Fullscreen" />
    <meta property="og:url" content="#" />
    <meta property="og:description" content="Fullscreen" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
  </head>

  <body class="modal-backdrop-effect dissable-sticky">
    <div class="container_main">
      <div class="wrapper">
        <app-header></app-header>
        <div class="wpart">
          <div class="banner-container">
            <section class="banner widget_edit_enabled">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">

                    <div
                    class="banner-content-monotype-corsiva title-container"
                    style="margin-top: -100px;">
                    <h1 class="most-text1 banner-content-monotype-corsiva" [innerHTML]="home_page_title" ></h1>
                  </div>


                    <!-- <div
                      class="banner-content title-container"
                      style="font-family: Monotype Corsiva ;margin-top: -100px;"
                    >
                      <h1 class="most-text1" [innerHTML]="home_page_title" style="font-family: Monotype Corsiva"></h1>

                    </div> -->

                    <div class="container">
                      <form
                        action="#"
                        class="row banner-search search-form top-search banner-search_init"
                      >
                        <div class="banner-search_box banner-search row">
                          <div class="form_field">
                            <div
                              class="form-group field_search_-"
                              style="width: 100%"
                            >
                              <input
                                id="search_option_smart"
                                name="search_option_smart"
                                value=""
                                type="text"
                                [(ngModel)]="search_text"
                                [ngModelOptions]="{ standalone: true }"
                                class="form-control"
                                placeholder="Address"
                              />
                            </div>
                          </div>

                          <div class="form_field" style="width: 30%">
                            <div class="form-group">
                              <select
                                class="drp_filter"
                                (change)="onChangeState($event)"
                                [(ngModel)]="state_id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <option value="0">Select State</option>
                                <option
                                  *ngFor="let data of state_list"
                                  [value]="data.state_id"
                                >
                                  {{ data.state_name }}
                                </option>
                              </select>
                            </div>
                            <!-- /.select-wrapper -->
                          </div>
                          <div class="form_field" style="width: 30%">
                            <div class="form-group">
                              <select
                                class="drp_filter"
                                (change)="onChangeCity($event)"
                                [(ngModel)]="city_id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <option value="0">Select City</option>
                                <option
                                  *ngFor="let data of city_list"
                                  [value]="data.city_id"
                                >
                                  {{ data.city_name }}
                                </option>
                              </select>
                              <br />
                              <div
                                class="alert alert-danger alert-city"
                                role="alert"
                              ></div>
                            </div>
                            <!-- /.select-wrapper -->
                          </div>

                          <div
                            class="form_field form_field_alt"
                            style="width: 30%"
                          >
                            <div class="form-group form-group-tree-alt">
                              <select
                                class="drp_filter"
                                [(ngModel)]="city_area_id"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <option value="0">Select Area</option>
                                <option
                                  *ngFor="let data of city_area_list"
                                  [value]="data.city_area_id"
                                >
                                  {{ data.city_area }}
                                </option>
                              </select>
                              <br />
                              <div
                                class="alert alert-danger alert-area"
                                role="alert"
                              ></div>
                            </div>
                          </div>

                          <div class="form_field_sw_range col-md-3">
                            <div class="form-group">
                              <div
                                class="scale-range sw_scale_range"
                                id="nonlinear-price"
                              >
                                <div
                                  class="hidden config-range"
                                  data-min="0"
                                  data-max="91000"
                                  data-sufix=""
                                  data-prefix="$"
                                  data-infinity="false"
                                  data-predifinedMin=""
                                  data-predifinedMax=""
                                ></div>

                                <ngb-rating
                                  [max]="5"
                                  [(rate)]="currentRate"
                                  [readonly]="false"
                                  [(ngModel)]="rating"
                                  [ngModelOptions]="{ standalone: true }"
                                ></ngb-rating>
                              </div>
                            </div>
                          </div>
                          <div
                            class="form_field srch-btn form_field_save"
                            (click)="searchProperty()"
                          >
                            <a
                              class="btn btn-outline-primary sw-search-start slim"
                            >
                              <i class="fa fa-search"></i>
                              <i
                                class="fa fa-spinner fa-spin fa-ajax-indicator hidden"
                              ></i>
                            </a>
                          </div>

                          <div
                            class="clearfix row-flex"
                            id="form-addittional"
                            style="display: none"
                          >
                            <div class="form_field - field_search_2">
                              <div class="form-group">
                                <select
                                  name="search_2"
                                  class="form-control selectpicker"
                                  title="Type"
                                  multiple="multiple"
                                >
                                  <option value="0">Apartment</option>
                                  <option value="1">House</option>
                                  <option value="2">Land</option>
                                  <option value="3">Commercial property</option>
                                </select>
                              </div>
                            </div>
                            <div class="form_field sf_input">
                              <div class="form-group field_search_3">
                                <div class="drop-menu">
                                  <div class="select">
                                    <span>Area</span>
                                    <i class="fa fa-angle-down"></i>
                                  </div>
                                  <input
                                    type="hidden"
                                    id="search_option_3"
                                    name="search_option_3"
                                    value=""
                                  />
                                  <ul class="dropeddown">
                                    <li>Area</li>
                                    <li data-value="Less than 50m2">
                                      Less than 50m2
                                    </li>
                                    <li data-value="50-100m2">50-100m2</li>
                                    <li data-value="More than 100m2">
                                      More than 100m2
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div class="form_field sf_input">
                              <div class="form-group field_search_20">
                                <input
                                  id="search_option_20_from"
                                  type="text"
                                  class="form-control"
                                  placeholder="Min Bedrooms"
                                  value=""
                                />
                              </div>
                            </div>

                            <div class="form_field srch-btn form_field_save">
                              <a class="btn btn-outline sw-search-start slim">
                                <i class="fa fa-search"></i>
                                <i
                                  class="fa fa-spinner fa-spin fa-ajax-indicator hidden"
                                ></i>
                              </a>
                              <button
                                type="button"
                                id="search-save"
                                class="btn btn-custom btn-savesearch btn-custom-secondary btn-icon"
                              >
                                <i
                                  class="fa fa-save icon-white fa-ajax-hide"
                                ></i
                                ><i
                                  class="fa fa-spinner fa-spin fa-ajax-indicator"
                                  style="display: none"
                                ></i>
                              </button>
                            </div>
                            <a
                              class="search-additional-btn"
                              id="search-additional"
                            >
                              <i class="fa fa-angle-double-down"></i>
                            </a>

                            <div class="form_field sf_input">
                              <div class="form-group field_search_20">
                                <input
                                  id="search_option_20_to"
                                  type="text"
                                  class="form-control"
                                  placeholder="Max Bedrooms"
                                  value=""
                                />
                              </div>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_31"
                            >
                              <input
                                type="checkbox"
                                name="search_option_31"
                                id="search_option_31"
                                value="trueMicrowave"
                              />
                              <label for="search_option_31">
                                <span></span>
                                <small>Microwave</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_25"
                            >
                              <input
                                type="checkbox"
                                name="search_option_25"
                                id="search_option_25"
                                value="trueDishwasher"
                              />
                              <label for="search_option_25">
                                <span></span>
                                <small>Dishwasher</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_22"
                            >
                              <input
                                type="checkbox"
                                name="search_option_22"
                                id="search_option_22"
                                value="trueAir conditioning"
                              />
                              <label for="search_option_22">
                                <span></span>
                                <small>Air conditioning</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_29"
                            >
                              <input
                                type="checkbox"
                                name="search_option_29"
                                id="search_option_29"
                                value="trueInternet"
                              />
                              <label for="search_option_29">
                                <span></span>
                                <small>Internet</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_23"
                            >
                              <input
                                type="checkbox"
                                name="search_option_23"
                                id="search_option_23"
                                value="trueCable TV"
                              />
                              <label for="search_option_23">
                                <span></span>
                                <small>Cable TV</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_27"
                            >
                              <input
                                type="checkbox"
                                name="search_option_27"
                                id="search_option_27"
                                value="trueGrill"
                              />
                              <label for="search_option_27">
                                <span></span>
                                <small>Grill</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_11"
                            >
                              <input
                                type="checkbox"
                                name="search_option_11"
                                id="search_option_11"
                                value="trueBalcony"
                              />
                              <label for="search_option_11">
                                <span></span>
                                <small>Balcony</small>
                                <b class="count"></b>
                              </label>
                            </div>
                            <div
                              class="input-field checkbox-field field_search_24"
                            >
                              <input
                                type="checkbox"
                                name="search_option_24"
                                id="search_option_24"
                                value="trueComputer"
                              />
                              <label for="search_option_24">
                                <span></span>
                                <small>Computer</small>
                                <b class="count"></b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section
            class="popular-listing hp2 section-padding widget_edit_enabled"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-6">
                  <div class="section-heading">
                    <!-- <span>Discover</span> -->
                    <h3>Popular Listing Reviews</h3>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-lg-4 col-md-6"
                  *ngFor="let data of blog_popular_listing"
                >
                  <div class="card" (click)="onReview(data.property_id)">
                    <a>
                      <div class="img-block">
                        <div class="overlay"></div>
                        <div
                          id="listing_carousel_19"
                          class="carousel slide carousel-listing"
                          data-ride="carousel"
                          data-interval="false"
                        >
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img
                                [src]="data.media_url"
                                alt="Retro old"
                                class="d-block w-100 img-fluid"
                                (click)="onReview(data.property_id)"
                              />
                            </div>
                          </div>
                          <!-- <span
                            class="carousel-control-prev disable_scroll"

                            role="button"
                            data-slide="prev"
                          >
                            <i class="fa fa-angle-left left_arrow"></i>
                          </span>
                          <span
                            class="carousel-control-next disable_scroll"

                            role="button"
                            data-slide="next"
                          >
                            <i class="fa fa-angle-right right_arrow"></i>
                          </span> -->
                        </div>
                        <div
                          class="rate-info"
                          (click)="onReview(data.property_id)"
                        >
                          <span
                            class="purpose-sale"
                            (click)="onReview(data.property_id)"
                            >Review</span
                          >
                        </div>
                      </div>
                    </a>
                    <div class="card-body">
                      <a>
                        <!-- <h3>{{ data.property_name }}</h3> -->
                        <p>
                          <i class="fa fa-map-marker"></i
                          ><span class="property-details">{{ data.property_details }}</span>
                        </p>
                      </a>
                    </div>
                    <div class="card-footer">
                      <span class="favorites-actions pull-left">
                        <i
                          class="fa fa-spinner fa-spin fa-custom-ajax-indicator"
                        ></i>
                      </span>
                      <a class="pull-right">
                        <ngb-rating
                          [max]="5"
                          [(rate)]="rate"
                          [(ngModel)]="data.review_score"
                          [readonly]="true"
                          [ngModelOptions]="{ standalone: true }"
                        ></ngb-rating>
                      </a>
                    </div>
                    <a class="ext-link"></a>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="load-more-posts">
                    <a class="btn2" (click)="onLoadMore()">Load More</a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="testimonial">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-xl-6">
                  <div class="section-heading">
                    <!-- <span>Clients Say</span> -->
                    <h3>Testimonials</h3>
                  </div>
                </div>
              </div>
              <!-- <div class="testimonail-sect  col-md-12 ">
                <div class="comment-carousel">
                  <div class="comment-info  col-md-6 " *ngFor="let data of testimonial_list">
                    <p>
                      {{ data.owner_description }}
                    </p>
                    <div class="cm-info-sec">
                      <div class="cm-img">
                        <img [src]="data.owner_image" alt="Image" />
                      </div>
                      <div class="cm-info">
                        <h3>{{ data.owner_name }}</h3>
                        <h4>{{ data.owner_designation }}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <span
                class="carousel-control-prev disable_scroll new_button_scroll"
                (click)="prevSlide()"
                role="button"
                data-slide="prev"
              >
                <i class="fa fa-angle-left left_button_scroll"></i>
              </span>
              <div class="testimonail-sect">
                <div class="comment-carousel">
                  <div class="comment-info col-md-6 testimonial_show">
                    <p class="font_paragraph">
                      {{ testimonial_list[currentIndex]?.owner_description }}
                    </p>
                    <div class="cm-info-sec">
                      <div class="cm-img">
                        <img
                          [src]="testimonial_list[currentIndex]?.owner_image"
                          alt="Image"
                        />
                      </div>
                      <div class="cm-info">
                        <h3>
                          {{ testimonial_list[currentIndex]?.owner_name }}
                        </h3>
                        <h4 class="h4_class">
                          {{
                            testimonial_list[currentIndex]?.owner_designation
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="comment-info col-md-6 testimonial_hide">
                    <p class="font_paragraph">
                      {{ testimonial_list[currentIndex1]?.owner_description }}
                    </p>
                    <div class="cm-info-sec">
                      <div class="cm-img">
                        <img
                          [src]="testimonial_list[currentIndex1]?.owner_image"
                          alt="Image"
                        />
                      </div>
                      <div class="cm-info">
                        <h3>
                          {{ testimonial_list[currentIndex1]?.owner_name }}
                        </h3>
                        <h4>
                          {{
                            testimonial_list[currentIndex1]?.owner_designation
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span
                class="carousel-control-next new_button_scroll disable_scroll"
                (click)="nextSlide()"
                role="button"
                data-slide="next"
              >
                <i class="fa fa-angle-right left_button_scroll"></i>
              </span>
            </div>
          </section>
          <!-- <section> -->
          <!-- <div class="testimonial">
              <img [src]="testimonials[currentIndex].image" alt="{{ testimonials[currentIndex].name }}">
              <p>{{ testimonials[currentIndex].quote }}</p>
              <span>{{ testimonials[currentIndex].name }}</span>
            </div>
            <button (click)="prevSlide()">Previous</button>
  <button (click)="nextSlide()">Next</button> -->
          <!-- </section> -->

          <a title="" class="widget_edit_enabled">
            <section class="cta section-padding">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="cta-text">
                      <h2>Discover a home where you'll love to stay</h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </a>

          <app-footer></app-footer>
          <div class="popup" id="sign-popup">
            <h3>Sign In to your Account</h3>
            <div class="popup-form form-wr">
              <form id="popup_form_login">
                <div class="alert alert-success m0" role="alert">
                  <b>Demo login details for Admin:</b><br />
                  Username: admin<br />
                  Password: admin<br /><br />
                  <b> Demo login details for User:</b><br />
                  Username: user<br />
                  Password: user
                </div>
                <div class="alerts-box"></div>
                <div class="form-field">
                  <input
                    type="text"
                    name="username"
                    value=""
                    class="form-control"
                    id="inputUsername"
                    placeholder="Username"
                  />
                </div>
                <div class="form-field">
                  <input
                    type="password"
                    name="password"
                    value=""
                    class="form-control"
                    id="inputPassword"
                    placeholder="Password"
                  />
                </div>
                <div class="form-cp">
                  <div class="form-field">
                    <div class="input-field">
                      <input
                        type="checkbox"
                        name="remember"
                        id="remember"
                        value="true"
                      />
                      <label for="remember">
                        <span></span>
                        <small>Remember me</small>
                      </label>
                    </div>
                  </div>
                  <a class="forgot-password" title="Forgot Password?"
                    >Forgot Password?</a
                  >
                </div>
                <button type="submit" class="btn2">
                  Sign In<i
                    class="fa fa-spinner fa-spin fa-ajax-indicator hidden ajax-indicator"
                  ></i>
                </button>
              </form>
              <a class="link-bottom">Create new account</a>
            </div>
          </div>
          <div class="se-pre-con"></div>
        </div>
      </div>
    </div>
  </body>
</html>
