<!doctype html>
<html class="no-js" lang="en">

<!-- Mirrored from geniuscript.com/selio-script/index.php/en/157/articles by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 13 Jul 2023 06:35:03 GMT -->
<!-- Added by HTTrack -->

<head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8"><!-- /Added by HTTrack -->
    <meta charset="UTF-8">
    <title>Blog</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="description" content="">
    <meta name="keywords" content="">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="author" content="">

    <meta property="og:site_name" content="Real estate point">
    <meta property="og:title" content="Real estate point - Articles">
    <meta property="og:url" content="articles.html">
    <meta property="og:description" content="">
    <meta property="og:image" content="../../../templates/selio/assets/img/default-image-og.webp">

 
</head>

<body>
    <div class="wrapper">
  <app-header></app-header>
        <section class="pager-sec bfr widget_edit_enabled">
            <div class="container">
                <div class="pager-sec-details">
                    <h3 class="pointer">Blog</h3>
                    <ul class="breadcrumb" class="pointer">
                        <li class="pointer"><a (click)="onHome()" class="pointer">Home</a><span class="delimiter"></span></li>

                        <li class="pointer"><span class="pointer">Blog</span></li>
                    </ul>
                </div><!--pager-sec-details end-->
            </div>
        </section>
        
        <section class="listing-main-sec section-padding2">
            <!-- <div class="header_blog_section"><h1 *ngIf="blog_title">{{this.blog_category_name}}&nbsp;&nbsp;&nbsp;List</h1></div> -->
            <div class="container">
                <div class="listing-main-sec-details">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="blog-posts property_content_position">
                                <div *ngFor="let data of blog_list"
                                    class="blog-single-post selio-cover post-308 post type-post status-publish format-standard has-post-thumbnail hentry category-lifestyle tag-business tag-construction tag-real-estate">
                                    <div class="blog-img-cover">
                                        <div class="blog-img">
                                            <a (click)="onReadMore(data.slug)" title="Test article 2">
                                                <img [src]="data.blog_image" alt="Displayed Image"
                                                   >
                                                <a (click)="onReadMore(data.slug)" title="Test article 2" class="hover"></a>
                                            </a>
                                        </div><!--blog-img end-->
                                    </div><!--blog-img end-->
                                    <div class="post_info">
                                        <ul class="post-nfo">
                                            <li><i class="fa fa-calendar"></i>
                                               {{data.created_datetime}}</li>
                                            <li>
                                                <a (click)="onReadMore(data.slug)"><i class="fa fa-bookmark-o"></i>{{data.blog_title}} </a>
                                            </li>
                                        </ul>
                                        <h3 class="blog_title">
                                            <a (click)="onReadMore(data.slug)"  style="font-size: 28px !important;" class="blog_title" title="Test article 2">{{data.blog_title}}  
                                            </a>
                                        </h3>
                                        <div class="post-content clearfix" style="font-family:'Poppins', 'Roboto' !important;" [innerHTML]=" data.blog_description | truncate"></div>

                                        <a (click)="onReadMore(data.slug)" title="Read more" class="btn-default">Read
                                            more</a>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="sidebar layout2">


                                <div class="widget widget-catgs widget_edit_enabled">
                                    <h3 class="widget-title blog_T">Blog Categories</h3>
                                    <ul>
                                        <li *ngFor="let data of blog_categories">
                                            <a (click)="onCategoryClick(data.blog_category_id,data.category_name)" title="Apartment">
                                                - <span style="font-family:'Poppins', 'Roboto' !important;">{{data.category_name}}</span></a>
                                            <!-- <span>0</span> -->
                                        </li>
                                       
                                    </ul>
                                </div><!--widget-catgs end-->

                                <div class="widget widget-posts widget_edit_enabled">
                                    <h3 class="widget-title ">Popular Listings</h3>
                                    <ul>
                                        <li *ngFor="let data of blog_popular_listing" (click)="onPopularListingClick(data.property_id)">
                                            <div class="wd-posts">
                                                <div class="ps-img">
                                                    <a  title="test 1">
                                                        <img [src]='data.media_url'
                                                            alt="test 1">
                                                    </a>
                                                </div><!--ps-img end-->
                                                <div class="ps-info">
                                                    <!-- <h3><a  title="test 1">{{data.property_name}}
                                                            </a></h3> -->
                                                    <strong>
                                                    </strong>
                                                    <span><i class="fa fa-map-marker"></i><span class="property-details">{{data.property_details}}</span></span>
                                                </div><!--ps-info end-->
                                            </div><!--wd-posts end-->
                                        </li>
                                      
                                    </ul>
                                </div><!--widget-posts end-->

                            </div><!--sidebar end-->
                        </div>
                    </div>
                </div><!--listing-main-sec-details end-->
            </div>
        </section><!--listing-main-sec end-->
        <a  title="" class="widget_edit_enabled">
            <section class="cta section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="cta-text">
                                <h2>Discover a home you'll love to stay</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </a>
 <app-footer></app-footer>
    </div><!--wrapper end-->

  
</body>


</html>



