<!DOCTYPE html>
<html
  class="js sizes customelements history pointerevents postmessage webgl websockets cssanimations csscolumns csscolumns-width csscolumns-span csscolumns-fill csscolumns-gap csscolumns-rule csscolumns-rulecolor csscolumns-rulestyle csscolumns-rulewidth csscolumns-breakbefore csscolumns-breakafter csscolumns-breakinside flexbox picture srcset webworkers"
  lang="en"
>
  <head>
    <meta charset="UTF-8" />
    <title>My Profile</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - My profile" />
    <meta property="og:url" content="myprofile/en" />
    <meta property="og:description" content="" />
    <style>
      .wrapper-loader {
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: fixed;
        left: 0px;
        right: 0px;
        z-index: 99999;
        opacity: 0.8;
      }

      .loader-spinner-img {
        margin: 0 auto;
        display: block;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    </style>
  </head>

  <body class="modal-backdrop-effect dissable-sticky">
    <div class="wrapper-loader">
      <span class="loader-spinner-img"
        ><img src="assets/img/loader.gif"
      /></span>
    </div>
    <div class="">
      <app-header></app-header>

      <section class="pager-sec bfr widget_edit_enabled">
        <div class="container">
          <div class="pager-sec-details">
            <h3 class="pointer">My Profile</h3>
            <ul class="breadcrumb">
              <li>
                <a (click)="openHomePage()" class="pointer">Home</a
                ><span class="delimiter pointer"></span>
              </li>
              <li class="pointer"><span class="pointer">My profile</span></li>
            </ul>
          </div>
          <!--pager-sec-details end-->
        </div>
      </section>
      <div class="container m-padding" id="main">
        <div class="alert alert-success"   id="user_alert_success"></div>
        <div class="alert alert-danger" id="user_alert_danger"></div>
        <div class="widget-panel border widget-submit">
          <!-- <div class="widget-header header-styles">
            <h2 class="title">Profile</h2>
          </div> -->
          <!-- ./ title -->
          <div class="validation m25"></div>
          <form
            action="#"
            method="post"
            accept-charset="utf-8"
            class="form-estate"
            role="form"
            data-h5-instanceid="0"
            novalidate="novalidate"
          >
            <div class="form-group">
              <label for="inputNameSurname" class="control-label"
                >Full name<span class="text-danger">*</span></label
              >
              <div >
                <input
                  type="text"
                  class="form-control"
                  id="inputNameSurname"
                  placeholder="Full name"
                  [(ngModel)]="full_name"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="inputEmail" class="control-label">Email<span class="text-danger">*</span></label>
              <div>
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail"
                  placeholder="Email"
                  [(ngModel)]="email"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>

              <!-- <div class="form-group">
                            <label for="inputPassword" class="control-label">Password</label>
                            <div class="controls">
                                <input type="password" name="password" value="" class="form-control" id="inputPassword"
                                    autocomplete="new-password" placeholder="Password">
                            </div>
                        </div> -->

              <!-- <div class="form-group">
                            <label for="inputPasswordConfirm" class="control-label">Confirm password</label>
                            <div class="controls">
                                <input type="password" name="password_confirm" value="" class="form-control"
                                    id="inputPasswordConfirm" autocomplete="new-password"
                                    placeholder="Confirm password">
                            </div>
                        </div> -->

              <div class="form-group">
                <label for="inputPhone" class="control-label">Phone</label>
                <div >
                  <input
                    type="text"
                    class="form-control"
                    id="inputPhone"
                    placeholder="Phone"
                    [(ngModel)]="phoneNumber"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="inputfacebook" class="control-label"
                  >Facebook Link</label
                >
                <div>
                  <input
                    type="text"
                    name="facebook"
                    class="form-control"
                    id="inputNameSurname"
                    placeholder="Facebook"
                    [(ngModel)]="facebook"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="inputNameSurname" class="control-label"
                  >Instagram Link</label
                >
                <div >
                  <input
                    type="text"
                    name="instagram"
                    class="form-control"
                    id="inputNameSurname"
                    placeholder="Instagram"
                    [(ngModel)]="instagram"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="inputtwitter" class="control-label"
                  >Twitter Link</label
                >
                <div >
                  <input
                    type="text"
                    name="twitter"
                    class="form-control"
                    id="inputNameSurname"
                    placeholder="Twitter"
                    [(ngModel)]="twitter"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="inputlinkedin" class="control-label"
                  >Linkedin Link</label
                >
                <div >
                  <input
                    type="text"
                    name="linkedin"
                    class="form-control"
                    id="inputNameSurname"
                    placeholder="Linkedin"
                    [(ngModel)]="linkedIn"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="inputsnapchat" class="control-label"
                  >Snapchat Link</label
                >
                <div >
                  <input
                    type="text"
                    name="snapchat"
                    class="form-control"
                    id="inputNameSurname"
                    placeholder="Snapchat"
                    [(ngModel)]="snapchat"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>
            </div>

            <div class="form-group">
              <div>
                <input
                  type="submit"
                  name="submit"
                  (click)="onUpdate()"
                  class="btn btn-primary"
                />
              </div>
            </div>
          </form>
        </div>
        <!-- ./ widget-submit -->

        <!--------------------- file section -------------------------->
        <form
          action="#"
          method="post"
          accept-charset="utf-8"
          class="form-estate"
          role="form"
          data-h5-instanceid="0"
          novalidate="novalidate"
          id="scrollTarget"
        >
        <div class="alert alert-success" role="alert" id="file_alert_success"></div>
        <div class="alert alert-danger" role="alert" id="file_alert_danger"></div>
          <div class="widget-panel border widget-submit" id="main">
            <div class="form-group">
              <label for="inputDocument" class="control-label"
                >Document Type<span class="text-danger">*</span></label
              >
              <div class="controls">
                <input
                  type="text"
                  name="Document"
                  value=""
                  class="form-control"
                  id="inputNameSurname"
                  placeholder="Document type"
                  [(ngModel)]="document_type"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="inputdocumentnum" class="control-label"
                >Document Number<span class="text-danger">*</span></label
              >
              <div class="controls">
                <input
                  type="text"
                  name="documentnum"
                  value=""
                  class="form-control"
                  id="inputNameSurname"
                  placeholder="Document number"
                  [(ngModel)]="document_number"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="inputcomment" class="control-label">Comment<span class="text-danger">*</span></label>
              <div class="controls">
                <input
                  type="text"
                  name="comment"
                  value=""
                  class="form-control"
                  id="inputNameSurname"
                  placeholder="Comment"
                  [(ngModel)]="comments"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>

            <div class="widget-header header-styles">
              <h2 class="title">Files </h2>
            </div>
            <!-- ./ title -->
            <div class="content-box">
              <div id="page-files-18" rel="user_m">
                <!-- The file upload form used as target for the file upload widget -->
                <form
                  class="fileupload"
                  action="#"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <!-- Redirect browsers with JavaScript disabled to the origin page -->

                  <!-- The fileupload-buttonbar contains buttons to add/delete files and start/cancel the upload -->
                  <div class="fileupload-buttonbar row">
                    <div class="col-md-6">
                      <!-- The fileinput-button span is used to style the file input field as button -->
                      <!-- <span class="btn btn-success fileinput-button">
                                        <i class="fa fa-plus"></i>
                                        <span>Add files</span> -->
                      <input
                        type="file"
                        class="form-control"
                        #file
                        multiple
                        (change)="getFile($event)"
                      />

                      <!-- </span> -->
                      <!-- <button type="reset" class="btn btn-warning cancel">
                                        <i class="icon-ban-circle icon-white"></i>
                                        <span>Cancel upload</span>
                                    </button>
                                    <button type="button" class="btn btn-danger delete">
                                        <i class="icon-trash icon-white"></i>
                                        <span>Delete selection</span>
                                    </button>
                                    <input type="checkbox" class="toggle"> -->
                    </div>

                    <!-- The global progress information -->
                    <div class="col-md-6 fileupload-progress fade">
                      <!-- The global progress bar -->
                      <div
                        class="progress progress-success progress-striped active"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <div class="bar" style="width: 0%"></div>
                      </div>
                      <!-- The extended global progress information -->
                      <div class="progress-extended">&nbsp;</div>
                    </div>
                  </div>

                  <!-- The loading indicator is shown during file processing -->
                </form>
              </div>
            </div>
            <br />

            <div class="fileupload-buttonbar row">
              <div class="form-group row" *ngIf="showDocumentTable === true">
                <div class="col-sm-12 overflow ">
                  <table
                    id="user_douments_table"
                    class="table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <!-- <th>Id</th> -->
                        <th>Document Type</th>
                        <th>Document Number</th>
                        <th>Comments</th>
                        <th>View Document</th>
                        <th>Delete</th>
                        <!-- <th class="d-none d-md-table-cell">Delete</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let doctDetails of documentDetails">
                        <!-- <td>{{doctDetails.user_document_id}}</td> -->
                        <td>{{ doctDetails.document_type }}</td>
                        <td>{{ doctDetails.document_number }}</td>
                        <td>{{ doctDetails.comments }}</td>
                        <td
                          (click)="viewDocumnet(doctDetails.document_url)"
                          data-toggle="tooltip"
                          title="View Document"
                          class="pointer"
                        >
                          <i
                            class="fa fa-eye pointer"
                            aria-hidden="true"
                            style="
                              display: inline;
                              font-size: 26px;
                              color: #ffffff;
                              background-color: #13a89e;
                              border: 1px solid #13a89e;
                              border-radius: 10px;
                              padding-left: 11px;
                              padding-right: 11px;
                              padding-top: 5px;
                              padding-bottom: 5px;
                            "
                          ></i>
                        </td>
                        <td
                          (click)="deleteDocument(doctDetails.user_document_id)"
                          data-toggle="tooltip"
                          title="Delete"
                          class="pointer"
                        >
                          <i
                            class="fa fa-trash pointer"
                            aria-hidden="true"
                            style="
                              display: inline;
                              font-size: 26px;
                              color: #ffffff;
                              background-color: #13a89e;
                              border: 1px solid #13a89e;
                              border-radius: 10px;
                              padding-left: 11px;
                              padding-right: 11px;
                              padding-top: 5px;
                              padding-bottom: 5px;
                            "
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>

        <!-- ./ widget-submit -->
      </div>

      <a href="#" title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
    <!--wrapper end-->

    <div class="se-pre-con"></div>
  </body>
</html>
