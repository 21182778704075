import { Component } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../assets/css/application.css'],
})
export class HomeComponent {
  state_id: any=0;
  city_id: any=0;
  search_text: any;
  city_area_id: any=0;
  city_list: any[] = [];
  city_area_list: any[] = [];
  state_list: any[] = [];
  blog_popular_listing: any[] = [];
  testimonial_list: any[] = [];
  currentRate = 0;
  rate = 3;
  currentRateProperty = 0;
  rating: any = 3;
  ratingProperty: any;
  ratings: { [key: number]: number } = {};
  currentIndex = 0;
  currentIndex1 = 1;
  cms_title: any;
  user_id: any;
  home_page_title: any;
  home_page_description: any;

  ngOnInit() {
    // this.startSlider();
    window.scrollTo(0,0);
    CommonService.ActiveClass('nav-home');
    $('.alert-danger').hide();
    // let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details === undefined ||
      user_details === null ||
      user_details === ''
    ) {
      this.user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      this.user_id = user.user_id;
    }
    this.city_area_id = 0;
    // this.city_id = 0;
    // this.state_id = 0;
    this.http
      .post(CommonService.api_url + '/get-popular-reviews', {
        oauth_key: CommonService.oauth_key,
        user_id: this.user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_popular_listing = responseData['data'];
      });
    this.http
      .post(CommonService.api_url + '/state-list-internal', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.state_list = responseData['data'];
        // this.state_id = this.state_list[0].state_id;
        // this.onChangeState({ target: { value: this.state_id } });
      });
    this.http
      .post(CommonService.api_url + '/get-testimonials', {
        user_id: this.user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.testimonial_list = responseData['data'];
      });

      this.http
      .post(CommonService.api_url + '/get-cms', {
        user_id: this.user_id,
        cms_id: 6,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.home_page_title =
          responseData['data']['cms_content'][0]['cms_content'];
        this.cms_title = responseData['data']['cms_content'][0]['cms_name'];
      });

      // this.http
      // .post(CommonService.api_url + '/get-cms', {
      //   user_id: this.user_id,
      //   cms_id: 7,
      //   oauth_key: CommonService.oauth_key,
      // })
      // .subscribe((responseData: any) => {
      //   this.home_page_description =
      //     responseData['data']['cms_content'][0]['cms_content'];
      //   this.cms_title = responseData['data']['cms_content'][0]['cms_name'];
      // });
  }

  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onReview(property_id: any) {
    localStorage.setItem('property_id', property_id);
    this.router.navigateByUrl('/property-details');
  }
  // startSlider() {
  //   setInterval(() => {
  //     this.nextSlide();
  //   }, 3000); // Change slide every 3 seconds (3000 milliseconds)
  // }

  nextSlide() {
    if (this.testimonial_list.length > 0) {
      this.currentIndex =
        (this.currentIndex + 1) % this.testimonial_list.length;
    }
    if (this.testimonial_list.length > 0) {
      this.currentIndex1 =
        (this.currentIndex1 + 1) % this.testimonial_list.length;
    }
  }
  prevSlide() {
    this.currentIndex =
      (this.currentIndex - 1 + this.testimonial_list.length) %
      this.testimonial_list.length;
    this.currentIndex1 =
      (this.currentIndex1 - 1 + this.testimonial_list.length) %
      this.testimonial_list.length;
  }
  onLoadMore() {
    this.router.navigateByUrl('/search');
  }
  // Change event of state dropdown
  onChangeState(event: any) {
    // API for city list
    $('.alert-danger').hide();
    this.city_list = [];
    this.city_area_list = [];
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details === undefined ||
      user_details === null ||
      user_details === ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }

    const state_id = event.target.value;
    if(state_id>0){
    this.http
      .post(CommonService.api_url + '/city-list-internal', {
        user_id: user_id,
        state_id: state_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.city_list = responseData['data'];
        // this.city_id = this.city_list[0].city_id;
        // this.onChangeCity({ target: { value: this.city_id } });
      });
    }
  }

  // Change event of city dropdown
  onChangeCity(event: any) {
    $('.alert-danger').hide();
    this.city_area_list = [];

    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details === undefined ||
      user_details === null ||
      user_details === ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    // API for city area list
    const city_id = parseInt(event.target.value);
    if(city_id>0){
      this.http
      .post(CommonService.api_url + '/get-city-area-list-internal', {
        user_id: user_id,
        city_id: city_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.city_area_list = responseData['data'];
      });
    }
  
  }

  searchProperty() {
    // if(this.validateSearch() === false){
    //   return;
    // }
    // else{
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details === undefined ||
      user_details === null ||
      user_details === ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let rating: any;
    let city_id: any;
    let city_area_id: any ;
    let state_id: any;
    if (city_id != '') {
      
      city_id=parseInt(this.city_id);
    }
    else{
      city_id = 0;
    
    }
    if (
      city_area_id != '' 
    ) {  city_area_id = parseInt(this.city_area_id);
     
    }
    else{
      city_area_id = 0;
    }
    if (state_id != '') {
      state_id =parseInt(this.state_id);
    }
    else{
      state_id = 0;

    }
    if (rating != '') {
      rating =parseInt(this.rating);
    }
    else{
      rating = 0;

    }
    if (
      this.search_text == '' ||
      this.search_text == undefined ||
      this.search_text == null
    ) {
      this.search_text = '';
    }
    localStorage.setItem('search_text', this.search_text);
    localStorage.setItem('city_id', city_id);
    localStorage.setItem('city_area_id', city_area_id);
    localStorage.setItem('state_id', state_id);
    localStorage.setItem('rating', rating);

    this.http
      .post(CommonService.api_url + '/search-property', {
        city_id: city_id,
        state_id: state_id,
        property_area: city_area_id,
        property_type: 0,
        property_address: this.search_text,
        review_score: rating,
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
        start: 0,
        draw: 0,
        length: 10,
      })
      .subscribe((responseData: any) => {
        // this.city_area_list = responseData['data'];
        if (responseData['is_successful'] === '1') {
          if (responseData['data'].length === 0) {
            // alert('No property found');
            localStorage.setItem('add_Property','No property found')
            this.router.navigateByUrl('/search');
          } else {
            localStorage.setItem(
              'search_property',
              JSON.stringify(responseData['data'])
            );
            localStorage.setItem('iTotalDisplayRecords',responseData['iTotalDisplayRecords'])
            this.router.navigateByUrl('/search');
          }
        }
        if (responseData['is_successful'] === '0') {
          // alert('No property found');
          localStorage.setItem('add_Property','No property found')
          this.router.navigateByUrl('/search');
          // $('.alert-danger').html('No property found');
        }
      });
    // }
  }
  // validateSearch() {
  //   let city_id: any = parseInt(this.city_id);
  //   let city_area_id: any = parseInt(this.city_area_id);
  //   let error_message = '';
  //   if (city_id === '' || city_id === undefined || city_id === null || city_id === 0) {
  //     error_message += 'Please select city<br/>';
  //   }
  //   if (
  //     city_area_id === '' ||
  //     city_area_id === undefined ||
  //     city_area_id === null || city_area_id === 0
  //   ) {
  //     error_message += 'Please select city area<br/>';
  //   }
  //   if (error_message != '') {
  //     // alert(error_message);
  //     $('.alert-danger').html('Required');
  //     $('.alert-danger').show();
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
}
