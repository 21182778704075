import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css', '../../assets/css/application.css'],
})
export class FooterComponent {
  cms_content: any;
  contact_content: any;
  list_content: any;
  cms_title: any;
  user_id: any;
  facebook: any =
    'https://www.facebook.com/profile.php?id=100095299902959&_rdr';
  instagram: any = 'https://www.instagram.com/social.rentalreviews/';
  twitter: any = 'https://twitter.com/rentals_reviews';
  linkedin: any = 'https://www.linkedin.com/in/rental-reviews-a51163286/';
  ngOnInit() {
    window.scrollTo(0, 0);
    let user_details: any = localStorage.getItem('agent_login');
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      this.user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      this.user_id = user.user_id;
    }

    this.http
      .post(CommonService.api_url + '/get-cms', {
        user_id: this.user_id,
        cms_id: 5,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.cms_content =
          responseData['data']['cms_content'][0]['cms_content'];
        this.cms_title = responseData['data']['cms_content'][0]['cms_name'];
      });
    // this.http
    //   .post(CommonService.api_url + '/get-cms', {
    //     user_id: this.user_id,
    //     cms_id: 6,
    //     oauth_key: CommonService.oauth_key,
    //   })
    //   .subscribe((responseData: any) => {
    //     this.contact_content =
    //       responseData['data']['cms_content'][0]['cms_content'];
    //   });
    // this.http
    //   .post(CommonService.api_url + '/get-cms', {
    //     user_id: this.user_id,
    //     cms_id: 7,
    //     oauth_key: CommonService.oauth_key,
    //   })
    //   .subscribe((responseData: any) => {
    //     this.list_content =
    //       responseData['data']['cms_content'][0]['cms_content'];
    //   });
  }
  onFaq() {
    this.router.navigate(['/faqs']);
  }
  onPrivacyPolicy() {
    this.router.navigate(['/privacy-policy']);
  }
  onTenantOrganizaion() {
    this.router.navigate(['/tenant-organization']);
  }
  onCommunity() {
    this.router.navigate(['/community-guidelines']);
  }
  onImageClick() {
    this.router.navigateByUrl('/home');
  }
  onfacebook() {
    window.open(this.facebook, '_blank');
  }
  ontwitter() {
    window.open(this.twitter, '_blank');
  }
  onlinkedin() {
    window.open(this.linkedin, '_blank');
  }
  oninstagram() {
    window.open(this.instagram, '_blank');
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
}
