import { Component } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: [
    './blog.component.css',
    '../../assets/css/application.css',
    '../header/header.component.css',
  ],
})

export class BlogComponent {
  blog_list: any[] = [];
  blog_categories: any[] = [];
  blog_popular_listing: any[] = [];
  blog_title: boolean = false;
  blog_category_name: any;
  ngOnInit() {
    window.scrollTo(0,0);
    CommonService.ActiveClass('nav-blog');
    this.blog_title = false;
    this.blog_category_name = '';
    let user_details: any = localStorage.getItem('agent_login');
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let blog_categories: any = localStorage.getItem('blog_categories');
    if (
      blog_categories == undefined ||
      blog_categories == null ||
      blog_categories == ''
    ) {
      this.http
        .post(CommonService.api_url + '/get-blogs', {
          user_id: user_id,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((responseData: any) => {
          this.blog_list = responseData['data'];
          // this.city_id=this.city_list[0].city_id;
        });
    } else {
      let blog_category_name: any = localStorage.getItem('blog_category_name');
      this.blog_list = JSON.parse(blog_categories);
      this.blog_title = true;
      this.blog_category_name = blog_category_name;
      localStorage.removeItem('blog_categories');
      localStorage.removeItem('blog_category_name');
    }
    this.http
      .post(CommonService.api_url + '/get-blog-categories', {
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.blog_categories = responseData['data'];
      });
    this.http
      .post(CommonService.api_url + '/get-popular-reviews', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_popular_listing = responseData['data'];
      });
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
 
  onReadMore(slug: any) {
    localStorage.setItem('blog_slug', slug);
    this.router.navigateByUrl('/blog-details');
  }
  onCategoryClick(blog_category_id: any, category_name: any) {
    let user_details: any = localStorage.getItem('agent_login');
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    }
    else{
      let user = JSON.parse(user_details);
       user_id = user.user_id;
  
    }
   
    this.http
      .post(CommonService.api_url + '/get-Blogs-By-Category', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
        blog_category_id: blog_category_id,
      })
      .subscribe((responseData: any) => {
        this.blog_list = responseData['data'];
        this.blog_title = true;
        this.blog_category_name = category_name;
      });
  }
  onPopularListingClick(property_id: any) {
    localStorage.setItem('property_id', property_id);
    this.router.navigateByUrl('/property-details');
  }
  onHome(){
    this.router.navigateByUrl('/home');
  }
}
