<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Login</title>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->

    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Login" />
    <meta property="og:url" content="login.html" />
    <meta property="og:description" content="" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
  </head>

  <body>
    <div class="wrapper">
      <app-header></app-header>
      <main class="main-clear">
        <div class="selio_sw_win_wrapper">
          <div class="ci sw_widget sw_wrap">
       
            <div class="sign-form-wr">
              <div class="sign-form-inner tab-content">
                <!-- Log In -->
                <div
                  class="form-wr log-in-form tab-pane fade active show"
                  role="tabpanel"
                  id="log-in-form"
                  id="login_show"
                >
                  <h3>Login</h3>
                  <div class="form-wr-content" >
              
                    <form id="popup_form_login">
                      <div class="alert alert-success" role="alert">
                        {{ this.display_success_msg }}
                      </div>
                      <div class="alert alert-danger" role="alert">
                        {{ this.display_failed_msg }}
                      </div>
                      <div class="form-field">
                        <input
                          type="email"
                          name="username"
                          class="form-control"
                          placeholder="Email"
                          [(ngModel)]="email"

                        />
                      </div>
                      <div class="form-field">
                        <input
                          type="password"
                          name="password"
                          class="form-control"
                          placeholder="Password"
                          [(ngModel)]="password"
                        />
                      </div>
                      <div class="form-cp">
                        <div class="form-field">
                          <div class="input-field">
                            <input
                              type="checkbox"
                              name="remember"
                              value="true"
                              style="color: black"
                            />
                            <!-- <label for="remember">
                              <span></span>
                              <small>Remember me</small>
                            </label> -->
                          </div>
                        </div>
                        <a
                      (click)="forgotPassword()"
                          class="forgot-password pointer"
                          title="Forgot Password?"
                          >Forgot Password?</a
                        >
                      </div>
                      <!--form-cp end-->
                      <button type="submit" class="btn2" (click)="Login()">
                       Login
                      </button>
                    </form>
                  </div>
               
                </div>
        
              </div>
            </div>
          </div>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
  </body>
</html>
