<!DOCTYPE html>
<html
  class="js sizes customelements history pointerevents postmessage webgl websockets cssanimations csscolumns csscolumns-width csscolumns-span csscolumns-fill csscolumns-gap csscolumns-rule csscolumns-rulecolor csscolumns-rulestyle csscolumns-rulewidth csscolumns-breakbefore csscolumns-breakafter csscolumns-breakinside flexbox picture srcset webworkers"
  lang="en"
>
  <head>
    <meta charset="UTF-8" />
    <title>My Review</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - My properties" />
    <meta property="og:url" content="myproperties/en" />
    <meta property="og:description" content="" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />

    <link
      rel="shortcut icon"
      href="templates/selio/assets/img/favicon.png"
      type="image/png"
    />
    <link rel="canonical" href="index-2.html" />

    <!-- Maps -->

    <script type="text/javascript">
      var _gaq = _gaq || [];
      _gaq.push(["_setAccount", "UA-683342-5"]);
      _gaq.push(["_trackPageview"]);
      (function () {
        var ga = document.createElement("script");
        ga.type = "text/javascript";
        ga.async = true;
        ga.src =
          ("https:" == document.location.protocol
            ? "https://ssl"
            : "http://www") + ".google-analytics.com/ga.js";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(ga, s);
      })();
    </script>
  </head>

  <body class="modal-backdrop-effect dissable-sticky">
    <div class="">
      <app-header></app-header>
      <section class="pager-sec bfr widget_edit_enabled">
        <div class="container">
          <div class="pager-sec-details">
            <h3 class="pointer">My Properties Review</h3>
            <ul class="breadcrumb">
              <li>
                <a (click)="openHomePage()" class="pointer">Home</a
                ><span class="delimiter pointer"></span>
              </li>
              <li class="pointer"><span class="pointer">My Review</span></li>
            </ul>
          </div>
          <!--pager-sec-details end-->
        </div>
      </section>
      <div class="container m-padding">
        <div class="widget-panel">
          <!-- <div class="widget-header header-styles">
            <h2 class="title">My Review</h2>
          </div> -->
          <div class="content-box">
            <div class="content">
        
              <button class="button_add" (click)="onAddReview()"><i class="fa fa-plus"></i>Add Review</button>
            </div>
            <div class="box-alert"></div>
            <div class="form-group row">
              <div class="col-sm-12 overflow">
                <table
                  id="user_douments_table"
                  class="table table-striped table-hover " 
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Property Name</th>
                      <th>Address</th>
                      <th>Property Type</th>
                      <th>Edit</th>
                      <!-- <th class="d-none d-md-table-cell">Delete</th> -->
                    </tr>
                  </thead>
                  <tbody  *ngIf="show_table">
                    <tr *ngFor="let doctDetails of review_data_table">
                      <td>{{ doctDetails.property_id }}</td>
                      <td>{{ doctDetails.property_name }}</td>
                      <td>{{ doctDetails.property_details }}</td>
                      <td>{{ doctDetails.property_type }}</td>

                      <td data-toggle="tooltip" title="Edit">
                        <button
                        class="edit_button"
                        (click)="onEditClick(doctDetails.property_id,doctDetails.review_id)"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                      </td>
                    </tr>
                   
                  </tbody>
                  <tbody *ngIf="hide_table" class="show_data">
                    <tr >
                      <td colspan="5">No data available</td>
                    </tr>
                  </tbody>
                 
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- /. widget -->
      </div>

      <!-- <section class="bottom section-padding">
            <div class="container placeholder-container">
                <div class="row">

                    <div class="col-xl-3 col-sm-6 col-md-4 widget_edit_enabled">
                        <div class="bottom-logo">
                            <img src="https://geniuscript.com/selio-script/templates/selio/assets/img/logo.png"
                                alt="Real estate point" class="img-fluid">
                            <div class="content">
                                <p class="description">Nam nec tellus a odio tincidunt auctor a ornare odio. Sed non
                                    mauris vitae erat co nsequat auctor eu in elit.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-md-3 widget_edit_enabled">
                        <div class="widget-footer-contacts">
                            <h3>Contact Us</h3>
                            <ul class="footer-list">
                                <li><i class="la la-map-marker"></i>
                                    <span class="value">432 Park Ave, New York, NY 10022</span>
                                </li>
                                <li><i class="la la-phone"></i> <span class="value"><a href="tel://38501123321">+385
                                            (0)1 123 321</a></span></li>
                                <li><i class="la la-envelope"></i> <span class="value"><a
                                            href="mailto:info@my-website.com">info@my-website.com</a></span></li>
                                <li><i class="la la-chevron-circle-right"></i><span class="value"><a href="#">Contact
                                            Us</a></span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-6 col-sm-12 col-md-5 widget_edit_enabled">
                        <div class="bottom-list widget-follow-us">
                            <h3>Follow Us</h3>
                            <div class="footer-social">
                                <a href="https://www.facebook.com/share.php?u=https://geniuscript.com/selio-script/"
                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"><i
                                        class="fa fa-facebook"></i></a>
                                <a href="https://twitter.com/home?status=https://geniuscript.com/selio-script/"
                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"><i
                                        class="fa fa-twitter"></i></a>
                                <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://geniuscript.com/selio-script/&amp;title=&amp;summary=&amp;source="
                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"><i
                                        class="fa fa-linkedin"></i></a>
                                <a href="https://www.instagram.com"
                                    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"><i
                                        class="fa fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="https://geniuscript.com/selio-script/templates/selio/assets/img/placeholder-footer.webp"
                    alt="placeholder" class="footer-placeholder">
            </div>
        </section> -->
      <a href="#" title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
    <!--wrapper end-->

    <div class="se-pre-con"></div>
  </body>
</html>
