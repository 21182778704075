import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { IpServiceService } from '../ip-service.service';
import * as $ from 'jquery';
declare var google: any;
@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: [
    './property.component.css',
    '../../assets/css/application.css',
    '../header/header.component.css',
  ],
})
export class PropertyComponent {
  hidelandlord: boolean = false;
  review_report: boolean = false;
  landlord_report: boolean = false;
  review_id: any;
  state_id: any = 0;
  city_id: any = 0;
  search_text: any;
  city_area_id: any = 0;
  image_list: any[] = [];
  city_list: any[] = [];
  city_area_list: any[] = [];
  state_list: any[] = [];
  rating: any = 3;
  currentRate: number = 0;
  property_details: any[] = [];
  question_list: any[] = [];
  property_review_renter: any[] = [];
  property_review_landlord: any[] = [];
  property_longitude: any = 0;
  property_latitude: any = 0;
  review_message: any;
  display_success_msg: any;
  display_failed_msg: any;
  review_scores: number[] = new Array(this.question_list.length).fill(0);
  review_rateing_list: any[] = new Array(this.question_list.length).fill(0);
  center: google.maps.LatLngLiteral = { lat: 23.1822611, lng: 72.621385 };
  zoom = 15;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  ipAddress: any;
  location: any;
  uploadedFiles: any[] = [];
  filelength: any;
  activeTabIndex: number = 0;
  activeTabIndexLandlord: number = 0;
  report_value: any;
  report_reason: any;
  property_name: any;
  property_address: any;
  @ViewChild('tabSet')
  tabSet!: ElementRef;
  report_array: any[] = [];
  locations: any[] = [];
  isdisabled: boolean = false;
  media_url: any;
  media_url_popup:any;
  media_image:boolean=false;
  media_vedio:boolean=false;
  report_description:any;
  isPopupOpen = false; // Initially, the popup is closed
  isImage = false;
  currentIndex:any;
  
  ngOnInit(): void {
    window.scrollTo(0, 0);
    $('.wrapper-loader').hide();
    $('#sucess_review').hide();
    $('#alert_danger_review').hide();
    $('#review_report_danger').hide();
    $('#report_reasons_review').hide();
    $('.report_reasons_review_landlord').hide();
    var agentDetails = localStorage.getItem('agent_login');
    if (
      agentDetails === undefined ||
      agentDetails === '' ||
      agentDetails === null
    ) {
      // this.router.navigateByUrl('/login');
      $('#alert_danger_review').html('Please login first to submit a review.');
      $('#alert_danger_review').show();
      $('#alert_danger_review').addClass('addCSS');
      this.isdisabled = true;
    } else {
      $('#alert_danger_review').hide();
      $('#alert_danger_review').removeClass('addCSS');
      this.isdisabled = false;
    }
    this.report_array = [
      {
        report_value: '1',
        report_reason: 'Address is in the Review',
      },
      {
        report_value: '2',
        report_reason: 'Fake Review',
      },
      {
        report_value: '3',
        report_reason: 'Review contains inappropriate language',
      },
      {
        report_value: '4',
        report_reason: 'This review contains sensitive information',
      },
      {
        report_value: '5',
        report_reason: 'Others',
      },
    ];
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    // this.city_area_id = 0;
    // this.city_id = 0;
    // this.state_id = 0;

    this.http
      .post(CommonService.api_url + '/state-list-internal', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.state_list = responseData['data'];
        // this.state_id = this.state_list[0].state_id;
        // this.onChangeState({ target: { value: this.state_id } });
      });
    // CommonService.ActiveClass('nav-search');
    $('.land-review').addClass('active');
    this.getIP();
    $('.alert-success').hide();
    $('#alert_danger_review').hide();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      });
    }
    let user = JSON.parse(user_details);
    let user_role_id: any;
    if (
      user_details == null ||
      user_details == undefined ||
      user_details == ''
    ) {
      user_id = 0;
      user_role_id = 3;
    } else {
      user_role_id = user.user_role_id;
      user_id = user.user_id;
    }
    let property_id: any = localStorage.getItem('property_id');
    let property_id1 = parseInt(property_id);
    this.http
      .post(CommonService.api_url + '/get-property-details', {
        user_id: user_id,
        property_id: property_id1,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.property_details = responseData['data'];
        this.property_longitude = parseFloat(
          responseData['data']['property_longitude']
        );
        this.property_latitude = parseFloat(
          responseData['data']['property_latitude']
        );
        this.property_address = responseData['data']['property_details'];
        this.property_name = responseData['data']['property_name'];
        this.media_url = responseData['data']['media_url'];
        this.locations = [
          {
            property_latitude: this.property_latitude,
            property_longitude: this.property_longitude,
            property_name: this.property_name,
            property_address: this.property_address,
            media_url: this.media_url,
          },
        ];
        this.onboundries();
      });
    this.http
      .post(CommonService.api_url + '/get-review-questions', {
        user_role_id: user_role_id,
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.question_list = responseData['data'];
        $('document').ready(function () {
          let arQTypes: any[] = [];
          $('.question_type').each(function (a, b) {
            if (jQuery.inArray($(b).text(), arQTypes) > -1) {
              $(this).hide();
              // console.log("Hidden " + $(this).text());
            } else {
              arQTypes.push($(this).text());
            }
          });
        });
      });
    this.http
      .post(CommonService.api_url + '/get-review-by-property', {
        user_id: user_id,
        property_id: property_id1,
        user_role_id: 3,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.property_review_renter = responseData['data'];
        if (this.property_review_renter.length == 0) {
          this.landlordReview();
          $('.report_reasons_review_landlord').hide();
          this.hidelandlord=false;
          $('#renter_tab').hide();
        }
      });
    this.http
      .post(CommonService.api_url + '/get-review-by-property', {
        user_id: user_id,
        property_id: property_id1,
        user_role_id: 2,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.property_review_landlord = responseData['data'];
        if (this.property_review_landlord.length == 0) {
          this.rentersReview();
          $('#landlord_tab').hide();
          // $('.report_reasons_review_landlord').hide();
          this.hidelandlord=false;
        } else {
          // $('.report_reasons_review_landlord').hide();
          this.landlordReview();
          this.hidelandlord=false;
        }
      });
  }
  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      // console.log(this.ipAddress);
    });
  }
  onboundries() {
    let boundries = this.calculateBoundaries(this.locations);
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 15,
      center: {
        lat: boundries?.center.property_latitude,
        lng: boundries?.center.property_longitude,
      },
    });

    for (let i = 0; i < this.locations.length; ++i) {
      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(this.locations[i].property_latitude),
          lng: parseFloat(this.locations[i].property_longitude),
        },
        map: map,
      });

      this.attachSecretMessage(
        marker,
        this.locations[i].property_name,
        this.locations[i].property_address,
        this.locations[i].media_url
      );
    }
    localStorage.removeItem('search_property');
  }
  attachSecretMessage(
    marker: any,
    property_name: any,
    property_details: any,
    media_url: any
  ) {
    const infowindow = new google.maps.InfoWindow({
      content: `
        <div class="card">
          <a >
            <div class="img-block">
              <div class="overlay"></div>
              <div id="listing_carousel_30" class="carousel slide carousel-listing" data-ride="carousel" data-interval="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="${media_url}"  style="height: 190px" class="d-block w-100 img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div class="card-body" style="height:86px">
            <a>
              <p><i class="fas fa fa-map-marker"></i><span style=" font-size: 16px;
              color: #3c3c3c;
              font-weight: bold;">${property_details}</span></p>
            </a>
          </div>
        </div>
      `,
    });

    marker.addListener('click', () => {
      // infowindow.close();
      // infowindow.open(marker.get('map'), marker);

      // Open the new InfoWindow
      infowindow.open(marker.get('map'), marker);

      // Update the current InfoWindow reference
    });
  }
  calculateBoundaries(locations: any) {
    if (locations.length === 0) {
      return null; // Return null if there are no locations
    }

    let leftmost = parseFloat(locations[0].property_longitude);
    let rightmost = parseFloat(locations[0].property_longitude);
    let topmost = parseFloat(locations[0].property_latitude);
    let bottommost = parseFloat(locations[0].property_latitude);

    locations.forEach((location: any) => {
      if (location.property_longitude < leftmost) {
        leftmost = parseFloat(location.property_longitude);
      }
      if (location.property_longitude > rightmost) {
        rightmost = parseFloat(location.property_longitude);
      }
      if (location.property_latitude > topmost) {
        topmost = parseFloat(location.property_latitude);
      }
      if (location.property_latitude < bottommost) {
        bottommost = parseFloat(location.property_latitude);
      }
    });
    // let latitude = (topmost + bottommost) / 2;
    // let longitude = (leftmost + rightmost) / 2;
    //     // Calculate the center of the boundaries

    //     if (!isNaN(latitude) && !isNaN(longitude)) {
    //    latitude = latitude;
    //    longitude = longitude;
    //     }
    //     else{
    //     latitude = locations[0].property_longitude;
    //     longitude= locations[0].property_latitude;
    //     }
    //    const center = {
    //     latitude:latitude,
    //     longitude:longitude,
    //     };
    const center = {
      property_latitude: (topmost + bottommost) / 2,
      property_longitude: (leftmost + rightmost) / 2,
    };

    return {
      leftmost,
      rightmost,
      topmost,
      bottommost,
      center,
    };
  }
  submitReview() {
    $('.wrapper-loader').show();
    this.reviewValidation();
    this.commonService.IsUserLogIn();
    if (this.validation() == true) {
      let user_details: any = localStorage.getItem('agent_login');
      let user = JSON.parse(user_details);
      let user_role_id;
      let user_id;
      if (
        user_details == null ||
        user_details == undefined ||
        user_details == ''
      ) {
        user_id = 0;
        user_role_id = 3;
      } else {
        user_role_id = user.user_role_id;
        user_id = user.user_id;
      }
      let property_id1: any = localStorage.getItem('property_id');
      let property_id = parseInt(property_id1);

      if (
        this.review_message == undefined ||
        this.review_message == null ||
        this.review_message == ''
      ) {
        this.review_message = '';
      }
      if (
        this.location == undefined ||
        this.location == null ||
        this.location == ''
      ) {
        this.location = {
          latitude: 0,
          longitude: 0,
        };
      }
      this.http
        .post(CommonService.api_url + '/submit-review', {
          user_id: user_id,
          property_id: property_id,
          created_by: user_id,
          review_message: this.review_message,
          oauth_key: CommonService.oauth_key,
          reviews: this.review_rateing_list,
          reviewer_latitude: this.location.latitude,
          reviewer_longitude: this.location.longitude,
          reviewer_ip_address: this.ipAddress,
        })
        .subscribe((responseData1: any) => {
          if (responseData1['is_successful'] === '1') {
            let review_id = responseData1['data']['review_id'];
            if (CommonService.uploadData != new FormData()) {
              CommonService.uploadData.append('review_id', review_id);
              this.http
                .post(
                  CommonService.api_url + '/upload-review-media',
                  CommonService.uploadData
                )
                .subscribe((responseData2: any) => {
                  if (responseData2['is_successful'] === '1') {
                    CommonService.uploadData = new FormData();
                    $('#review_main').hide();
                    $('#sucess_review').show();
                    $('#alert-success').html(responseData1['success_message']);
                    $('#alert-success').show();
                    $('#alert_danger_review').hide();
                    $('.wrapper-loader').hide();
                    // window.location.reload();
                    window.scrollTo(0, 0);
                  }
                  if (responseData2['is_successful'] === '0') {
                    $('#alert_danger_review').html(responseData2['errors']);
                    $('#alert-success').hide();
                    $('#alert_danger_review').show();
                    $('.wrapper-loader').hide();
                    CommonService.uploadData = new FormData();
                    window.scrollTo(0, 0);
                  }
                });
            } else {
              $('#review_main').hide();
              $('#sucess_review').show();
              $('#alert-success').html(responseData1['success_message']);
              $('.wrapper-loader').hide();
              $('#alert-success').show();
              $('#alert_danger_review').hide();
              window.scrollTo(0, 0);
            }
          }
          if (responseData1['is_successful'] === '0') {
            $('#alert_danger_review').html(responseData1['errors']);
            $('#alert-success').hide();
            $('#alert_danger_review').show();
            $('.wrapper-loader').hide();
            window.scrollTo(0, 0);
          }
        });
    } else {
      return;
    }
  }
  reviewValidation() {
    if (this.review_scores.length > 0) {
      this.question_list.forEach((question_list, index) => {
        if (
          this.review_scores[index] == undefined ||
          this.review_scores[index] == null
        ) {
          this.review_scores[index] = 0;
        } else {
          this.review_scores[index] = this.review_scores[index];
        }
        question_list.review_score = this.review_scores[index];
        question_list.review_question_id =
          this.question_list[index].review_question_id;
        question_list.review_type_id = this.question_list[index].review_type_id;
      });
    } else {
      this.question_list.forEach((question_list, index) => {
        question_list.review_score = 0;
        question_list.review_question_id =
          this.question_list[index].review_question_id;
        question_list.review_type_id = this.question_list[index].review_type_id;
      });
    }
    for (let i = 0; i < this.question_list.length; i++) {
      this.review_rateing_list[i] = {
        review_type_id: this.question_list[i].review_type_id,
        review_question_id: this.question_list[i].review_question_id,
        review_score: this.question_list[i].review_score,
      };
    }
  }
  onFileSelected(event: any): void {
    this.uploadedFiles.push(...event.target.files);
    CommonService.uploadData = new FormData();
    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    let user_id = user.user_id;
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      const file: File = this.uploadedFiles[i];
      // CommonService.uploadData.append(`files[${i}]`, file);
      CommonService.uploadData.append(`files`, file);
    }
    this.filelength = event.target.files.length;
    CommonService.uploadData.append('user_id', user_id);
    CommonService.uploadData.append('created_by', user_id);
  }
  constructor(
    private ip: IpServiceService,
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  rentersReview() {
    this.report_description='';
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
    $('#renter_report_button').show();
    $('#nav-landlord').removeClass('active');
    $('.land-review').removeClass('active');
    $('#nav-renter').addClass('active');
    $('.rental-review').addClass('active');
    $('.land-review').hide();
    $('.rental-review').show();
    $('#report_reasons_review').hide();

    // Add the 'active' class to the newly activated tab
    if (this.activeTabIndex < this.property_review_renter.length - 1) {
      // Remove the 'active' class from the current active tab
      $('.rental-review.active').removeClass('active');
      // Increment the activeTabIndex
      // Add the 'active' class to the newly activated tab
      $('.rental-review').eq(this.activeTabIndex).addClass('active');
      $('#report_reasons_review').hide();
    }
  }
  landlordReview() {
    this.report_description='';
    // this.ActiveClass(menu_name);
    // $('#nav-renter').removeClass('active');
    // $('.rental-review').removeClass('active');
    // $('#nav-landlord').addClass('active');
    // // $('.land-review').addClass('active');

    // $('.land-review').show();
    // $('.rental-review').hide();
    // // $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
    // if (
    //   this.activeTabIndexLandlord <
    //   this.property_review_landlord.length - 1
    // ) {
    //   $('.land-review').removeClass('active');
    //   $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
    //   $('#report_reasons_review_landlord').hide();
    // }
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
    $('#landlord_review_button').show();
    $('#nav-landlord').addClass('active');
    $('.land-review').addClass('active');
    $('#nav-renter').removeClass('active');
    $('.rental-review').removeClass('active');
    $('.land-review').show();
    $('.rental-review').hide();
    $('.report_reasons_review_landlord').hide();

    // Add the 'active' class to the newly activated tab
    if (this.activeTabIndexLandlord < this.property_review_landlord.length - 1) {
      // Remove the 'active' class from the current active tab
      $('.land-review.active').removeClass('active');
      // Increment the activeTabIndex
      // Add the 'active' class to the newly activated tab
      $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
      $('.report_reasons_review_landlord').hide();
    }
  }
  activateNextTab() {
    this.report_description='';
    $('.review_report').show();
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
   
    if (this.activeTabIndex < this.property_review_renter.length - 1) {
      // Remove the 'active' class from the current active tab
      $('.rental-review.active').removeClass('active');

      // Increment the activeTabIndex
      this.activeTabIndex++;

      // Add the 'active' class to the newly activated tab
      $('.rental-review').eq(this.activeTabIndex).addClass('active');
      $('.report_reasons_review').hide();
    } else {
      $('.rental-review.active').removeClass('active');
      this.activeTabIndex = 0;

      $('.rental-review').eq(this.activeTabIndex).addClass('active');
      $('.report_reasons_review').hide();
    }
  }
  activatePreviousTab() {
    this.report_description='';
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
    $('.review_report').show();
    if (this.activeTabIndex > 0) {
      $('.rental-review.active').removeClass('active');
      this.activeTabIndex--;
      $('.rental-review').eq(this.activeTabIndex).addClass('active');
      $('.report_reasons_review').hide();
    } else {
      $('.rental-review.active').removeClass('active');
      this.activeTabIndex = this.property_review_renter.length - 1;

      $('.rental-review').eq(this.activeTabIndex).addClass('active');
      $('.report_reasons_review').hide();
    }
  }
  activatePreviousTabLandloard() {
    this.report_description='';
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
    $('.review_report').show();
    if (this.activeTabIndexLandlord > 0) {
      $('.land-review.active').removeClass('active');
      this.activeTabIndexLandlord--;
      $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
      $('.report_reasons_review_landlord').hide();
    } else {
      $('.land-review.active').removeClass('active');
      this.activeTabIndexLandlord = this.property_review_landlord.length - 1;
      $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
      $('.report_reasons_review_landlord').hide();
    }
  }
  activateNextTabLandloard() {
    this.report_description='';
    $('#review_report_success').hide();
    $('#review_report_danger').hide();
    $('.review_report').show();
    if (
      this.activeTabIndexLandlord <
      this.property_review_landlord.length - 1
    ) {
      $('.report_reasons_review_landlord').hide();
      $('.land-review.active').removeClass('active');
      this.activeTabIndexLandlord++;
      $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
    } else {
      $('.report_reasons_review_landlord').hide();
      $('.land-review.active').removeClass('active');
      this.activeTabIndexLandlord = 0;
      $('.land-review').eq(this.activeTabIndexLandlord).addClass('active');
    }
  }

  searchProperty() {
    // if(this.validateSearch() == false){
    //   return;
    // }
    // else{
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let rating: any;
    let city_id: any;
    let city_area_id: any;
    let state_id: any;
    if (city_id != '') {
      city_id = parseInt(this.city_id);
    } else {
      city_id = 0;
    }
    if (city_area_id != '') {
      city_area_id = parseInt(this.city_area_id);
    } else {
      city_area_id = 0;
    }
    if (state_id != '') {
      state_id = parseInt(this.state_id);
    } else {
      state_id = 0;
    }
    if (rating != '') {
      rating = parseInt(this.rating);
    } else {
      rating = 0;
    }
    if (
      this.search_text == '' ||
      this.search_text == undefined ||
      this.search_text == null
    ) {
      this.search_text = '';
    }
    localStorage.setItem('search_text', this.search_text);
    localStorage.setItem('city_id', city_id);
    localStorage.setItem('city_area_id', city_area_id);
    localStorage.setItem('state_id', state_id);
    localStorage.setItem('rating', rating);
    this.http
      .post(CommonService.api_url + '/search-property', {
        city_id: city_id,
        state_id: state_id,
        property_area: city_area_id,
        property_type: 0,
        property_address: this.search_text,
        review_score: rating,
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
        start: 0,
        draw: 0,
        length: 10,
      })
      .subscribe((responseData: any) => {
        // this.city_area_list = responseData['data'];
        if (responseData['is_successful'] == '1') {
          if (responseData['data'].length == 0) {
            localStorage.setItem('add_Property', 'No property found');
            this.router.navigateByUrl('/search');
          } else {
            localStorage.setItem(
              'search_property',
              JSON.stringify(responseData['data'])
            );
            localStorage.setItem(
              'iTotalDisplayRecords',
              responseData['iTotalDisplayRecords']
            );
            this.router.navigateByUrl('/search');
          }
        }
        if (responseData['is_successful'] == '0') {
          // alert('No property found');
          localStorage.setItem('add_Property', 'No property found');
          this.router.navigateByUrl('/search');
          // $('.alert-danger').html('No property found');
        }
      });
    // }
  }
  onChangeState(event: any) {
    // API for city list
    $('#alert_danger_review').hide();
    this.city_list = [];
    this.city_area_list = [];
    this.city_area_id = '';
    this.city_id = '';
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    const state_id = event.target.value;

    this.http
      .post(CommonService.api_url + '/city-list-internal', {
        user_id: user_id,
        state_id: state_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.city_list = responseData['data'];
        this.city_id = this.city_list[0].city_id;
        this.onChangeCity({ target: { value: this.city_id } });
      });
  }

  // Change event of city dropdown
  onChangeCity(event: any) {
    this.city_area_list = [];
    $('#alert_danger_review').hide();
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    // API for city area list
    const city_id = parseInt(event.target.value);
    this.http
      .post(CommonService.api_url + '/get-city-area-list-internal', {
        user_id: user_id,
        city_id: city_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.city_area_list = responseData['data'];
      });
  }
  validation() {
    let errorMessage = '';

    let valid = false;
    for (let i = 0; i < this.review_rateing_list.length; i++) {
      if (this.review_rateing_list[i].review_score == 0) {
        valid = true;
      }
    }
    if (valid === true) {
      errorMessage += 'Please select rating for all questions.<br>';
    }
    if (this.review_rateing_list.length === 0) {
      errorMessage += 'Please select rating for all questions.<br>';
    }
    if (errorMessage != '') {
      this.display_failed_msg = errorMessage;
      $('#alert_danger_review').html(errorMessage);
      $('.alert-success').hide();
      $('#alert_danger_review').show();
      window.scrollTo(0, 0);
      $('.wrapper-loader').hide();
      return false;
    } else {
      return true;
    }
  }
  openImage(image_url: any,media_type:any,image_list:any,index:any) {
    // alert(image);
    // window.open(image, '_blank');
    this.currentIndex=index;
    this.image_list=image_list;
    setTimeout(()=>{
      this.isPopupOpen = true;
    },2000);
    
    // this.media_url_popup=image_url;
    // if(media_type==="image"){
    //   this.media_image=true;

    // }
    // if(media_type==="vedio"){
    //   this.media_vedio=true;
    // }

    
  }
  nextSlide() {
    if (this.image_list.length > 0) {
      this.currentIndex =
        (this.currentIndex + 1) % this.image_list.length;
    }
  
  }
  prevSlide() {
    this.currentIndex =
      (this.currentIndex - 1 + this.image_list.length) %
      this.image_list.length;
   
  }
  submitReport(review_id:any) {
    this.review_id=review_id;
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    if(this.report_description===undefined|| this.report_description===null || this.report_description===''){
      this.report_description='';
    }
    this.http
      .post(CommonService.api_url + '/submit-report', {
        user_id: user_id,
        review_id: this.review_id,
        report_type: this.report_reason,
        description:this.report_description,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if(responseData['is_successful']=='1')
        {
          $('#review_report_success').html(responseData['success_message']);
          $('#review_report_success').show();
          $('#review_report_success').show();
          $('#review_report_danger').hide();
        }
        if(responseData['is_successful']=='0'){
          $('#review_report_danger').html(responseData['errors']);
          $('#review_report_danger').show();
          $('#review_report_success').hide();
        }
      });
  }
  submitReportLandLord(review_id:any) {
    this.review_id=review_id;
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    if(this.report_description===undefined|| this.report_description===null || this.report_description===''){
      this.report_description='';
    }
    this.http
      .post(CommonService.api_url + '/submit-report', {
        user_id: user_id,
        review_id: this.review_id,
        report_type: this.report_reason,
        description:this.report_description,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if(responseData['is_successful']==='1')
        {
          $('#review_report_success').html(responseData['success_message']);
          $('#review_report_success').show();
          $('#review_report_success').show();
          $('#review_report_danger').hide();
        }
        if(responseData['is_successful']==='0'){
          $('#review_report_danger').html(responseData['errors']);
          $('#review_report_danger').show();
          $('#review_report_success').hide();
        }
      });
  }

  onChangeReport(event: any, review_id: any) {
    this.report_value = event.target.value;
    this.report_reason = event.target.options[event.target.selectedIndex].text;
    this.review_id = parseInt(review_id);
  }
  onChangeReportLandLord(event: any, review_id: any) {
    this.report_value = event.target.value;
    this.report_reason = event.target.options[event.target.selectedIndex].text;
    this.review_id = parseInt(review_id);
  }
  reportClick() {
  this.commonService.IsUserLogIn();
      $('.report_reasons_review').show();
      $('.review_report').hide();

  
  }
  reportClickLandord() {
    this.commonService.IsUserLogIn();
    this.hidelandlord=true;
      $('.report_reasons_review_landlord').show();
      $('.report_landlord').hide();
    
  }
 

  closePopup() {
    this.isPopupOpen = false;
  }
}
