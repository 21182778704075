<!doctype html>
<html class="no-js" lang="en">

<head>
    <meta charset="UTF-8">
    <title>Login</title>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" ><!-- /Added by HTTrack -->

    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="description" content="" >
    <meta name="keywords" content="" >
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="author" content="" >

    <meta property="og:site_name" content="Real estate point" >
    <meta property="og:title" content="Real estate point - Login" >
    <meta property="og:url" content="login.html" >
    <meta property="og:description" content="" >
    <meta property="og:image" content="templates/selio/assets/img/default-image-og.webp" >
    <link
    href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
    rel="stylesheet"
  />
</head>

<body>
    <div class="wrapper">
        <app-header></app-header>
        <main class="main-clear">
            <div class="selio_sw_win_wrapper">
                <div class="ci sw_widget sw_wrap">
                    <ul class="nav nav-tabs d-none sw-sign-form-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link log-in" href="#log-in-form" role="tab" data-toggle="tab">Log in</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link sign-up" href="#sign-up-form" role="tab" data-toggle="tab">Sign Up</a>
                        </li>
                    </ul>
                    <div class="sign-form-wr">
                        <div class="sign-form-inner tab-content">
                            <!-- Log In -->
                            <div class="form-wr log-in-form tab-pane fade active show" role="tabpanel" id="log-in-form">
                                <h3>Sign In to your Account</h3>
                                <div class="form-wr-content">
                                    <form method="post" action="login.html">
                                        <div class="form-field">
                                            <input type="text" name="username" value="" class="form-control"
                                                id="inputUsername_l" placeholder="Username">
                                        </div>
                                        <div class="form-field">
                                            <input type="password" name="password" value="" class="form-control"
                                                id="inputPassword_l" placeholder="Password">
                                        </div>
                                        <div class="form-cp">
                                            <div class="form-field">
                                                <div class="input-field">
                                                    <input type="checkbox" name="remember" id="remember" value="true">
                                                    <label for="remember">
                                                        <span></span>
                                                        <small>Remember me</small>
                                                    </label>
                                                </div>
                                            </div>
                                            <a  class="forgot-password create-op" title="Create?">Create?</a>
                                            <span class="or"> / </span>
                                            <a  class="forgot-password" title="Forgot Password?">Forgot
                                                Password?</a>
                                        </div><!--form-cp end-->
                                        <button type="submit" class="btn2">Sign In</button>
                                    </form>
                                </div>
                            </div>
          
                        </div>
                    </div>
                </div>
            </div>
        </main>
     <app-footer></app-footer>
      
    </div>

    <div class="popup" id="sign-popup">
        <h3>Sign In to your Account</h3>
        <div class="popup-form form-wr">
            <form id="popup_form_login">
             
                <div class="alerts-box"></div>
                <div class="form-field">
                    <input type="text" name="username" value="" class="form-control" 
                        placeholder="Username" >
                </div>
                <div class="form-field">
                    <input type="password" name="password" value="" class="form-control" 
                        placeholder="Password" >
                </div>
                <div class="form-cp">
                    <div class="form-field">
                        <div class="input-field">
                            <input type="checkbox" name="remember"  value="true" style="color: black;">
                            <label for="remember">
                                <span></span>
                                <small>Remember me</small>
                            </label>
                        </div>
                    </div>
                    <a  class="forgot-password" title="Forgot Password?">Forgot
                        Password?</a>
                </div><!--form-cp end-->
                <button type="submit" class="btn2">Sign In<i
                        class="fa fa-spinner fa-spin fa-ajax-indicator hidden ajax-indicator"></i></button>
            </form>
            <a  class="link-bottom">Create new account</a>
        </div>
    </div><!--popup end-->

   <div class="se-pre-con"></div>

 
</body>



</html>

