<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Login</title>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->

    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Login" />
    <meta property="og:url" content="login.html" />
    <meta property="og:description" content="" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
  </head>

  <body>
    <div class="wrapper">
      <app-header></app-header>
      <main class="main-clear">
        <div class="selio_sw_win_wrapper">
          <div class="ci sw_widget sw_wrap">
       
            <div class="sign-form-wr">
              <div class="sign-form-inner tab-content">
                <!-- Log In -->
      
                <div
                class="form-wr log-in-form tab-pane fade active show"
                role="tabpanel"
                id="log-in-form"
                id="forgot_password_show"
              >
              <h3>Change Password</h3>
                <div class="form-wr-content">
                  
                 
                  <form id="popup_form_login">
                    <div class="alerts-box"></div>
                    <div class="alert alert-success" role="alert">
                    </div>
                    <div class="alert alert-danger" role="alert">
                    </div>
              
                    <div class="form-field">
                      <input
                        type="password"
                        name="username"
                        class="form-control"
                        placeholder="Old Password"
                        [(ngModel)]="old_password"

                      />
                    </div>
                    <div class="form-field">
                      <input
                        type="password"
                        name="username"
                        class="form-control"
                        placeholder="New Password"
                        [(ngModel)]="new_password"

                      />
                    </div>
                    <div class="form-field">
                      <input
                        type="password"
                        name="username"
                        class="form-control"
                        placeholder="Confirm Password"
                        [(ngModel)]="confirm_password"

                      />
                    </div>
                  
                 
                    <button type="submit" class="btn2" (click)="onChangePasswordClick()">
                     Change Password
                    </button>
                  </form>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <app-footer></app-footer>
    </div>
  </body>
</html>
