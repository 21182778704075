import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css', '../../assets/css/application.css'],
})
export class FaqComponent {
  user_id: any;
  accordian: boolean = false;
  faq_content: any[] = [];
  ngOnInit() {
    window.scrollTo(0,0);
    let user_details: any = localStorage.getItem('agent_login');
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      this.user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      this.user_id = user.user_id;
    }

    this.http
      .post(CommonService.api_url + '/get-faq-list', {
        user_id: this.user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        // this.faq_content = responseData['aaData'];
        this.faq_content = responseData;
        this.faq_content.forEach((item) => {
          item.showAnswer = false;
        });
      });
  }
  toggleAnswer(index: number) {
    this.faq_content[index].showAnswer = !this.faq_content[index].showAnswer;
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
}
