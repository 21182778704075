import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { SearchComponent } from './search/search.component';
import { SigninComponent } from './signin/signin.component';
import { RegisterComponent } from './register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SubmitReviewComponent } from './submit-review/submit-review.component';
import { AboutComponent } from './about/about.component';
import { SingleBlogComponent } from './single-blog/single-blog.component';
import { PropertyComponent } from './property/property.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { CommonService } from './services/common.service';
import { GoogleMapsModule } from '@angular/google-maps';
import { TruncatePipe } from './truncate.pipe';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TenantOrganizationComponent } from './tenant-organization/tenant-organization.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
import { FaqComponent } from './faq/faq.component';
import { CommonListingComponent } from './common-listing/common-listing.component';
import { VerificationComponent } from './verification/verification.component';
import { MyReviewComponent } from './my-review/my-review.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ReviewEditComponent } from './review-edit/review-edit.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

const appRoutes: Routes = [
  { path: 'tenant-organization', component: TenantOrganizationComponent },
  { path: 'community-guidelines', component: CommunityGuidelinesComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'sigin', component: SigninComponent },
  { path: 'submit-review', component: SubmitReviewComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'search', component: SearchComponent },
  { path: 'about', component: AboutComponent },
  { path: 'property-details', component: PropertyComponent },
  { path: 'blog-details', component: SingleBlogComponent },
  { path: 'google-map', component: GoogleMapComponent },
  { path: 'blog-category', component: BlogCategoryComponent },
  { path: 'faqs', component: FaqComponent },
  { path: 'my-review', component: MyReviewComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'review-edit', component: ReviewEditComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset-account/:param', component: ResetPasswordComponent },
  { path: 'verify-account/:param', component: VerificationComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
@NgModule({

  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    BlogComponent,
    ContactComponent,
    SearchComponent,
    SigninComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    SubmitReviewComponent,
    AboutComponent,
    SingleBlogComponent,
    PropertyComponent,
    GoogleMapComponent,
    TruncatePipe,
    PrivacyPolicyComponent,
    TenantOrganizationComponent,
    CommunityGuidelinesComponent,
    BlogCategoryComponent,
    FaqComponent,
    CommonListingComponent,
    VerificationComponent,
    MyReviewComponent,
    MyProfileComponent,
    ReviewEditComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    NgbModule,
    GoogleMapsModule
  ],
  providers: [{
    provide: 'googleMapsApiKey',
    useValue: CommonService.googleMapsApiKey,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
