<!DOCTYPE html>
<html class="no-js" lang="en">
  <!-- Mirrored from geniuscript.com/selio-script/index.php/fquick/submission/en by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 13 Jul 2023 06:38:42 GMT -->
  <!-- Added by HTTrack -->
  <meta
    http-equiv="content-type"
    content="text/html;charset=UTF-8"
  /><!-- /Added by HTTrack -->


  
  <head>
    <meta charset="UTF-8" />
    <title>Quick add listing</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Quick add listing" />
    <meta property="og:url" content="en.html" />
    <meta property="og:description" content="" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
    <style>
      .wrapper-loader {
        width: 100%;
        height: 100%;
        background-color: gainsboro;
        position: fixed;
        left: 0px;
        right: 0px;
        z-index: 99999;
        opacity: 0.8;
      }

      .loader-spinner-img {
        margin: 0 auto;
        display: block;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    </style>
  </head>

  <body>
    <div class="wrapper">
      <div class="wrapper-loader">
        <span class="loader-spinner-img"
          ><img src="assets/img/loader.gif"
        /></span>
      </div>
      <app-header></app-header>
      <div id="success_div">
        <div class="alert alert-success result" role="alert"></div>
      </div>
      <div class="container b-padding" id="main">
        <div class="row">
          <div class="alert alert-success" role="alert"></div>
          <div class="alert alert-danger" role="alert"></div>
          <div class="col-sm-6">
            <div class="widget-panel border widget-submit" id="content">
              <div class="widget-header header-styles">
                <h2 class="title">Property Details</h2>
              </div>
              <div class="content-box local-form">
                <!-- Form starts.  -->

                <form
                  action="https://geniuscript.com/selio-script/index.php/fquick/submission/en#content"
                  method="post"
                  accept-charset="utf-8"
                  class="form-estate form-editproperty"
                  role="form"
                  id="property-submition"
                >
                  <div class="row form-group col-12">
                    <div id="map" style="width: 100%; height: 400px"></div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Property Name
                        <!-- <span class="text-danger">*</span> -->
                        </label
                      >
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Property Name"
                        aria-label="property_name"
                        aria-describedby="basic-addon1"
                        [(ngModel)]="property_name"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Property Type
                        <span class="text-danger">*</span>
                        </label
                      >
                    </div>

                    <div class="col-8">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        [(ngModel)]="property_type_id"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option
                          *ngFor="let data of property_type"
                          [value]="data.property_type_id"
                        >
                          {{ data.property_type_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Building No.<span class="text-danger">*</span></label
                      >
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        class="form-control"
                        id="buliding_no"
                        [(ngModel)]="buliding_no"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Building No"
                        (change)="onChangeBuildingNo()"
                      />
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Street 1<span class="text-danger">*</span></label
                      >
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        name="mail"
                        class="form-control"
                        id="property_address"
                        [(ngModel)]="property_address"
                        (change)="onChangeStreet1()"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Street 1"
                      />
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label">Street 2</label>
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        name="mail"
                        class="form-control"
                        id="property_address2"
                        (change)="onChangeStreet2()"
                        [(ngModel)]="property_address2"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Street 2"
                      />
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label">State</label
                      ><span class="text-danger">*</span>
                    </div>

                    <div class="col-8">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        (change)="onChangeState($event)"
                        [(ngModel)]="state_id"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option
                          *ngFor="let data of state_list"
                          [value]="data.state_id"
                        >
                          {{ data.state_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >City<span class="text-danger">*</span></label
                      >
                    </div>

                    <div class="col-8">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        (change)="onChangeCity($event)"
                        [(ngModel)]="city_id"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option
                          *ngFor="let data of city_list"
                          [value]="data.city_id"
                        >
                          {{ data.city_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label">Area</label>
                    </div>

                    <div class="col-8">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                        [(ngModel)]="city_area_id"
                        (change)="onChangeCityArea($event)"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option
                          *ngFor="let data of city_area_list"
                          [value]="data.city_area_id"
                        >
                          {{ data.city_area }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Property Description<span class="text-danger"
                          >*</span
                        ></label
                      >
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        name="mail"
                        class="form-control"
                        id="property_description"
                        [(ngModel)]="property_description"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Property Description"
                      />
                    </div>
                  </div> -->
                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Zip Code<span class="text-danger">*</span></label
                      >
                    </div>

                    <div class="col-8">
                      <input
                        type="text"
                        name="mail"
                        class="form-control"
                        id="zip_code"
                        [(ngModel)]="zip_code"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>
                  <!-- <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >File Upload<span class="text-danger">*</span></label
                      >
                    </div>
                    <div class="col-8">
                      <input
                        type="file"
                        (change)="onFileSelected($event)"
                        multiple
                        id="inputGroupFile04"
                      />
                    </div>
                  </div>
            

                  <div class="row form-group col-12">
                    <div class="col-4">
                      <label class="control-label"
                        >Review Message<span class="text-danger">*</span></label
                      >
                    </div>
                    <div class="col-8">
                   
                      <textarea
                        id="message"
                        name="message"
                        rows="5"
                        class="resize-vertical form-control"
                        [(ngModel)]="review_message"
                        [ngModelOptions]="{ standalone: true }"
                        placeholder="Write Review"
                      ></textarea>
                    </div>
                  </div> -->
                </form>
              </div>
            </div>
          </div>
          <div class="col-sm-6 review-questions">
            <!-- <div id="map" style="width: 100%; height: 400px;"></div> -->
            <div class="question">
              <div
                class="row question_row"
                *ngFor="let data of question_list; let i = index"
              >
                <div class="row question_type">
                  <h1>{{ data.review_type_name }}</h1>
                </div>
                <div class="row">
                  <label class="question">{{ data.review_question }}</label>
                </div>
                <div class="row">
                  <ngb-rating
                    [max]="5"
                    [(ngModel)]="review_scores[i]"
                    [readonly]="false"
                  ></ngb-rating>
                </div>
              </div>
            </div>
            <form>
              <div class="form-field">
                <h4 class="font_text">Add Review</h4>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  class="resize-vertical form-control"
                  [(ngModel)]="review_message"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Write Review"
                ></textarea>
              </div>

              <div class="form-field">
                <h4 class="font_text">Add Media</h4>
                <input
                  type="file"
                  (change)="onFileSelected($event)"
                  multiple
                  id="inputGroupFile04"
                />
              </div>
              <button (click)="sendPayload()" class="submit_review col-md-12">
                Submit Review
              </button>
            </form>
          </div>

          <!-- <button (click)="requestLocationPermission()">Allow Location</button> -->
        </div>
      </div>
  

      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding" style="z-index: 999">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
  </body>
</html>
