import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css','../../assets/css/application.css']
})
export class ChangePasswordComponent {
  old_password:any;
  new_password:any;
  confirm_password:any;
  user_id:any;
  ngOnInit() {
    window.scrollTo(0,0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
    let user_details: any = localStorage.getItem('agent_login');
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      this.user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      this.user_id = user.user_id;
    }
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onChangePasswordClick(){
if(this. validation()===true){
  this.http
  .post(CommonService.api_url + '/change-password', {
    old_password: this.old_password,
    new_password: this.new_password,
    user_id:this.user_id,
    oauth_key:CommonService.oauth_key
  })
  .subscribe((responseData: any) => {
    if (responseData['is_successful'] === '1') {
      $('.alert-success').html(responseData['success_message']);
      $('.alert-success').show();
      $('.alert-danger').hide();
      setTimeout(() => {
      this.router.navigateByUrl('/home');
      },2000);

      
    }
    if (responseData['is_successful'] === '0') {
      $('.alert-danger').html(responseData['errors']);
      $('.alert-success').hide();
      $('.alert-danger').show();
      window.scrollTo(0, 0);
    }
  });
}
  }
  validation(){
    $('.alert-success').hide();
    $('.alert-danger').hide();
    let error_message='';
    let pass_valid=false;
    let pass_valid_confirm=false;
    if(this.old_password===null|| this.old_password=== undefined|| this.old_password===''){
      error_message+='Please enter Old Password.</br>'; 
    }
    if(this.new_password===null|| this.new_password=== undefined|| this.new_password===''){
      error_message+='Please enter New Password.</br>'; 
      pass_valid=true;
    }
    if(this.confirm_password===null|| this.confirm_password=== undefined|| this.confirm_password===''){
      error_message+='Please enter confirm Password.</br>'; 
      pass_valid_confirm=true;
    }
    if (pass_valid===false) {
      if (pass_valid_confirm===false) {
        if (this.new_password != this.confirm_password) {
          error_message += 'Please enter correct confirm password.</br>';
        }
      }
    }
    if(error_message!=''){
      $('.alert-danger').html(error_message);
      $('.alert-danger').show();
      return false;
    }else{ 
      return true;
    }
  }
}
