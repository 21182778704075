import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-my-review',
  templateUrl: './my-review.component.html',
  styleUrls: ['./my-review.component.css','../../assets/css/application.css']
})
export class MyReviewComponent {
  user_id:any;
  review_data_table:any[]=[];
  show_table:boolean=false;
  hide_table:boolean=false;
  ngOnInit() {
    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    this.user_id = user.user_id; 
 
    
    this.http
    .post(CommonService.api_url + '/get-reviews-by-user', {
      user_id: this.user_id,
      oauth_key: CommonService.oauth_key,
    })
    .subscribe((responseData: any) => {
      if (responseData['is_successful'] === '1') {
        this.review_data_table=responseData['data'];
       if(this.review_data_table.length===0){
this.show_table=false;
this.hide_table=true
       }
       else{
        this.show_table=true;
        this.hide_table=false;
       }
        
      } 
    });
  }
onAddReview(){
  this.router.navigateByUrl('/submit-review');
}
openHomePage(){
  this.router.navigateByUrl('/home');
}
onEditClick(property_id:any,review_id:any){
  localStorage.setItem('property_id', property_id);
  localStorage.setItem('review_id', review_id);
  this.router.navigateByUrl('/review-edit');
}
constructor(
  private router: Router,
  private commonService: CommonService,
  private http: HttpClient
) {}
}
