<!DOCTYPE html>
<html class="no-js" lang="en">
  <!-- Added by HTTrack -->

  <head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->
    <meta charset="UTF-8" />
    <title>About</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta
      name="description"
      content="Nam eget est facilisis, porta mi ac, ultricies enim. Proin nisi diam, eleifend ac eleifend in, dapibus in orci. Vestibulum elementum lectus non nisl venenatis,&#8230;"
    />
    <meta name="keywords" content="test" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Test page" />
    <meta property="og:url" content="index.php/en/142/test-page.html" />
    <meta
      property="og:description"
      content="Nam eget est facilisis, porta mi ac, ultricies enim. Proin nisi diam, eleifend ac eleifend in, dapibus in orci. Vestibulum elementum lectus non nisl venenatis,&#8230;"
    />
    <meta
      property="og:image"
      content="../../assets/img/default-image-og.webp"
    />
  </head>

  <body>
    <div class="wrapper">
      <app-header></app-header>
      <section class="pager-sec bfr widget_edit_enabled">
        <div class="container">
          <div class="pager-sec-details">
            <h3>FAQs</h3>
            <ul class="breadcrumb">
              <li>
                <a href="index.html">Home</a><span class="delimiter"></span>
              </li>
              <li><span>FAQs</span><span class="delimiter"></span></li>
            </ul>
          </div>
          <!--pager-sec-details end-->
        </div>
      </section>
      <section class="listing-main-sec section-padding2">
        <div class="container">
          <div class="listing-main-sec-details">
            <div class="row">
              <div class="col-lg-8">
                <div class="blog-single-post single">
                  
                  <div class="">
                    
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div
                        class="accordion-item"
                        *ngFor="let data of faq_content; let i = index"
                      >
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            (click)="toggleAnswer(i)"
                          >
                            {{ data.faq_question }}
                          </button>
                        </h2>
                        <div
                          class="accordion-collapse"
                          *ngIf="data.showAnswer"
                          aria-labelledby="flush-headingOne"
                        >
                          <div class="accordion-body">
                            {{ data.faq_answer }}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <app-common-listing></app-common-listing>
              </div>
            </div>
          </div>
        </div>
      </section>
      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
    <!--wrapper end-->
  </body>
</html>
