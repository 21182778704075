<!doctype html>
<html class="no-js" lang="en">

<!-- Mirrored from geniuscript.com/selio-script/index.php/en/158/test_article_2 by HTTrack Website Copier/3.x [XR&CO'2014], Thu, 13 Jul 2023 07:14:31 GMT -->
<!-- Added by HTTrack -->


<head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" ><!-- /Added by HTTrack -->
    <meta charset="UTF-8">
    <title>{{this.blog_title}}</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="description"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sollicitudin commodo cursus. Nunc varius accumsan ultrices. Quisque hendrerit mi id ullamcorper&#8230;" >
    <meta name="keywords" content="Test article 2" >
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="author" content="">

    <meta property="og:site_name" content="Real estate point" >
    <meta property="og:title" content="Real estate point - Test article 2" >
    <meta property="og:url" content="test_article_2.html" >
    <meta property="og:description"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sollicitudin commodo cursus. Nunc varius accumsan ultrices. Quisque hendrerit mi id ullamcorper&#8230;" >
        <link
        href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
        rel="stylesheet"
      />

   
</head>

<body>
    <div class="wrapper">
   <app-header></app-header>
        <section class="pager-sec bfr widget_edit_enabled">
            <div class="container">
                <div class="pager-sec-details">
                    <h3 class="pointer">{{this.blog_title}}</h3>
                    <ul class="breadcrumb" class="pointer">
                        <li class="pointer"><a (click)="onHome()">Home</a><span class="delimiter"></span></li>
                        <li class="pointer"><a class="pointer">Blog</a><span class="delimiter"></span></li>
                        <li class="pointer"><span class="pointer">{{this.blog_title}}</span><span class="delimiter"></span></li>
                        
                    </ul>
                </div><!--pager-sec-details end-->
            </div>
        </section>
        <section class="listing-main-sec section-padding2">
            <div class="container">
                <div class="listing-main-sec-details">
                    <div class="row">
                        <div class="col-lg-8">

                            <div class="blog-single-post single">
                                <h3>{{this.blog_title}}</h3>
                                <div class="blog-img">
                                    <img [src]="blog_image" class="wp-post-image"
                                        alt="Test article 2" >
                                </div>
                                <div class="WYSIWYG_content" [innerHTML]="blog_description">
                               
                                </div>

                            </div>

                            </div>
                            <div class="col-lg-4">
                                <app-common-listing></app-common-listing>
                                  </div>
                    </div>
                </div><!--listing-main-sec-details end-->
            </div>
        </section><!--listing-main-sec end-->
        <a  title="" class="widget_edit_enabled">
            <section class="cta section-padding" >
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="cta-text">
                                <h2>Discover a home you'll love to stay</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </a>
  <app-footer></app-footer>

    <div class="se-pre-con"></div>

   </div>
</body>


</html>



