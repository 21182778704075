<div class="sidebar layout2">


    <div class="widget widget-catgs widget_edit_enabled">
        <h3 class="widget-title blog_T">Blog Categories</h3>
        <ul>
            <li *ngFor="let data of blog_categories">
                <a (click)="onCategoryClick(data.blog_category_id,data.category_name)"  class="pointer" title="{{data.category_name}}"> 
                    - <span style="font-family:'Poppins', 'Roboto' !important;">{{data.category_name}}</span></a>
                <!-- <span>0</span> -->
            </li>
           
        </ul>
    </div><!--widget-catgs end-->

    <div class="widget widget-posts widget_edit_enabled">
        <h3 class="widget-title ">Popular Listings</h3>
        <ul>
            <li *ngFor="let data of blog_popular_listing" (click)="onPopularListingClick(data.property_id)">
                <div class="wd-posts">
                    <div class="ps-img">
                        <a  class="pointer">
                            <img [src]='data.media_url'
                                alt="test 1">
                        </a>
                    </div><!--ps-img end-->
                    <div class="ps-info">
                        <!-- <h3><a  class="pointer">{{data.property_name}}
                                </a></h3> -->
                        <strong>
                        </strong>
                        <span><i class="fa fa-map-marker"></i><span class="property-details">{{data.property_details}}</span></span>
                    </div><!--ps-info end-->
                </div><!--wd-posts end-->
            </li>
          
        </ul>
    </div><!--widget-posts end-->

</div><!--sidebar end-->