import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css', '../../assets/css/application.css'],
})
export class RegisterComponent {
  fullname: any;
  email: any;
  password: any;
  confirmPassword: any;
  selected_role: any;
  phone: any;

  ngOnInit() {
    window.scrollTo(0, 0);
    $('.alert-danger').hide();
    $('.alert-success').hide();
    this.selected_role = 'Renter';
    // CommonService.ActiveClass('nav-register');
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onCreateAccount() {
    if (this.validation() === true) {
      if (
        this.phone === undefined ||
        this.phone === null ||
        this.phone === ''
      ) {
        this.phone = '';
      }
      this.http
        .post(CommonService.api_url + '/register-customer', {
          user_email: this.email,
          full_name: this.fullname,
          password: this.password,
          mobile_no: this.phone,
          register_as: this.selected_role,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((responseData: any) => {
          if (responseData['is_successful'] === '1') {
            $('.alert-success').html(responseData['success_message']);
            $('.alert-success').show();
            $('.alert-danger').hide();
            $('.wrapper-loader').hide();
            // window.location.reload();
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 3000);
          }
          if (responseData['is_successful'] === '0') {
            $('.alert-danger').html(responseData['errors']);
            $('.alert-success').hide();
            $('.alert-danger').show();
            $('.wrapper-loader').hide();
            // CommonService.uploadData = new FormData();
          }
        });
    } else {
      return;
    }
  }
  validation() {
    let error = '';
    let pass_valid=false;
    let pass_valid_confirm=false;
    if (
      this.fullname === '' ||
      this.fullname === null ||
      this.fullname === undefined
    ) {
      error += 'Please enter your Full name.</br>';
    }
    if (this.email === '' || this.email === undefined || this.email === null) {
      error += 'Please enter your Email address.</br>';
    }

    if (
      this.password === '' ||
      this.password === null ||
      this.password === undefined
    ) {
      error += 'Please enter password.</br>';
      pass_valid=true;
    }
    if (
      this.confirmPassword === '' ||
      this.confirmPassword === null ||
      this.confirmPassword === undefined
    ) {
      error += 'Please enter confirm password.</br>';
      pass_valid_confirm=true;
    }

    if (
      this.selected_role === '' ||
      this.selected_role === null ||
      this.selected_role === undefined
    ) {
      error += 'Please select User Type.</br>';
    }
    if (pass_valid===false) {
      if (pass_valid_confirm===false) {
        if (this.password != this.confirmPassword) {
          error += 'Please enter correct confirm password.</br>';
        }
      }
    }

    if (error != '') {
      $('.alert-danger').html(error);
      $('.alert-success').hide();
      $('.alert-danger').show();
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }
}
