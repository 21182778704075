import { Component } from '@angular/core';
import { IpServiceService } from './ip-service.service';  
import { GoogleMapsService } from './google-maps.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'rental-review-front';
  ipAddress:any;  
  constructor(private ip:IpServiceService){}  

ngOnInit()  

  {  
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
      });
    } 
   
    this.getIP();  
  }  
  getIP()  
  {  
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
      // console.log(this.ipAddress);
    });  
  }  
}
