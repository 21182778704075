import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-single-blog',
  templateUrl: './single-blog.component.html',
  styleUrls: [
    './single-blog.component.css',
    '../../assets/css/application.css',
    '../header/header.component.css',
  ],
})
export class SingleBlogComponent {
  blog_details: any[] = [];
  blog_categories: any[] = [];
  blog_popular_listing: any[] = [];
  blog_description: any;
  blog_title: any;
  blog_image: any;

  ngOnInit() {
    window.scrollTo(0,0);
    CommonService.ActiveClass('nav-blog');
    // get blog categories
    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      user_id = user.user_id;
    }
    this.http
      .post(CommonService.api_url + '/get-blog-categories', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_categories = responseData['data'];
      });

    // get popular reviews
    this.http
      .post(CommonService.api_url + '/get-popular-reviews', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_popular_listing = responseData['data'];
      });

    // get blog details
    let blog_slug: any = localStorage.getItem('blog_slug');
    this.http
      .post(CommonService.api_url + '/get-blog', {
        oauth_key: CommonService.oauth_key,
        slug: blog_slug,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_details = responseData['data'];
        this.blog_description = responseData['data']['blog_description'];
        this.blog_title = responseData['data']['title'];
        this.blog_image = responseData['data']['blog_image'];
      });
  }
  onCategoryClick(blog_category_id: any, category_name: any) {
    let user_details: any = localStorage.getItem('agent_login');
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    this.http
      .post(CommonService.api_url + '/get-Blogs-By-Category', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
        blog_category_id: blog_category_id,
      })
      .subscribe((responseData: any) => {
        let blog_categories = responseData['data'];
        localStorage.setItem('blog_category_name', category_name);
        localStorage.setItem(
          'blog_categories',
          JSON.stringify(blog_categories)
        );
        this.router.navigateByUrl('/blog');
      });
  }
  onHome(){
    this.router.navigateByUrl('/home');
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
}
