import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private readonly apiKey = 'YOUR_GOOGLE_MAPS_API_KEY';

  constructor(private http: HttpClient) {}

  geocodeAddress(address: string): Observable<any> {
    const encodedAddress = encodeURIComponent(address);
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${this.apiKey}`;
    
    return this.http.get(apiUrl);
  }

}
