<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->

    <meta charset="UTF-8" />
    <title>Contacts</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Homepage" />
    <meta property="og:url" content="/home" />
    <meta property="og:description" content="" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
  </head>

  <body class="modal-backdrop-effect dissable-sticky">
    <div class="container_main">
      <div class="wrapper">
        <app-header></app-header>

        <section>
          <div class="col-12 height_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3309.0505002216105!2d-84.2199976233097!3d33.96554147318982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5a105886eeb5b%3A0xff187b14d9e36464!2s130%20Technology%20Pkwy%2C%20Norcross%2C%20GA%2030092%2C%20USA!5e0!3m2!1sen!2sin!4v1695623635417!5m2!1sen!2sin"
              class="map_width"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>

        <div class="contact-sec">
          <div class="container">
            <div class="contact-details-sec">
              <div class="row">
                <div class="col-lg-8 col-md-8 pl-0 pr-0">
                  <div class="alert alert-success" role="alert">
                    {{ this.display_success_msg }}
                  </div>
                  <div class="alert alert-danger" role="alert">
                    {{ this.display_failed_msg }}
                  </div>
                  <div class="contact_form">
                    <h3>Contact</h3>
                    <form method="post" class="contact-form">
                      <div class="form-fieldss">
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <div class="form-field">
                              <input
                                type="text"
                                id="firstname"
                                name="firstname"
                                class=""
                                placeholder="First and last name"
                                [(ngModel)]="name"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div class="form-field">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                class=""
                                placeholder="Email"
                                [(ngModel)]="email"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div class="form-field">
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                class=""
                                placeholder="Phone"
                                [(ngModel)]="phone"
                              />
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12">
                            <div class="form-field">
                              <textarea
                                class=""
                                id="message"
                                name="message"
                                rows="10"
                                placeholder="Message"
                                [(ngModel)]="message"
                              ></textarea>
                            </div>
                          </div>
                          <!-- <div class="col-lg-12 col-md-12">
                                                    <div class="form-field ">
                                                        <div class="form_captcha">

                                                            <div class="input-control">
                                                                <input class="captcha  " name="captcha" type="text"
                                                                    placeholder="Enter code from image" value="" >
                                                                <input class="hidden" name="captcha_hash" type="text"
                                                                    value="77f3c" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                          <div class="col-lg-12 col-md-12 pl-0">
                            <button
                              type="submit"
                              class="btn-default submit"
                              (click)="onSendMessage()"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                      <!--form-fieldss end-->
                    </form>
                  </div>
                  <!--contact_form end-->
                </div>
                <div class="col-lg-4 col-md-4 pr-0">
                  <div class="contact_info">
                    <span style="font-weight: bold">Contact Point</span
                    ><br /><span
                      ><span style="font-weight: bold"
                        ><span><span> Rental Review</span></span></span
                      ></span
                    ><br />130 Technology Pkwy,<br /> Norcross, GA 30092, USA<br /><br />
                    <!-- <span
                      style="font-weight: bold"
                      ><span>Tel:</span>
                      </span> -->
                    <!-- +1 937-343-5697<br /> -->
                    <span style="font-weight: bold"
                      ><span>Mail:</span></span
                    >
                    contact@rentalreviews365.com<br />
                  </div>
                </div>
              </div>
            </div>
            <!--contact-details-sec end-->
          </div>
        </div>
        <!--contact-sec end-->

        <footer class="footer">
          <div class="container">
            <div class="row justify-content-between"></div>
          </div>
        </footer>
        <!--footer end-->
      </div>
      <!--wrapper end-->

      <div class="popup" id="sign-popup">
        <h3>Sign In to your Account</h3>
        <div class="popup-form form-wr">
          <form id="popup_form_login">
            <div class="alerts-box"></div>
            <div class="form-field">
              <input
                type="text"
                name="username"
                value=""
                class="form-control"
                id="inputUsername"
                placeholder="Username"
              />
            </div>
            <div class="form-field">
              <input
                type="password"
                name="password"
                value=""
                class="form-control"
                id="inputPassword"
                placeholder="Password"
              />
            </div>
            <div class="form-cp">
              <div class="form-field">
                <div class="input-field">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    value="true"
                  />
                  <label for="remember">
                    <span></span>
                    <small>Remember me</small>
                  </label>
                </div>
              </div>
              <a
                class="forgot-password"
                title="Forgot Password?"
                style="color: #13a89e"
                >Forgot Password?</a
              >
            </div>
            <!--form-cp end-->
            <button
              type="submit"
              class="btn2"
              style="background-color: #13a89e"
            >
              Sign In<i
                class="fa fa-spinner fa-spin fa-ajax-indicator hidden ajax-indicator"
              ></i>
            </button>
          </form>
          <a class="link-bottom">Create new account</a>
        </div>
      </div>
      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home where you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>

      <app-footer></app-footer>
      <div class="se-pre-con"></div>
    </div>
  </body>
</html>
