import { Component, ElementRef } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeocodingService } from '../geocoding.service';
import { GoogleMapsService } from '../google-maps.service';
import { IpServiceService } from '../ip-service.service';
import { iif } from 'rxjs';
declare var google: any;
@Component({
  selector: 'app-submit-review',
  templateUrl: './submit-review.component.html',
  styleUrls: [
    './submit-review.component.css',
    '../../assets/css/application.css',
    '../header/header.component.css',
  ],
})
export class SubmitReviewComponent {
  selectedMarker: google.maps.Marker | null = null;
  markers: google.maps.Marker[] = []; 
  address: string = '';
  latitude: number = 0;
  longitude: number = 0;
  property_name: any;
  property_address: any;
  review_message: any;
  property_address2: any;
  buliding_no: any;
  property_type_id: any;
  state_id: any;
  city_id: any;
  zip_code: any;
  city_area_id: any;
  property_type: any[] = [];
  state_list: any[] = [];
  city_list: any[] = [];
  city_area_list: any[] = [];
  question_list: any[] = [];
  payload: any[] = [];
  uploadedFiles: File[] = [];
  map: any;
  // property_description: any;
  errorMessage: any;
  display_failed_msg: any;
  display_success_msg: any;
  review_scores: number[] = new Array(this.question_list.length).fill(0);
  review_rateing_list: any[] = new Array(this.question_list.length).fill(0);
  title = 'rental-review-front';
  ipAddress: any;
  location: any;
  state_name: any;
  city_name: any;
  city_area_name: any;
  draggedFiles: boolean = false;
  filelength: any;
  centerLat: any;
  centerLng: any;

  ngOnInit() {
    // this.initMap();
    window.scrollTo(0,0);
    CommonService.uploadData=new FormData();
    CommonService.ActiveClass('nav-submit');
    this.commonService.IsUserLogIn();
    $('#map').hide();
    $('.alert-success').hide();
    $('.alert-danger').hide();
    $('.wrapper-loader').hide();
    $('#success_div').hide();
   
    let user_details: any = localStorage.getItem('agent_login');

    let user = JSON.parse(user_details);
    let user_role_id = user.user_role_id;
    let user_id = user.user_id;

    this.getIP();
    this.http
      .post(CommonService.api_url + '/property-type-list-internal', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.property_type = responseData['data'];
        this.property_type_id=this.property_type[0].property_type_id; // const mapElement:any = document.getElementById('map'); // Replace 'map' with your map element ID
        // const address = 'Your Address'; // Replace with the address you want to display
    
        // // Load the Google Maps API and initialize the map
        // this.googleMapsService.loadAPI().subscribe(() => {
        //   this.googleMapsService.initMapWithAddress(mapElement, address);
        // });
        //API for state list
        // if ('geolocation' in navigator) {
        //   navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //       const latitude = position.coords.latitude;
        //       const longitude = position.coords.longitude;
        //       console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        //     },
        //     (error) => {
        //       console.error('Error getting location:', error);
        //     }
        //   );
        // } else {
        //   console.error('Geolocation is not supported in this browser.');
        // }
        // this.city_id=this.city_list[0].city_id;
      });
    this.http
      .post(CommonService.api_url + '/state-list-internal', {
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.state_list = responseData['data'];

        this.state_id = this.state_list[0].state_id;
        this.onChangeState({ target: { value: this.state_id } });
      });

    this.http
      .post(CommonService.api_url + '/get-review-questions', {
        user_id: user_id,
        user_role_id: user_role_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData3: any) => {
        this.question_list = responseData3['data'];

        $('document').ready(function () {
          let arQTypes: any[] = [];
          $('.question_type').each(function (a, b) {
            if (jQuery.inArray($(b).text(), arQTypes) > -1) {
              $(this).hide();
              // console.log("Hidden " + $(this).text());
            } else {
              arQTypes.push($(this).text());
            }
          });
        });

        // this.city_id=this.city_list[0].city_id;
      });
    // Check if the browser supports Geolocation
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        
      });
     
    } 
  }
  // initMap(event: any): void {
  //   // Replace 'YOUR_ADDRESS' with the actual address you want to display
  //   const address = event;

  //   const mapOptions: google.maps.MapOptions = {
  //     center: { lat: 0, lng: 0 }, // Set the initial map center
  //     zoom: 15, // Set the initial zoom level
  //   };

  //   const map = new google.maps.Map(document.getElementById('map'), mapOptions);

  //   // Use the Geocoder to convert the address into coordinates and set the marker
  //   const geocoder = new google.maps.Geocoder();
  //   geocoder.geocode({ address: address }, (results: any, status: any) => {
  //     if (status === 'OK' && results[0]) {
  //       const location = results[0].geometry.location;
  //       map.setCenter(location);

  //       new google.maps.Marker({
  //         position: location,
  //         map: map,
  //         title: address,
  //       });

  //       // Get the coordinates (latitude and longitude) of the map's center
  //       this.centerLat = map.getCenter().lat();
  //       this.centerLng = map.getCenter().lng();

  //       // Now you can use centerLat and centerLng as the coordinates of the map's center
  //       // console.log('Latitude:', this.centerLat);
  //       // console.log('Longitude:', this.centerLng);
  //     } else {
  //       // console.error(
  //       //   'Geocode was not successful for the following reason: ' + status
  //       // );
  //     }
  //   });
  // }
  initMap(event: any): void {
    const address = event;
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 0, lng: 0 }, // Set the initial map center
      zoom: 15, // Set the initial zoom level
    };

    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    // Use the Geocoder to convert the address into coordinates and set the marker
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results: any, status: any) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location;
        this.map.setCenter(location);

        const marker = new google.maps.Marker({
          position: location,
          map: this.map,
          title: address,
          draggable: true, // Allow the marker to be dragged
        });

        // Store the marker in the markers array
        this.markers.push(marker);

        // Get the coordinates (latitude and longitude) of the map's center
        this.centerLat = this.map.getCenter().lat();
        this.centerLng = this.map.getCenter().lng();

        // Add an event listener to update the marker's position when dragged
        google.maps.event.addListener(marker, 'drag', (event: any) => {
          // Update the marker's position in real-time
          this.centerLat = event.latLng.lat();
          this.centerLng = event.latLng.lng();
          // console.log( 'lat',this.centerLat);
          // console.log( 'lag',this.centerLng);

        });

        // Add a click event listener to select the marker
    
        google.maps.event.addListener(marker, 'click', () => {
          this.selectedMarker = marker;
          marker.setDraggable(true); // Make the marker draggable on click
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }
  // ngAfterViewInit(): void {
  //   const mapElement:any = document.getElementById('map'); // Replace 'map' with your map element ID
  //   const address = 'Your Address'; // Replace with the address you want to display

  //   // Load the Google Maps API and initialize the map
  //   this.googleMapsService.loadAPI().subscribe(() => {
  //     this.googleMapsService.initMapWithAddress(mapElement, address);
  //   });
  // }
  getIP() {
    this.ip.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      // console.log(this.ipAddress);
    });
  }
  onFileSelected(event: any): void {
    this.uploadedFiles = [];
    this.uploadedFiles.push(...event.target.files);
    $('.alert-danger').hide();
    $('.alert-success').hide();
    CommonService.uploadData = new FormData();
    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    let user_id = user.user_id;
    // const file: File = this.uploadedFiles[0];
    // this.filelength = event.target.files.length;
    for (let i = 0; i < this.uploadedFiles.length; i++) {
      const file: File = this.uploadedFiles[i];
      // CommonService.uploadData.append(`files[${i}]`, file);
      CommonService.uploadData.append(`files`, file);
    }
    this.filelength = event.target.files.length;
    CommonService.uploadData.append('user_id', user_id);
    // CommonService.uploadData.append('files', file);
    CommonService.uploadData.append('created_by', user_id);
  }
  constructor(
    private ip: IpServiceService,
    private googleMapsService: GoogleMapsService,
    private el: ElementRef,
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient,
    private geocodingService: GeocodingService
  ) {}
  // Change event of state dropdown
  onChangeState(event: any) {
    // API for city list

    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    let user_role_id = user.user_role_id;
    let user_id = user.user_id;
    const state_id = parseInt(event.target.value);
    this.state_name = this.state_list.filter(
      (ele: any) => ele.state_id == state_id
    )[0].state_name;
    this.http
      .post(CommonService.api_url + '/city-list-internal', {
        state_id: state_id,
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.city_list = responseData['data'];
        if (this.city_list.length == 0) {
          this.city_id = 0;
          this.city_name = '';
        } else {
          // this.city_id = this.city_list[0].city_id;
          this.onChangeCity({ target: { value: this.city_id } });
        }
      });
    if (this.validtionaddress() == true) {
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null ||
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }
  // Change event of city dropdown
  onChangeCity(event: any) {
    // API for city area list
    this.city_area_list = [];
    this.city_area_id = 0;
    this.city_area_name = '';
    let user_details: any = localStorage.getItem('agent_login');
    let user = JSON.parse(user_details);
    let user_role_id = user.user_role_id;
    let user_id = user.user_id;
    const city_id = parseInt(event.target.value);
    this.city_name = this.city_list.filter(
      (ele: any) => ele.city_id == city_id
    )[0].city_name;
    this.http
      .post(CommonService.api_url + '/get-city-area-list-internal', {
        city_id: city_id,
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.city_area_list = responseData['data'];
        if (this.city_area_list.length == 0) {
          this.city_area_id = 0;
          this.city_area_name = '';
        } else {
          // this.city_area_id = this.city_area_list[0].city_area_id;
          this.onChangeCityArea({ target: { value: this.city_area_id } });
        }
      });
    if (this.validtionaddress() == true) {
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null ||
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }

  sendPayload() {
    $('.wrapper-loader').show();
    this.reviewRatings();
    if (!this.validation()) {
      return;
    } else {
      // This array contains questions and review scores
      // Here you can send the payload as needed, e.g., with an HTTP request.
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null
      ) {
        this.property_name = '';
      }
      if (
               this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null
      ) {
       
        this.property_address2 = '';
      }
      let user_details: any = localStorage.getItem('agent_login');
      let user = JSON.parse(user_details);
      let user_id = user.user_id;
      this.http
        .post(CommonService.api_url + '/addProperty', {
          user_id: user_id,
          property_type: parseFloat(this.property_type_id),
          property_name: this.property_name,
          building_no: this.buliding_no,
          property_address: this.property_address,
          property_address2: this.property_address2,
          property_area: parseFloat(this.city_area_id),
          property_latitude: this.centerLat,
          property_longitude: this.centerLng,
          city_id: parseFloat(this.city_id),
          state_id: parseFloat(this.state_id),
          zip_code: this.zip_code,
          // description: this.property_description,
          created_by: user_id,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((responseData: any) => {
          if (responseData['is_successful'] === '1') {
            // $('.wrapper-loader').hide();
            // this.display_success_msg = responseData['success_message'];
            let property_id = responseData['data'][0]['property_id'];
            $('.alert-success').html(responseData['success_message']);
            $('.alert-success').show();
            $('.alert-danger').hide();
            if(property_id != null || property_id != undefined || property_id){
        
            // if(this.location.latitude === null || this.location.latitude === ''|| this.location.longitude === undefined)
            // {
            //   this.location.latitude=0;
            // }
            // if(this.location.longitude === null || this.location.longitude === ''|| this.location.longitude=== undefined)
            // {
            //   this.location.longitude=0;
            // }
            if( this.location ==undefined || this.location== null || this.location==''){
              this.location = {
                latitude: 0,
                longitude: 0,
              };
            }
            if(this.review_message === null || this.review_message === ''|| this.review_message === undefined)
            {
              this.review_message='';
            }
            this.http
              .post(CommonService.api_url + '/submit-review', {
                user_id: user_id,
                property_id: property_id,
                created_by: user_id,
                review_message: this.review_message,
                oauth_key: CommonService.oauth_key,
                reviews: this.review_rateing_list,
                reviewer_latitude: this.location.latitude,
                reviewer_longitude: this.location.longitude,
                reviewer_ip_address: this.ipAddress,
              })
              .subscribe((responseData1: any) => {
                if (responseData1['is_successful'] === '1') {

                  let review_id = responseData1['data']['review_id'];
                  if( CommonService.uploadData != new FormData()){
                    CommonService.uploadData.append('review_id', review_id);
                    this.http
                      .post(
                        CommonService.api_url + '/upload-review-media',
                        CommonService.uploadData
                      )
                      .subscribe((responseData2: any) => {
                        if (responseData2['is_successful'] === '1') {
                          $('.wrapper-loader').hide();
                          $('#main').hide();
                          $('#success_div').show();

                          $('.alert-success').html(
                            responseData1['success_message']
                          );
                          $('.alert-success').show();
                          $('.alert-danger').hide();
                          window.scrollTo(0, 0);
                          // this.router.navigate(['/home']);
                        } else {
                          $('.wrapper-loader').hide();
                          $('.alert-danger').html(responseData2['errors']);
                          $('.alert-success').hide();
                          $('.alert-danger').show();
                          window.scrollTo(0, 0);
                        }
                      });
                  }
                  else{
                    $('#main').hide();
                    $('#success_div').show();
                    $('.alert-success').html(
                      responseData1['success_message']
                    );
                    $('.alert-success').show();
                    $('.alert-danger').hide();
                    window.scrollTo(0, 0);
                  }
                
                }
                if (responseData1['is_successful'] === '0') {
                  $('.wrapper-loader').hide();
                  $('.alert-danger').html(responseData1['errors']);
                  $('.alert-success').hide();
                  $('.alert-danger').show();
                  window.scrollTo(0, 0);
                }
              });
          }
        }
           else {
            $('.wrapper-loader').hide();
            $('.alert-danger').html(responseData['errors']);
            $('.alert-success').hide();
            $('.alert-danger').show();
            window.scrollTo(0, 0);
          }
        });
    }
  }
  reviewRatings(){
    if (this.review_scores.length > 0) {
      this.question_list.forEach((question_list, index) => {
        if (
          this.review_scores[index] == undefined ||
          this.review_scores[index] == null
        ) {
          this.review_scores[index] = 0;
        } else {
          this.review_scores[index] = this.review_scores[index];
        }
        question_list.review_score = this.review_scores[index];
        question_list.review_question_id =
          this.question_list[index].review_question_id;
        question_list.review_type_id =
          this.question_list[index].review_type_id;
      });
    } else {
      this.question_list.forEach((question_list, index) => {
        question_list.review_score = 0;
        question_list.review_question_id =
          this.question_list[index].review_question_id;
        question_list.review_type_id =
          this.question_list[index].review_type_id;
      });
    }
    for (let i = 0; i < this.question_list.length; i++) {
      this.review_rateing_list[i] = {
        review_type_id: this.question_list[i].review_type_id,
        review_question_id: this.question_list[i].review_question_id,
        review_score: this.question_list[i].review_score,
      };
    }
  }
  findLocation() {
    this.geocodingService
      .geocodeAddress(this.address)
      .subscribe((data: any) => {
        if (data.status === 'OK' && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          this.latitude = location.lat;
          this.longitude = location.lng;
        } else {
          // Handle geocoding errors
          console.error('Geocoding error:', data.status);
        }
      });
  }
  requestLocationPermission() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Do something with the latitude and longitude, e.g., display them
          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        },
        (error) => {
          if (error.code === 1) {
            // Handle the "User denied Geolocation" error
            this.errorMessage =
              'You have denied geolocation permission. Please enable it in your browser settings.';
          } else {
            // Handle other geolocation errors
            this.errorMessage = `Error getting location: ${error.message}`;
          }
        }
      );
    } else {
      // Geolocation is not available in this browser
      this.errorMessage = 'Geolocation is not available in this browser.';
    }
  }
  validation() {
    let buliding_no = this.buliding_no;
    let property_type_id = this.property_type_id;
    let state_id = this.state_id;
    let city_id = this.city_id;
    let zip_code = this.zip_code;
    // let city_area_id = this.city_area_id;
    // let property_description = this.property_description;
    let property_name = this.property_name;
    let property_address = this.property_address;
    let property_address2 = this.property_address2;
    let review_message = JSON.stringify(this.review_message);
    let errorMessage = '';
    if (buliding_no == '' || buliding_no == undefined || buliding_no == null) {
      errorMessage += 'Please enter buliding no.<br>';
    }
    if (
      property_type_id == '' ||
      property_type_id == undefined ||
      property_type_id == null
    ) {
      errorMessage += 'Please select property type.<br>';
    }
    if (state_id == '' || state_id == undefined || state_id == null) {
      errorMessage += 'Please select state.<br>';
    }
    if (city_id == '' || city_id == undefined || city_id == null) {
      errorMessage += 'Please select city.<br>';
    }
    if (zip_code == '' || zip_code == undefined || zip_code == null) {
      errorMessage += 'Please enter zip code.<br>';
    }

    // if (
    //   property_name == '' ||
    //   property_name == undefined ||
    //   property_name == null
    // ) {
    //   errorMessage += 'Please enter property name.<br>';
    // }
    if (
      property_address == '' ||
      property_address == undefined ||
      property_address == null
    ) {
      errorMessage += 'Please enter property street 1.<br>';
    }

    let valid = false;
    for (let i = 0; i < this.review_rateing_list.length; i++) {
      if (this.review_rateing_list[i].review_score === 0) {
        valid = true;
      }
    }
    if (valid === true) {
      errorMessage += 'Please select rating for all questions.<br>';

    }
    if (this.review_rateing_list.length == 0) {
      errorMessage += 'Please select rating for all questions.<br>';
    }
    if (errorMessage != '') {
      this.display_failed_msg = errorMessage;
      $('.alert-danger').html(errorMessage);
      $('.alert-success').hide();
      $('.alert-danger').show();
      window.scrollTo(0, 0);
      $('.wrapper-loader').hide();
      return false;
    } else {
      return true;
    }
  }

  onChangeBuildingNo() {
    if (this.validtionaddress() == true) {
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null ||
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }
  onChangeStreet1() {
    if (this.validtionaddress() == true) {
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null ||
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }
  onChangeStreet2() {
    if (this.validtionaddress() == true) {
      if (
        this.property_name == ''|| 
        this.property_name == undefined ||
        this.property_name == null ||
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }
  onChangeCityArea(event: any) {
    let city_area_id = parseInt(event.target.value);
    this.city_area_name = this.city_area_list.filter(
      (ele: any) => ele.city_area_id == city_area_id
    )[0].city_area;
    if (this.validtionaddress() == true) {
      if (
        this.property_address2 == '' ||
        this.property_address2 == undefined ||
        this.property_address2 == null || this.city_area_name||this.city_area_name == undefined ||
        this.city_area_name == null 
      ) {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      } else {
        this.address =
          this.buliding_no +
          ',' +
          this.property_address +
          ',' +
          this.property_address2 +
          ',' +
          this.city_area_name +
          ',' +
          this.city_name +
          ',' +
          this.state_name;
      }
      $('#map').show();
      this.initMap(this.address);
    } else {
      return;
    }
  }
  validtionaddress() {
    let error_message = '';
    if (
      this.buliding_no == '' ||
      this.buliding_no == undefined ||
      this.buliding_no == null
    ) {
      error_message += 'Please enter buliding no.<br>';
    }
    if (
      this.property_address == '' ||
      this.property_address == undefined ||
      this.property_address == null
    ) {
      error_message += 'Please enter property street 1.<br>';
    }
    // if (
    //   this.property_address2 == '' ||
    //   this.property_address2 == undefined ||
    //   this.property_address2 == null
    // ) {
    //   error_message += 'Please enter property street 2.<br>';
    // }
    // if (
    //   this.city_area_name == '' ||
    //   this.city_area_name == undefined ||
    //   this.city_area_name == null
    // ) {
    //   error_message += 'Please select city area.<br>';
    // }
    if (
      this.city_name == '' ||
      this.city_name == undefined ||
      this.city_name == null
    ) {
      error_message += 'Please select city.<br>';
    }
    if (
      this.state_name == '' ||
      this.state_name == undefined ||
      this.state_name == null
    ) {
      error_message += 'Please select state.<br>';
    }
    if (error_message != '') {
      return false;
    } else {
      return true;
    }
  }
}
