import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css','../../assets/css/application.css','../header/header.component.css']
})
export class ForgetPasswordComponent {
  email:any;
  password:any;
  display_success_msg: any;
  display_failed_msg: any;
  // full_name: any;
  forget_email: any;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    window.scrollTo(0,0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
  }
  forgotPassword(){
    $('.alert-success').hide();
    $('.alert-danger').hide();
    $('#login_show').hide();
    $('#forgot_password_show').show();
  }

  onForgetClick(){
    if(this.validation()===true){
    this.http
    .post(CommonService.api_url + '/forget-password', {
      user_email: this.forget_email,
      oauth_key:CommonService.oauth_key
    })
    .subscribe((responseData: any) => {
      if (responseData['is_successful'] === '1') {
        $('.wrapper-loader').hide();
        this.display_success_msg = responseData['success_message'];
        $('.alert-success').html(responseData['success_message']);
        $('.alert-success').show();
        $('.alert-danger').hide();
        setTimeout(() => {
        this.router.navigateByUrl('/login');
        },2000);

        
      }
      if (responseData['is_successful'] === '0') {
        this.display_failed_msg = responseData['errors'];
        $('.alert-danger').html(responseData['errors']);
        $('.alert-success').hide();
        $('.alert-danger').show();
        window.scrollTo(0, 0);
      }
    });
  }
  else{
    return;
  }
  }
  validation(){
    let error='';

    if(this.forget_email===''|| this.forget_email===undefined|| this.forget_email===null){
      error+="Please enter your Email address.</br>"  ;
     }
   
     if(error !=''){
      $('.alert-danger').html(error);
      $('.alert-success').hide();
      $('.alert-danger').show();
      window.scrollTo(0,0);
      return false;
     }
     else{
      return true;
     }
    }

}
