<section class="bottom section-padding footer_style">
  <div class="container placeholder-container">
    <div class="row wpart">
      <div class="col-xl-4 col-lg-4 widget_edit_enabled">
        <div class="bottom-logo">
          <a (click)="onImageClick()">
            <img src="assets/img/logo-rental.png" class="pointer" />
          </a>
          <div class="content">
            <p
              class="description"
              style="text-align: justify; margin-left: 25px"
              [innerHTML]="cms_content"
            >
          </p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 widget_edit_enabled">
        <div class="widget-footer-contacts">
          <h3 class="pointer">Contact Us</h3>
          <!-- <div [innerHTML]="contact_content"></div> -->
           <ul class="footer-list" >
              <li>
                <i class="fa fa-map-marker"></i>
                <span class="value pointer"
                  >130 Technology Pkwy,
                  Norcross, GA 30092, USA</span
                >
              </li>
              <!-- <li>
                <i class="fa fa-phone"></i>
                <span class="value pointer"
                  ><a href="tel://38501123321"
                    >+1 937-343-5697</a
                  ></span
                >
              </li> -->
              <li>
                <i class="fa fa-envelope"></i>
                <span class="value pointer">
                  <a href="mailto:contact@rentalreviews365.com"
                    >contact@rentalreviews365.com</a
                  ></span
                >
              </li>
            </ul>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 widget_edit_enabled">
        <div
          class="bottom-list widget-follow-us"
          style="display: block; float: none; margin: 0 auto"
        >
          <h3
            style="margin-bottom: 0; padding-left: 0; font-weight: 600"
            class="pointer"
          >
            Follow Us
          </h3>
          <!-- <div [innerHTML]="list_content"> -->
            <div class="footer-social">
              <a (click)="onfacebook()"
                ><i class="fa fa-facebook pointer"></i
              ></a>

              <a (click)="ontwitter()" target="_blank"
                ><i class="fa fa-twitter pointer"></i
              ></a>

              <a (click)="onlinkedin()" target="_blank"
                ><i class="fa fa-linkedin pointer"></i
              ></a>

              <a (click)="oninstagram()" target="_blank"
                ><i class="fa fa-instagram pointer"></i
              ></a>
            </div>
            <div
              class="footer-social footer-top pointer"
              target="_blank"
              (click)="onFaq()"
            >
              FAQ
            </div>
            <div
              class="footer-social footer-top pointer"
              target="_blank"
              (click)="onPrivacyPolicy()"
            >
              Privacy policy
            </div>
            <div
              class="footer-social footer-top pointer"
              target="_blank"
              (click)="onTenantOrganizaion()"
            >
              Tenant organization
            </div>
            <div
              class="footer-social footer-top pointer"
              target="_blank"
              (click)="onCommunity()"
            >
              Community guideline
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer">
  <div class="container">
    <div class="row justify-content-between"></div>
  </div>
</footer>
