import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  // private googleMaps!: google.maps.Map;

  // loadAPI(): Observable<void> {
  //   return new Observable<void>((observer: Observer<void>) => {
  //     // Check if the Google Maps API is already loaded
  //     if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
  //       observer.next();
  //       observer.complete();
  //     } else {
  //       // Create a script element to load the Google Maps API
  //       const script = document.createElement('script');
  //       script.src = 'https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places';
  //       script.async = true;
  //       script.defer = true;

  //       script.onload = () => {
  //         observer.next();
  //         observer.complete();
  //       };

  //       script.onerror = () => {
  //         observer.error('Google Maps API failed to load');
  //       };

  //       document.body.appendChild(script);
  //     }
  //   });
  // }

  // initMapWithAddress(mapElement: HTMLElement, address: string): void {
  //   // Geocode the address to get coordinates
  //   const geocoder = new google.maps.Geocoder();

  //   // geocoder.geocode({ address }, (results: any[], status: string) => {
  //   //   if (status === 'OK') {
  //   //     const location = results[0].geometry.location;

  //   //     const mapOptions: google.maps.MapOptions = {
  //   //       center: location,
  //   //       zoom: 10,
  //   //     };

  //   //     this.googleMaps = new google.maps.Map(mapElement, mapOptions);
  //   //   } else {
  //   //     console.error('Geocode was not successful for the following reason: ' + status);
  //   //   }
  //   // });
  // }
}
