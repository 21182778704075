import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  // public static api_url = 'https://rental-review-dev.thcitsolutions.com/frontend';
  public static api_url = 'https://rentalreviews365.com/api/frontend';
  // public static api_url = 'https://rental-review.thcitsolutions.com/dev/frontend';
  public static oauth_key ='N7Yp3a0VpEFr3wgdagtwa4SpELy445Ys';
  public static  googleMapsApiKey= 'AIzaSyBDUA93FCLEcRP6hVodqic-LryBe6aTcPM';
  static file: any;
  static filename: any = '';
  static arrayColumns: any;
  static uploadData = new FormData();
  static notification_data: string;

  IsUserLogIn(){
    var agentDetails = localStorage.getItem("agent_login");
    if(agentDetails === undefined || agentDetails === "" || agentDetails === null){
      this.router.navigateByUrl('/login');
    }
  }
  public static ActiveClass = function (menu_name: string) {
    $('ul.sidebar-nav li').each(function () {
      $(this).removeClass('active');
    });

    $('li.' + menu_name).addClass('active');
  };

  formatAmount(number:string) {
    return parseFloat(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  constructor(private router : Router){
  }

}
