import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    
    // Split the text into words
    const words = value.split(' ');

    // If there are more than 200 words, truncate the array
    if (words.length > 50) {
      words.length = 50;
    }

    // Join the words back together to form the truncated text
    return words.join(' ');
  }

}
