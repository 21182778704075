import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css','../../assets/css/application.css']
})
export class HeaderComponent {
  facebook: any = 'https://www.facebook.com/profile.php?id=100095299902959&_rdr';
  instagram: any = 'https://www.instagram.com/social.rentalreviews/';
  twitter: any = 'https://twitter.com/rentals_reviews';
  linkedin: any = 'https://www.linkedin.com/in/rental-reviews-a51163286/';
  ngOnInit() {
    $('#already_login').hide();
    $('#not_login').hide();

    var agentDetails = localStorage.getItem("agent_login");
    if(agentDetails === undefined || agentDetails === "" || agentDetails === null){
      // this.router.navigateByUrl('/login');
      $('#not_login').show();

    }
    else{
      $('#already_login').show();

    }
  }
constructor(private router: Router) { 

}
  openSignin(){
    
    this.router.navigateByUrl('/login');
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openRegister(){
    this.router.navigate(['/register']);
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openSubmitReview(){
    this.router.navigate(['/submit-review']);
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openBlogPage(){
    this.router.navigate(['/blog']); 
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openContactPage(){
    this.router.navigate(['/contact']);
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openSearchPage(){
    this.router.navigate(['/search']);
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openHomePage(){
    this.router.navigate(['/home']);  
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  openAboutPage(){
    this.router.navigate(['/about']);
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  onLogoutClick(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
  onChangePasswordClick(){
    this.router.navigateByUrl('/change-password');
  }
  onfacebook() {
    window.open(this.facebook, '_blank');
  }
  ontwitter() {
    window.open(this.twitter, '_blank');
  }
  onlinkedin() {
    window.open(this.linkedin, '_blank');
  }
  oninstagram() {
    window.open(this.instagram, '_blank');
  }
  onmenuclick(){
    $('#navbarSupportedContent').show();
    $('#navbarSupportedContent').removeClass('collapse');
    $('#navbarSupportedContent').removeClass('navbar-collapse');
    $('#navbarSupportedContent').addClass('header_menu');
    
  }
  closeMenu(){
    $('#navbarSupportedContent').hide();
    $('#navbarSupportedContent').addClass('collapse');
    $('#navbarSupportedContent').addClass('navbar-collapse');
    $('#navbarSupportedContent').removeClass('header_menu');
  }
  onReviewClick(){
    this.router.navigateByUrl('/my-review');
  }
  onProfileClick(){
    this.router.navigateByUrl('/my-profile');
  }
}
