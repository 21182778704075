<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->
    <meta charset="UTF-8" />
    <title>Bjelovar</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta
      name="description"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vulputate nec neque gravida rhoncus. Donec sit amet blandit mauris, sed bibendum risus."
    />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Bjelovar estate 1" />
    <meta
      property="og:url"
      content="index.php/property/30/en/bjelovar-estate.html"
    />
    <meta
      property="og:description"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vulputate nec neque gravida rhoncus. Donec sit amet blandit mauris, sed bibendum risus."
    />
    <meta
      property="og:image"
      content="https://geniuscript.com/selio-script/files/431262903_9d77804a5b_o (1).jpg"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
    <style>
      .wrapper-loader {
        width: 100%;
        height: 100%;
        background-color: gainsboro;
        position: fixed;
        left: 0px;
        right: 0px;
        z-index: 99999;
        opacity: 0.8;
      }

      .loader-spinner-img {
        margin: 0 auto;
        display: block;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    </style>
  </head>

  <body>
    <div class="wrapper">
      <div class="wrapper-loader">
        <span class="loader-spinner-img"
          ><img src="assets/img/loader.gif"
        /></span>
      </div>
      <app-header></app-header>
      <section class="form_sec widget_edit_enabled">
        <h3 class="vis-hid">Invisible</h3>
        <div class="container conatiner_padding">
          <form
            action="#"
            class="row banner-search search-form top-search banner-search_init"
          >
            <div class="banner-search_box banner-search row">
              <div class="form_field">
                <div class="form-group field_search_-" style="width: 100%">
                  <input
                    id="search_option_smart"
                    name="search_option_smart"
                    value=""
                    type="text"
                    class="form-control"
                    placeholder="Address"
                    [(ngModel)]="search_text"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
              </div>

              <div class="form_field" style="width: 30%">
                <div class="form-group">
                  <select
                    class="drp_filter"
                    (change)="onChangeState($event)"
                    [(ngModel)]="state_id"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="0">Select State</option>
                    <option
                      *ngFor="let data of state_list"
                      [value]="data.state_id"
                    >
                      {{ data.state_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form_field" style="width: 30%">
                <div class="form-group">
                  <select
                    class="drp_filter"
                    [(ngModel)]="city_id"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeCity($event)"
                  >
                    <option value="0">Select City</option>
                    <option
                      *ngFor="let data of city_list"
                      [value]="data.city_id"
                    >
                      {{ data.city_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form_field form_field_alt" style="width: 30%">
                <div class="form-group form-group-tree-alt">
                  <select
                    class="drp_filter"
                    [(ngModel)]="city_area_id"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="0">Select Area</option>
                    <option
                      *ngFor="let data of city_area_list"
                      [value]="data.city_area_id"
                    >
                      {{ data.city_area }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form_field form_field_alt" style="width: 30%">
                <div class="form-group form-group-tree-alt">
                  <ngb-rating
                    [max]="5"
                    [(rate)]="currentRate"
                    [readonly]="false"
                    [(ngModel)]="rating"
                    [ngModelOptions]="{ standalone: true }"
                  ></ngb-rating>
                </div>
              </div>

              <div class="form_field srch-btn form_field_save">
                <a
                  (click)="searchProperty()"
                  class="btn btn-outline-primary sw-search-start slim pointer"
                >
                  <i class="fa fa-search"></i>
                  <i class="fa fa-spinner fa-spin fa-ajax-indicator hidden"></i>
                </a>
              </div>
            </div>
          </form>
        </div>
      </section>
      <div class="container contain-bottom">
        <div class="row">
          <!-- <div class="alert alert-success" role="alert"></div> -->
          <div
            class="alert alert-danger"
            role="alert"
            id="alert_danger_review"
          ></div>
        </div>
        <div class="row">
          <div class="col-md-6 property-column">
            <br />
            <br />
            <br />
            <div
              class="map-dv widget-listing-map property-map"
              style="border: 0"
            >
              <h3 style="margin-top: -23px">Property Location</h3>
              <!-- <google-map
               #map
                [center]="{ lat: property_latitude, lng: property_longitude }"
                [zoom]="zoom"
                style="height: 400px; width: 95% !important;"
                class="col-12"
              >
                <map-marker
                class="col-12"
                  [position]="{
                    lat: property_latitude,
                    lng: property_longitude
                  }"
                ></map-marker>
              </google-map> -->
              <div id="map"></div>
            </div>
          </div>
          <div class="col-md-6" id="sucess_review">
            <div
              class="alert alert-success"
              role="alert"
              id="alert-success"
            ></div>
          </div>
          <div class="col-md-6" id="review_main">
            <div
              class="map-dv widget-listing-map submit-review"
              style="border: 0"
            >
              <h3 class="submit_review_head">Submit Review</h3>
              <!-- <div class="alert alert-danger " role="alert" id="alert_danger_review"></div> -->
              <div class="row">
                <div
                  class="alert alert-success"
                  role="alert"
                  id="alert-success_review"
                ></div>
              </div>
              <div class="coloum">
                <div class="question">
                  <div
                    class="row question_row box"
                    *ngFor="let data of question_list; let i = index"
                  >
                    <div class="row question_type">
                      <label
                        ><b>{{ data.review_type_name }}</b></label
                      >
                    </div>
                    <div class="row">
                      <label class="question">{{ data.review_question }}</label>
                    </div>
                    <div class="row">
                      <ngb-rating
                        [max]="5"
                        [(ngModel)]="review_scores[i]"
                        [readonly]="false"
                        [disabled]="isdisabled"
                      ></ngb-rating>
                    </div>
                  </div>
                </div>
                <br />
                <form>
                  <div class="form-field">
                    <h4 class="font_text">Add Review</h4>
                    <textarea
                      id="message"
                      name="message"
                      rows="3"
                      class="resize-vertical"
                      [(ngModel)]="review_message"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Write Review"
                      [disabled]="isdisabled"
                    ></textarea>
                  </div>

                  <div class="form-field">
                    <h4 class="font_text">Add Media</h4>
                    <input
                      type="file"
                      (change)="onFileSelected($event)"
                      multiple
                      id="inputGroupFile04"
                      [disabled]="isdisabled"
                    />
                  </div>

                  <!-- <button
                    type="submit"
                    class="btn2"
                    (click)="submitReview()"
                    [disabled]="isdisabled"
                  > -->
                  <button type="submit" class="btn2" (click)="submitReview()">
                    Submit Review
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <div class="container col-md-12">
        <div
          class="alert alert-success"
          role="alert"
          id="review_report_success"
        ></div>
        <div
          class="alert alert-danger"
          role="alert"
          id="review_report_danger"
        ></div>
      </div>
      <div class="container col-md-12">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="row"> -->
            <!-- <div class="col-md-12"> -->
            <ul class="nav nav-tabs review-navbar">
              <li class="nav-item" id="landlord_tab">
                <a
                  (click)="landlordReview()"
                  class="nav-link"
                  data-toggle="tab"
                  id="nav-landlord"
                >
                  <span style="color: #13a89e">Landlord Review</span>
                </a>
              </li>
              <li class="nav-item" id="renter_tab">
                <a
                  (click)="rentersReview()"
                  class="nav-link"
                  data-toggle="tab"
                  id="nav-renter"
                >
                  <span style="color: #13a89e">Renters Review</span>
                </a>
              </li>
            </ul>
            <div class="write" style="border: 0">
              <div class="user">
                <div class="tab-content mt-6">
                  <div
                    #tabSet
                    class="tab-pane card col-md-12 land-review"
                    *ngFor="let data of property_review_landlord; let i = index"
                    [class.active]="i === activeTabIndexLandlord"
                  >
                    <div class="col-md-12 display_image">
                      <a>
                        <div class="img-block">
                          <!-- <div class="overlay"></div> -->
                          <div
                            id="listing_carousel_19"
                            class="carousel slide carousel-listing"
                            data-ride="carousel"
                            data-interval="false"
                          >
                            <div class="carousel-inner">
                              <div
                                class="carousel-item active col-md-6 col-sm-6"
                              >
                                <div class="review-name">
                                  <div class="image">
                                    <h4>{{ data.reviewer_name }}</h4>
                                    <div
                                      class="image-icon"
                                      style="color: #e6e6e6"
                                    ></div>
                                    <p class="p_class">
                                      {{ data.property_details }}
                                      <br />
                                      {{ data.created_datetime }}
                                    </p>
                                  </div>
                                  <div
                                    class="icon-text col-8 col-sm-8"
                                    style="color: #e6e6e6"
                                  >
                                    <div
                                      class="col-6 col-sm-6"
                                      *ngFor="
                                        let review_data of data.review_data;
                                        let i = index
                                      "
                                    >
                                      <h4>
                                        {{ review_data.review_type_name }}
                                      </h4>
                                      <ngb-rating
                                        class="rating_star"
                                        [max]="5"
                                        [(rate)]="review_data.review_score"
                                        [(ngModel)]="review_data.review_score"
                                        [readonly]="true"
                                        [ngModelOptions]="{ standalone: true }"
                                      ></ngb-rating>
                                    </div>
                                  </div>
                                </div>
                                <div class="written">
                                  <h4>Written Review</h4>
                                  <p>
                                    {{ data.review_message }}
                                  </p>
                                  <div class="col-12 col-sm-12">
                                    <div
                                      class="row report_reasons_review_landlord"
                                      id="report_reasons_review_landlord"
                                      *ngIf="hidelandlord"
                                    >
                                      <div
                                        class="col-4 col-sm-4 details-margin"
                                      >
                                        <h1>Report Reasons:</h1>
                                      </div>
                                      <div class="col-8 col-sm-8">
                                        <select
                                          class="form-select"
                                          aria-label="Default select example"
                                          (change)="
                                            onChangeReportLandLord(
                                              $event,
                                              data.review_id
                                            )
                                          "
                                          [(ngModel)]="report_value"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                        >
                                          <option
                                            *ngFor="let data of report_array"
                                            [value]="data.report_value"
                                          >
                                            {{ data.report_reason }}
                                          </option>
                                        </select>
                                      </div>

                                      <div
                                        class="col-4 col-sm-4 margin_report details-margin"
                                      >
                                        <h1>Report Description:</h1>
                                      </div>
                                      <div class="col-8 col-sm-8 margin_report">
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="report_description"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                        />
                                      </div>
                                      <button
                                        (click)="
                                          submitReportLandLord(data.review_id)
                                        "
                                        class="submitReport"
                                      >
                                        Submit Report
                                      </button>
                                    </div>
                                  </div>
                                  <button
                                    id="landlord_review_button"
                                    (click)="reportClickLandord()"
                                    class="submitReport report_landlord"
                                  >
                                    Report
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <div class="sideshow">
                                  <div
                                    class="property-imgs"
                                    style="margin-top: 21px"
                                  >
                                    <div class="property-main-img">
                                      <div
                                        class="property-img"
                                        *ngIf="
                                          data.media_urls[0].media_type ==
                                          'image'
                                        "
                                      >
                                        <img
                                          (click)="
                                            openImage(
                                              data.media_urls[0].media_url,
                                              data.media_urls[0].media_type,
                                              data.media_urls,
                                              0
                                            )
                                          "
                                          [src]="data.media_urls[0].media_url"
                                        />
                                      </div>
                                      <div
                                        class="property-img"
                                        *ngIf="
                                          data.media_urls[0].media_type ==
                                          'video'
                                        "
                                      >
                                        <video
                                          controls
                                          class="display_image"
                                          (click)="
                                            openImage(
                                              data.media_urls[0].media_url,
                                              data.media_urls[0].media_type,
                                              data.media_urls,
                                              0
                                            )
                                          "
                                        >
                                          <source
                                            [src]="data.media_urls[0].media_url"
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                    <div class="property-thumb-imgs">
                                      <div class="col-md-12 image_Scroll">
                                        <div
                                          class="col-lg-4 col-md-4 col-sm-4 col-4 thumb-img"
                                          *ngFor="
                                            let image_datas of data.media_urls;
                                            let j = index
                                          "
                                        >
                                          <div
                                            class="property-img image_height"
                                            *ngIf="
                                              image_datas.media_type == 'image'
                                            "
                                          >
                                            <img
                                              (click)="
                                                openImage(
                                                  image_datas.media_url,
                                                  image_datas.media_type,
                                                  data.media_urls,
                                                  j
                                                )
                                              "
                                              [src]="image_datas.media_url"
                                            />
                                          </div>
                                          <div
                                            class="property-img image_height"
                                            *ngIf="
                                              image_datas.media_type == 'video'
                                            "
                                          >
                                            <video
                                              controls
                                              class="display_image"
                                              (click)="
                                                openImage(
                                                  image_datas.media_url,
                                                  image_datas.media_type,
                                                  data.media_urls,
                                                  j
                                                )
                                              "
                                            >
                                              <source
                                                [src]="image_datas.media_url"
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- <span
                              class="carousel-control-prev disable_scroll button-color"
                              role="button"
                              data-slide="prev"
                              (click)="activatePreviousTabLandloard()"
                            >
                              <i class="fa fa-angle-left"></i>
                            </span>
                            <span
                              class="carousel-control-next disable_scroll button-color"
                              role="button"
                              data-slide="next"
                              (click)="activateNextTabLandloard()"
                            >
                              <i class="fa fa-angle-right"></i>
                            </span> -->
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    #tabSet
                    class="rental-review tab-pane card col-md-12"
                    *ngFor="let data of property_review_renter; let i = index"
                    [class.active]="i === activeTabIndex"
                  >
                    <div class="col-md-12 col-sm-12 display_image">
                      <a>
                        <div class="img-block">
                          <!-- <div class="overlay"></div> -->
                          <div
                            id="listing_carousel_17"
                            class="carousel slide carousel-listing"
                            data-ride="carousel"
                            data-interval="false"
                          >
                            <div class="carousel-inner">
                              <div
                                class="carousel-item active col-md-6 col-sm-6"
                              >
                                <div class="review-name">
                                  <div class="image col-4 col-sm-4">
                                    <h4>{{ data.reviewer_name }}</h4>
                                    <div
                                      class="image-icon"
                                      style="color: #e6e6e6"
                                    ></div>
                                    <p class="p_class">
                                      {{ data.property_details }}
                                      <br />
                                      {{ data.created_datetime }}
                                    </p>
                                  </div>
                                  <div
                                    class="icon-text col-8 col-sm-8"
                                    style="color: #e6e6e6"
                                  >
                                    <div
                                      class="col-6 col-sm-6"
                                      *ngFor="
                                        let review_data of data.review_data;
                                        let i = index
                                      "
                                    >
                                      <h4>
                                        {{ review_data.review_type_name }}
                                      </h4>
                                      <ngb-rating
                                        class="rating_star"
                                        [max]="5"
                                        [(rate)]="review_data.review_score"
                                        [(ngModel)]="review_data.review_score"
                                        [readonly]="true"
                                        [ngModelOptions]="{ standalone: true }"
                                      ></ngb-rating>
                                    </div>
                                  </div>
                                </div>
                                <div class="written">
                                  <h4>Written Review</h4>
                                  <p>
                                    {{ data.review_message }}
                                  </p>
                                  <div
                                    class="col-12 col-sm-12 report_css"
                                    id="renter_report_div"
                                  >
                                    <div
                                      class="row report_reasons_review"
                                      id="report_reasons_review"
                                    >
                                      <div
                                        class="col-4 col-sm-4 details-margin"
                                      >
                                        <h1>Report Reasons:</h1>
                                      </div>
                                      <div class="col-8 col-sm-8">
                                        <select
                                          class="form-select"
                                          aria-label="Default select example"
                                          (change)="
                                            onChangeReport(
                                              $event,
                                              data.review_id
                                            )
                                          "
                                          [(ngModel)]="report_value"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                        >
                                          <option
                                            *ngFor="let data of report_array"
                                            [value]="data.report_value"
                                          >
                                            {{ data.report_reason }}
                                          </option>
                                        </select>
                                      </div>
                                      <div
                                        class="col-4 col-sm-4 margin_report details-margin"
                                      >
                                        <h1>Report Description:</h1>
                                      </div>
                                      <div class="col-8 col-sm-8 margin_report">
                                        <input
                                          type="text"
                                          class="form-control"
                                          [(ngModel)]="report_description"
                                          [ngModelOptions]="{
                                            standalone: true
                                          }"
                                        />
                                      </div>
                                      <div>
                                        <button
                                          (click)="submitReport(data.review_id)"
                                          class="submitReport"
                                        >
                                          Submit Report
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    id="renter_report_button"
                                    (click)="reportClick()"
                                    class="submitReport review_report"
                                  >
                                    Report
                                  </button>
                                </div>
                              </div>
                              <div class="col-md-6 col-sm-6">
                                <div class="sideshow">
                                  <div
                                    class="property-imgs"
                                    style="margin-top: 21px"
                                  >
                                    <div class="property-main-img">
                                      <div
                                        class="property-img"
                                        *ngIf="
                                          data.media_urls[0].media_type ==
                                          'image'
                                        "
                                      >
                                        <img
                                          (click)="
                                            openImage(
                                              data.media_urls[0].media_url,
                                              data.media_urls[0].media_type,
                                              data.media_urls,
                                              0
                                            )
                                          "
                                          [src]="data.media_urls[0].media_url"
                                        />
                                      </div>
                                      <div
                                        class="property-img"
                                        *ngIf="
                                          data.media_urls[0].media_type ==
                                          'video'
                                        "
                                      >
                                        <video
                                          controls
                                          class="display_image"
                                          (click)="
                                            openImage(
                                              data.media_urls[0].media_url,
                                              data.media_urls[0].media_type,
                                              data.media_urls,
                                              0
                                            )
                                          "
                                        >
                                          <source
                                            [src]="data.media_urls[0].media_url"
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                    <div class="property-thumb-imgs">
                                      <div
                                        class="col-md-12 col-sm-12 image_Scroll"
                                      >
                                        <div
                                          class="col-lg-4 col-md-4 col-sm-4 col-4 thumb-img"
                                          *ngFor="
                                            let image_datas of data.media_urls;
                                            let j = index
                                          "
                                        >
                                          <div
                                            class="property-img image_height"
                                            *ngIf="
                                              image_datas.media_type == 'image'
                                            "
                                          >
                                            <img
                                              (click)="
                                                openImage(
                                                  image_datas.media_url,
                                                  image_datas.media_type,
                                                  data.media_urls,
                                                  j
                                                )
                                              "
                                              [src]="image_datas.media_url"
                                            />
                                          </div>
                                          <div
                                            class="property-img image_height"
                                            *ngIf="
                                              image_datas.media_type == 'video'
                                            "
                                          >
                                            <video
                                              controls
                                              class="display_image"
                                              (click)="
                                                openImage(
                                                  image_datas.media_url,
                                                  image_datas.media_type,
                                                  data.media_urls,
                                                  j
                                                )
                                              "
                                            >
                                              <source
                                                [src]="image_datas.media_url"
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span
                              class="carousel-control-prev disable_scroll button-color"
                              role="button"
                              data-slide="prev"
                              (click)="activatePreviousTab()"
                            >
                              <i class="fa fa-angle-left"></i>
                            </span>
                            <span
                              class="carousel-control-next disable_scroll button-color"
                              role="button"
                              data-slide="next"
                              (click)="activateNextTab()"
                            >
                              <i class="fa fa-angle-right"></i>
                            </span>
                            <!-- Carousel nav -->
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
          </div>

          <br />
          <br />
        </div>
      </div>
      <!-- your-component.component.html -->
      <div *ngIf="isPopupOpen" class="popup-background">
        <!-- Popup content goes here -->

        <div class="popup-content col-md-12">
          <!-- Add your media here (image or video) using data.mediaUrl -->
          <div (click)="closePopup()" class="close_button">&times;</div>
          <div class="row">
            <div class="col-md-12  popup-display">
              <div class="col-md-2 arrow">
                <span
                  class="carousel-control-prev disable_scroll new_button_scroll"
                  (click)="prevSlide()"
                  role="button"
                  data-slide="prev"
                >
                  <i class="fa fa-angle-left left_button_scroll"></i>
                </span>
              </div>
              <div class="col-md-8 centered">
                <div
                  *ngIf="image_list[currentIndex]?.media_type === 'image'"
                  class="centered"
                >
                  <img
                    [src]="image_list[currentIndex]?.media_url"
                    class="image_css"
                  />
                </div>
                <div
                  *ngIf="image_list[currentIndex]?.media_type === 'video'"
                  class="centered"
                >
                  <video controls class="image_css">
                    <source
                      [src]="image_list[currentIndex]?.media_url"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div class="col-md-2 arrow">
                <span
                  class="carousel-control-next new_button_scroll disable_scroll"
                  (click)="nextSlide()"
                  role="button"
                  data-slide="next"
                >
                  <i class="fa fa-angle-right left_button_scroll"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal" *ngIf="showModal">
        <img [src]="image" alt="Image">
        <button (click)="closeModal()">Close</button>
      </div> -->
      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
    <!--wrapper end-->

    <div class="se-pre-con"></div>
  </body>
</html>
