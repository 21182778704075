<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" />
    <!-- /Added by HTTrack -->
    <meta charset="UTF-8" />
    <title>Bjelovar</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta
      name="description"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vulputate nec neque gravida rhoncus. Donec sit amet blandit mauris, sed bibendum risus."
    />
    <meta name="keywords" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />

    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Bjelovar estate 1" />
    <meta
      property="og:url"
      content="index.php/property/30/en/bjelovar-estate.html"
    />
    <meta
      property="og:description"
      content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vulputate nec neque gravida rhoncus. Donec sit amet blandit mauris, sed bibendum risus."
    />
    <meta
      property="og:image"
      content="https://geniuscript.com/selio-script/files/431262903_9d77804a5b_o (1).jpg"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
    <style>
      .wrapper-loader {
        width: 100%;
        height: 100%;
        background-color: gainsboro;
        position: fixed;
        left: 0px;
        right: 0px;
        z-index: 99999;
        opacity: 0.8;
      }

      .loader-spinner-img {
        margin: 0 auto;
        display: block;
        text-align: center;
        position: fixed;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    </style>
  </head>

  <body>
    <div class="wrapper">
      <div class="wrapper-loader">
        <span class="loader-spinner-img"
          ><img src="assets/img/loader.gif"
        /></span>
      </div>
      <app-header></app-header>

      <div class="container contain-bottom">
        <div class="row">
          <div
            class="alert alert-success"
            role="alert"
            id="alert-success"
          ></div>
          <div
            class="alert alert-danger"
            role="alert"
            id="alert_danger_review"
          ></div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <br />
            <br />
            <br />
            <div class="map-dv widget-listing-map" style="border: 0">
              <h3 style="margin-top: -23px">Property Location</h3>
              <!-- <google-map
               #map
                [center]="{ lat: property_latitude, lng: property_longitude }"
                [zoom]="zoom"
                style="height: 400px; width: 95% !important;"
                class="col-12"
              >
                <map-marker
                class="col-12"
                  [position]="{
                    lat: property_latitude,
                    lng: property_longitude
                  }"
                ></map-marker>
              </google-map> -->
              <div id="map"></div>
            </div>
          </div>
          <div class="col-md-6" id="sucess_review">
            <div
              class="alert alert-success"
              role="alert"
              id="alert-success_review"
            ></div>
          </div>
          <div class="col-md-6" id="review_main">
            <div class="map-dv widget-listing-map" style="border: 0">
              <h3 class="submit_review_head">Update Review</h3>
              <!-- <div class="alert alert-danger " role="alert" id="alert_danger_review"></div> -->
              <!-- <div class="row">
              <div class="alert alert-success" role="alert" id="alert-success_review"></div>
            </div> -->
              <div class="coloum">
                <div class="question">
                  <div
                    class="row question_row box"
                    *ngFor="let data of question_list; let i = index"
                  >
                    <div class="row question_type">
                      <label
                        ><b>{{ data.review_type_name }}</b></label
                      >
                    </div>
                    <div class="row">
                      <label class="question">{{ data.review_question }}</label>
                    </div>
                    <div class="row">
                      <ngb-rating
                        [max]="5"
                        [(rate)]="data.review_score"
                        [(ngModel)]="data.review_score"
                        [readonly]="false"
                        [disabled]="isdisabled"
                      ></ngb-rating>
                    </div>
                  </div>
                </div>
                <br />
                <form>
                  <div class="form-field">
                    <textarea
                      id="message"
                      name="message"
                      rows="3"
                      class="resize-vertical"
                      [(ngModel)]="review_message"
                      [ngModelOptions]="{ standalone: true }"
                      placeholder="Write Review"
                      [disabled]="isdisabled"
                    ></textarea>
                  </div>

                  <div class="form-field">
                    <h4>Add Media</h4>
                    <input
                      type="file"
                      (change)="onFileSelected($event)"
                      multiple
                      id="inputGroupFile04"
                      [disabled]="isdisabled"
                    />
                  </div>

                  <button
                    type="submit"
                    class="btn2"
                    (click)="submitReview()"
                    [disabled]="isdisabled"
                  >
                    Update Review
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container contain-bottom">
        <table id="user_douments_table" class="table table-striped table-hover">
          <thead>
            <tr>
              <!-- <th>Id</th> -->
              <!-- <th>Media Type</th> -->

              <th class="th_class">View Image</th>
              <th class="th_class">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doctDetails of review_media">
              <!-- <td>{{doctDetails.user_document_id}}</td> -->
              <!-- <td>{{ doctDetails.media_type }}</td> -->
              <!-- <td>{{ doctDetails.document_number }}</td>
      <td>{{ doctDetails.comments }}</td> -->
              <td data-toggle="tooltip" title="View Image">
                <div
                  *ngIf="doctDetails.media_type === 'image'"
                  (click)="viewDocumnet(doctDetails.media_url)"
                >
                  <img
                    [src]="doctDetails.media_url"
                    style="width: 86px"
                    alt="Image 1"
                  />
                </div>
                <div
                  *ngIf="doctDetails.media_type === 'video'"
                  (click)="viewDocumnet(doctDetails.media_url)"
                >
                  <video controls style="width: 86px">
                    <source [src]="doctDetails.media_url" type="video/mp4" />
                  </video>
                </div>
              </td>

              <td data-toggle="tooltip" title="Delete">
                <i
                  (click)="deleteDocument(doctDetails.review_media_id)"
                  class="fa fa-trash"
                  aria-hidden="true"
                  style="
                    display: inline;
                    font-size: 26px;
                    color: #ffffff;
                    background-color: #13a89e;
                    border: 1px solid #13a89e;
                    border-radius: 10px;
                    padding-left: 11px;
                    padding-right: 11px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                  "
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <br />

      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
      <app-footer></app-footer>
    </div>
    <!--wrapper end-->

    <div class="se-pre-con"></div>
  </body>
</html>
