import { Component, ElementRef, ViewChild } from '@angular/core';
import * as $ from 'jquery';

declare var google: any;
@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css'],
})
export class GoogleMapComponent {
  centerLat: any;
  centerLng: any;
  map: any; // Google Maps object
  markers: google.maps.Marker[] = []; // Array to store markers
  selectedMarker: google.maps.Marker | null = null; // Store the selected marker

  constructor() {}

  ngOnInit() {
    this.initMap('VedikIn Solutions, PRAMUKH PARAMOUNT, 10, Kameshwar road, Sargasan, Gandhinagar, Gujarat 382421');
  }

  // ...

  initMap(address: string): void {
    const mapOptions: google.maps.MapOptions = {
      center: { lat: 0, lng: 0 }, // Set the initial map center
      zoom: 15, // Set the initial zoom level
    };

    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

    // Use the Geocoder to convert the address into coordinates and set the marker
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results: any, status: any) => {
      if (status === 'OK' && results[0]) {
        const location = results[0].geometry.location;
        this.map.setCenter(location);

        const marker = new google.maps.Marker({
          position: location,
          map: this.map,
          title: address,
          draggable: true, // Allow the marker to be dragged
        });

        // Store the marker in the markers array
        this.markers.push(marker);

        // Get the coordinates (latitude and longitude) of the map's center
        this.centerLat = this.map.getCenter().lat();
        this.centerLng = this.map.getCenter().lng();

        // Add an event listener to update the marker's position when dragged
        google.maps.event.addListener(marker, 'drag', (event: any) => {
          // Update the marker's position in real-time
          this.centerLat = event.latLng.lat();
          this.centerLng = event.latLng.lng();
          console.log( 'lat',this.centerLat);
          console.log( 'lag',this.centerLng);

        });

        // Add a click event listener to select the marker
    
        google.maps.event.addListener(marker, 'click', () => {
          this.selectedMarker = marker;
          marker.setDraggable(true); // Make the marker draggable on click
        });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  }


 
}