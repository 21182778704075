import { Component, ElementRef } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: [
    './contact.component.css',
    '../../assets/css/application.css'
    
  ],
})
export class ContactComponent {
  name: any;
  email: any;
  phone: any;
  message: any;
  display_success_msg: any;
  display_failed_msg: any;
  ngOnInit() {
    window.scrollTo(0,0);
    CommonService.ActiveClass('nav-contact');
    $('.alert-success').hide();
    $('.alert-danger').hide();
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onSendMessage() {
    if(this.validation()===true){
    this.http
      .post(CommonService.api_url + '/contact', {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        if (responseData['is_successful'] === '1') {
          $('.alert-success').html(responseData['success_message']);
          $('.alert-success').show();
          $('.alert-danger').hide();
           setTimeout(() => {
          window.location.reload();
        }, 3000);
    
          
        }
        if (responseData['is_successful'] === '0') {
          $('.alert-danger').html(responseData['errors']);
          $('.alert-success').hide();
          $('.alert-danger').show();
          window.scrollTo(0, 0);
        }
        // this.display_success_msg = responseData['success_message'];
        // $('.alert-success').show();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      });
    }
    else{
      return;
    }
  }
  validation(){
    $('.alert-success').hide();
    $('.alert-danger').hide();
    let error_message='';
    if(this.name===null|| this.name=== undefined|| this.name===''){
      error_message+='Please enter Name.</br>'; 
    }
    if(this.email===null|| this.email=== undefined|| this.email===''){
      error_message+='Please enter Email.</br>'; 
    }
    if(this.phone===null|| this.phone=== undefined|| this.phone===''){
      error_message+='Please enter phone number.</br>'; 
    }
    if(this.message===null|| this.message=== undefined|| this.message===''){
      error_message+='Please enter Message.</br>'; 
      
    }
    if(error_message!=''){
      $('.alert-danger').html(error_message);
      $('.alert-danger').show();
      return false;
    }else{ 
      return true;
    }
  }
}
