import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: [
    './reset-password.component.css',
    '../../assets/css/application.css',
  ],
})
export class ResetPasswordComponent {
  decodedParam: any;
  user_id: any;
  user_otp: any;
  password: any;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService
  ) {}
  ngOnInit(): void {
    // Extract the base64 token from the URL
    window.scrollTo(0, 0);
    $('.alert-danger').hide();
    $('.alert-success').hide();
    this.route.params.subscribe((params) => {
      const encodedParam = params['param']; // Replace 'param' with your actual parameter name

      // Decode the parameter
      let decodedParam = atob(encodedParam);
      this.decodedParam = JSON.parse(decodedParam);
      this.user_otp = this.decodedParam.otp; // Assuming it's base64 encoded, you can use 'atob' to decode
      this.user_id = this.decodedParam.user_id; // Assuming it's base64 encoded, you can use 'atob' to decode
    });
  }
  onResetClick() {
    if (this.validation() === true) {
      this.http
        .post(CommonService.api_url + '/reset-password', {
          user_otp: this.user_otp,
          new_password: this.password,
          user_id: this.user_id,
          oauth_key: CommonService.oauth_key,
        })
        .subscribe((responseData: any) => {
          if (responseData['is_successful'] === '1') {
            $('.wrapper-loader').hide();
            $('.alert-success').html(responseData['success_message']);
            $('.alert-success').show();
            $('.alert-danger').hide();
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            },3000);
          }
          if (responseData['is_successful'] === '0') {
            $('.alert-danger').html(responseData['errors']);
            $('.alert-success').hide();
            $('.alert-danger').show();
            window.scrollTo(0, 0);
          }
        });
    } else {
      return;
    }
  }
  validation() {
    let error = '';

    if (
      this.password === '' ||
      this.password === undefined ||
      this.password === null
    ) {
      error += 'Please enter your password.</br>';
    }

    if (error != '') {
      $('.alert-danger').html(error);
      $('.alert-success').hide();
      $('.alert-danger').show();
      window.scrollTo(0, 0);
      return false;
    } else {
      return true;
    }
  }
}
