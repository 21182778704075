<!DOCTYPE html>
<html class="no-js" lang="en">
  <head>
    <meta charset="UTF-8" />
    <title>Search</title>
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="Fullscreen" />
    <meta name="keywords" content="Fullscreen" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="author" content="" />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
    <meta property="og:site_name" content="Real estate point" />
    <meta property="og:title" content="Real estate point - Fullscreen" />
    <meta property="og:url" content="#" />
    <meta property="og:description" content="Fullscreen" />
    <meta
      property="og:image"
      content="templates/selio/assets/img/default-image-og.webp"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
      rel="stylesheet"
    />
  </head>

  <body>
    <div class="container_main">
      <div class="wrapper">
        <app-header></app-header>

        <section class="half-map-sec">
          <div class="container_map">
            <div class="col-xl-12 col-lg-12 display_contain">
              <div class="col-xl-6 col-lg-6 map-width">
                <div id="map"></div>
              </div>

              <div class="col-xl-6 col-lg-6 search-width margin-search">
                <div class="row padding_validation">
                  <div class="alert alert-success" role="alert"></div>
                  <div class="alert alert-danger" role="alert"></div>
                </div>

                <div class="widget-property-search widget_edit_enabled">
                  <form
                    action="#"
                    class="row banner-search search-form"
                    id="search-banner"
                    #searchForm="ngForm"
                    (ngSubmit)="searchProperty()"
                  >
                    <div class="col-xl-12 col-lg-12 col-md-12">
                      <div class="form_field address-width">
                        <div class="form-group field_search_-">
                          <input
                            id="search_option_smart"
                            name="search_option_smart"
                            value=""
                            type="text"
                            class="form-control"
                            placeholder="Address"
                            [(ngModel)]="search_text"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </div>
                      </div>

                      <div class="form_field sf_input">
                        <div class="form-group field_search_20">
                          <div class="form-group field_search_-">
                            <select
                              class="form-select"
                              [(ngModel)]="state_id"
                              [ngModelOptions]="{ standalone: true }"
                              (change)="onChangeState($event)"
                            >
                              <option value="0">Select State</option>
                              <option
                                *ngFor="let data of state_list"
                                [value]="data.state_id"
                              >
                                {{ data.state_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form_field sf_input">
                        <div class="form-group field_search_20">
                          <div class="form-group field_search_-">
                            <select
                              class="form-select"
                              [(ngModel)]="city_id"
                              [ngModelOptions]="{ standalone: true }"
                              (change)="onChangeCity($event)"
                            >
                              <option value="0">Select City</option>
                              <option
                                *ngFor="let data of city_list"
                                [value]="data.city_id"
                              >
                                {{ data.city_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-12 col-lg-12">
                      <div class="form_field address-width">
                        <div class="form-group field_search_20">
                          <div class="form-group field_search_-">
                            <select
                              class="form-select"
                              [(ngModel)]="city_area_id"
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option value="0">Select Area</option>
                              <option
                                *ngFor="let data of city_area_list"
                                [value]="data.city_area_id"
                              >
                                {{ data.city_area }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form_field sf_input">
                        <div class="form-group field_search_20">
                          <div class="form-group field_search_-">
                            <select
                              class="form-select"
                              [(ngModel)]="property_type_id"
                              [ngModelOptions]="{ standalone: true }"
                            >
                              <option value="0">Property Type</option>
                              <option
                                *ngFor="let data of property_type"
                                [value]="data.property_type_id"
                              >
                                {{ data.property_type_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form_field sf_input">
                        <div class="form-group field_search_20">
                          <div class="form-group field_search_-">
                            <!-- <select
                                class="form-select"
                                [(ngModel)]="rating"
                                [ngModelOptions]="{ standalone: true }"
                              >
                                <option value="1">
                                  <img
                                    src="assets/img/1star.png"
                                    class="item-image"
                                  />
                                </option>
                                <option value="2">
                                  <img
                                    src="assets/img/2star.png"
                                    class="item-image"
                                  />
                                </option>
                                <option value="3">
                                  <img
                                    src="assets/img/3star.png"
                                    class="item-image"
                                  />
                                </option>
                                <option value="4">
                                  <img
                                    src="assets/img/4star.png"
                                    class="item-image"
                                  />
                                </option>
                                <option [value]="5">
                                  <img
                                    src="assets/img/5star.png"
                                    class="item-image"
                                  />
                                </option>
                              </select> -->
                            <small>Avg. Rating</small>
                            <ngb-rating
                              [max]="5"
                              [(rate)]="rating"
                              [readonly]="false"
                              [(ngModel)]="rating"
                              [ngModelOptions]="{ standalone: true }"
                              style="font-size: 24px"
                            ></ngb-rating>
                          </div>
                        </div>
                      </div>
                      <!-- <div id="myDropdown"></div> -->
                    </div>
                    <div class="col-xl-12 col-lg-12 margin_button">
                      <!-- <div class="feat-srch-btn col-lg-12 col-xl-12">
                          <div class="form_field form_field_save">
                            <div class="form_field_row"> -->
                      <!-- <button
                                class="search_button"
                                type="submit"
                                (click)="searchProperty()"
                              
                              >
                              
                                  Submit
                                  
                              </button> -->
                      <button
                        type="submit"
                        (click)="searchProperty()"
                        class="submit_review col-md-12"
                      >
                        Submit
                      </button>
                      <!-- </div>
                          </div>
                        </div> -->
                      <!--more-feat end-->
                    </div>
                    <div class="features_list">
                      <div class="group">
                        <div class="input-field checkbox-field field_search_22">
                          <input
                            type="checkbox"
                            name="search_option_22"
                            id="search_option_22"
                            value="trueAir conditioning"
                          />
                          <label for="search_option_22">
                            <span></span>
                            <small></small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_24">
                          <input
                            type="checkbox"
                            name="search_option_24"
                            id="search_option_24"
                            value="trueComputer"
                          />
                          <label for="search_option_24">
                            <span></span>
                            <small>Computer</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_28">
                          <input
                            type="checkbox"
                            name="search_option_28"
                            id="search_option_28"
                            value="trueHeating"
                          />
                          <label for="search_option_28">
                            <span></span>
                            <small>Heating</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_32">
                          <input
                            type="checkbox"
                            name="search_option_32"
                            id="search_option_32"
                            value="trueParking"
                          />
                          <label for="search_option_32">
                            <span></span>
                            <small>Parking</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_23">
                          <input
                            type="checkbox"
                            name="search_option_23"
                            id="search_option_23"
                            value="trueCable TV"
                          />
                          <label for="search_option_23">
                            <span></span>
                            <small>Cable TV</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_31">
                          <input
                            type="checkbox"
                            name="search_option_31"
                            id="search_option_31"
                            value="trueMicrowave"
                          />
                          <label for="search_option_31">
                            <span></span>
                            <small>Microwave</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_30">
                          <input
                            type="checkbox"
                            name="search_option_30"
                            id="search_option_30"
                            value="trueLift"
                          />
                          <label for="search_option_30">
                            <span></span>
                            <small>Lift</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_33">
                          <input
                            type="checkbox"
                            name="search_option_33"
                            id="search_option_33"
                            value="truePool"
                          />
                          <label for="search_option_33">
                            <span></span>
                            <small>Pool</small>
                            <b class="count"></b>
                          </label>
                        </div>
                        <div class="input-field checkbox-field field_search_25">
                          <input
                            type="checkbox"
                            name="search_option_25"
                            id="search_option_25"
                            value="trueDishwasher"
                          />
                          <label for="search_option_25">
                            <span></span>
                            <small>Dishwasher</small>
                            <b class="count"></b>
                          </label>
                        </div>
                      </div>
                    </div>
                    <!--features end-->
                  </form>
                </div>
                <!--widget widget-property-searche end-->
                <div class="listing-directs">
                  <div
                    class="results-container result_preload_box"
                    id="results_conteiner"
                  >
                    <div class="list_products">
                      <h1 id="show_no_result"></h1>
                      <div class="row" id="search-container-property">
                        <div
                          class="col-md-6"
                          *ngFor="let data of search_property"
                        >
                          <div class="card" [id]="data.property_id">
                            <a
                              (click)="openModal(data.property_id)"
                              class="pointer"
                            >
                              <div class="img-block">
                                <div class="overlay"></div>

                                <div
                                  id="listing_carousel_30"
                                  class="carousel slide carousel-listing"
                                  data-ride="carousel"
                                  data-interval="false"
                                >
                                  <div class="carousel-inner">
                                    <div class="carousel-item active">
                                      <img
                                        [src]="data.media_url"
                                        alt="Bjelovar estate 1"
                                        style="height: 229px"
                                        class="d-block w-100 img-fluid"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="rate-info">
                                  <span
                                    class="purpose-sale"
                                    (click)="openModal(data.property_id)"
                                    style="background-color: #12a89d"
                                    >Review</span
                                  >
                                </div>
                              </div>
                            </a>
                            <div class="card-body">
                              <a
                                (click)="openModal(data.property_id)"
                                class="pointer"
                              >
                                <!-- <h3>{{ data.property_name }}</h3> -->
                                <p>
                                  <i class="fa fa-map-marker"></i
                                  ><span class="property-details">{{ data.property_details }}</span>
                                </p>
                              </a>
                            </div>
                            <div class="card-footer">
                              <span class="favorites-actions pull-left">
                                <i
                                  class="fa fa-spinner fa-spin fa-custom-ajax-indicator"
                                ></i>
                              </span>
                              <a class="pointer" class="pull-right">
                                <ngb-rating
                                  [max]="5"
                                  [(rate)]="data.review_score"
                                  [(ngModel)]="data.review_score"
                                  [readonly]="true"
                                  [ngModelOptions]="{ standalone: true }"
                                ></ngb-rating>
                              </a>
                            </div>
                            <a
                              (click)="openModal(data.property_id)"
                              class="pointer"
                              class="ext-link"
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <nav
                      aria-label="Page navigation example"
                      class="pagination properties"
                    ></nav>
                    <!--pagination end-->

                    <div class="result_preload_indic"></div>
                    
                  </div>
                  <div class="col-xl-12 col-lg-12 margin_button" style="padding: 0px;">
                    <button
                    id="onSubmitReview"
                      type="submit"
                      (click)="onSubmitReview()"
                      class="submit_review col-md-12"
                    >
                   Submit Review
                    </button>
                    <button
                    id="loadMore"
                      type="submit"
                      (click)="onLoadMoreClick()"
                      class="submit_review col-md-12"
                    >
                    Load More
                    </button>
                    <button
                    id="loadMoreSearch"
                      type="submit"
                      (click)="onLoadMoreClickSearch()"
                      class="submit_review col-md-12"
                    >
                    Load More
                    </button>
                  </div>
                  <!--tab-content end-->
                </div>
                <!---directs end-->
                <!-- <div class="widget-property-search widget_edit_enabled">
                  <form
                    action="#"
                    class="row banner-search search-form"
                    id="search-banner"
                  > -->
                    <!-- <button
                      class="btn btn-outline-primary sw-search-start"
                      type="submit"
                      (click)="onLoadMoreClick()"
                      style="
                        color: #fcfcfc;
                        background: #12a89d;
                        border-radius: 0px;
                        margin-left: 10px;
                      "
                      id="loadMore"
                    >
                      <span class="submit-full"
                        >Load More
                        <i
                          class="fa fa-spinner fa-spin fa-ajax-indicator hidden"
                        ></i
                      ></span>
                    </button> -->
                 
                    <!-- <button
                      class="btn btn-outline-primary sw-search-start"
                      type="submit"
                      (click)="onLoadMoreClickSearch()"
                      style="
                        color: #fcfcfc;
                        background: #12a89d;
                        border-radius: 0px;
                        margin-left: 10px;
                      "
                      id="loadMoreSearch"
                    >
                      <span class="submit-full"
                        >Load More
                        <i
                          class="fa fa-spinner fa-spin fa-ajax-indicator hidden"
                        ></i
                      ></span>
                    </button> -->
                    <!--more-feat end-->

                    <!--features end-->
                  <!-- </form>
                </div> -->
              </div>
            </div>
          </div>
        </section>

        <!--half-map-sec end-->
      </div>
      <!--wrapper end-->

      <div class="se-pre-con"></div>

      <a title="" class="widget_edit_enabled">
        <section class="cta section-padding" style="z-index: 999">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="cta-text">
                  <h2>Discover a home you'll love to stay</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </a>
    </div>

    <app-footer></app-footer>
  </body>
</html>
