<!doctype html>
<html class="no-js" lang="en">


<!-- Added by HTTrack -->

<head>
    <meta http-equiv="content-type" content="text/html;charset=UTF-8" ><!-- /Added by HTTrack -->
    <meta charset="UTF-8">
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="description"
        content="Nam eget est facilisis, porta mi ac, ultricies enim. Proin nisi diam, eleifend ac eleifend in, dapibus in orci. Vestibulum elementum lectus non nisl venenatis,&#8230;" >
    <meta name="keywords" content="test" >
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <meta name="author" content="" >

    <meta property="og:site_name" content="Real estate point" >
    <meta property="og:title" content="Real estate point - Test page" >
    <meta property="og:url" content="index.php/en/142/test-page.html" >
    <meta property="og:description"
        content="Nam eget est facilisis, porta mi ac, ultricies enim. Proin nisi diam, eleifend ac eleifend in, dapibus in orci. Vestibulum elementum lectus non nisl venenatis,&#8230;" >
    <meta property="og:image" content="../../assets/img/default-image-og.webp" >

    <link
    href="https://fonts.googleapis.com/css?family=Lora%7COpen+Sans:300,400,600,700%7CPlayfair+Display:400,700%7CPoppins:300,400,500,600,700%7CRaleway:300,400,500,600,700,800%7CRoboto:300,400,500,700&amp;display=swap&amp;subset=cyrillic&amp;display=swap"
    rel="stylesheet"
  />

</head>

<body>
    <div class="wrapper">
  <app-header></app-header>
        <section class="pager-sec bfr widget_edit_enabled">
            <div class="container">
                <div class="pager-sec-details">
                    <h3>{{cms_title}}</h3>
                    <ul class="breadcrumb">
                        <li><a href='/home'>Home</a><span class="delimiter"></span></li>
                        <li><span>{{cms_title}}</span><span class="delimiter"></span></li>
                    </ul>
                </div><!--pager-sec-details end-->
            </div>
        </section>
        <section class="listing-main-sec section-padding2">
            <div class="container">
                <div class="listing-main-sec-details">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="blog-single-post single">
                                <!-- <h3>{{cms_title}}</h3> -->
                                <!-- <p><span style="font-weight: bold;">Nam eget est facilisis, porta mi ac, ultricies enim.
                                        Proin nisi diam, eleifend ac eleifend in, dapibus in orci. Vestibulum elementum
                                        lectus non nisl venenatis, tempus molestie nisi tempus. Pellentesque facilisis
                                        nibh nec purus blandit, id aliquam lorem fermentum. </span></p> -->


                                <div class="">
                                    <!-- <div class="blog-img-cover">
                                        <div class="blog-img">
                                            <a  title="Test article 2">
                                                <img src="assets/img/real_estate_category_image_2.jpg" alt="Test article 2">
                                                </a><a  title="Test article 2" class="hover"></a>
                                        </div>
                                    </div> -->
                                    <div class="post_info">
                                        <!-- <ul class="post-nfo">
                                            <li><i class="fa fa-calendar"></i>
                                                June 15, 2015 </li>
                                           
                                        </ul>
                                        <h3>
                                            <a  title="Test article 2">Company Details
                                                </a>
                                        </h3> -->
                                        <div class="post-content clearfix" [innerHTML]="cms_content">
                                          
                                        </div>
                                        <!-- <a  title="Read more" class="btn-default">Read
                                            more</a> -->
                                    </div>
                                </div><!--post-share end-->
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <app-common-listing></app-common-listing>
                              </div>
                    
                   
                    </div>
                </div><!--listing-main-sec-details end-->
            </div>
        </section><!--listing-main-sec end-->
        <a  title="" class="widget_edit_enabled">
            <section class="cta section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="cta-text">
                                <h2>Discover a home you'll love to stay</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </a>
      <app-footer></app-footer>
    </div><!--wrapper end-->

   
</body>

</html>