import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/common';
declare var google: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: [
    './search.component.css',
    '../../assets/css/application.css',
    '../../styles.css',
  ],
})
export class SearchComponent {
  // mapCenter: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  state_name: any;
  property_type_id: any = 0;
  search_property: any;
  locations: any[] = [];
  property_type: any[] = [];
  currentInfoWindow: google.maps.InfoWindow | null = null;
  state_id: any;
  city_id: any;
  search_text: any = '';
  city_area_id: any;
  city_list: any[] = [];
  city_area_list: any[] = [];
  state_list: any[] = [];
  items: any[] = [];
  rating: any = 3;
  currentRate: number = 3;
  draw: any = 0;
  start: any = 0;
  length: any = 10;
  // zoom = 10;yt
  // @ViewChild('propertyDetails') propertyDetails!: ElementRef;
  // @ViewChild('googleMap') googleMap!: ElementRef;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    $('.alert-success').hide();
    $('.alert-danger').hide();
    $('#loadMoreSearch').hide();
    $('#onSubmitReview').hide();
    CommonService.ActiveClass('nav-search');
    let add_Property = localStorage.getItem('add_Property');
    let state_id: any = localStorage.getItem('state_id');
    let city_id: any = localStorage.getItem('city_id');
    let city_area_id: any = localStorage.getItem('city_area_id');
    let rating: any = localStorage.getItem('rating');
    let search_text: any = localStorage.getItem('search_text');
    if (state_id != undefined && state_id != null && state_id != '') {
      this.state_id = state_id;
    } else {
      this.state_id = 0;
    }
    if (city_id != undefined && city_id != null && city_id != '') {
      this.city_id = city_id;
    } else {
      this.city_id = 0;
    }
    if (
      city_area_id != undefined &&
      city_area_id != null &&
      city_area_id != ''
    ) {
      this.city_area_id = city_area_id;
    } else {
      this.city_area_id = 0;
    }
    if (rating != undefined && rating != null && rating != '') {
      this.rating = rating;
    }
    if (search_text != undefined && search_text != null && search_text != '') {
      this.search_text = search_text;
    }
    $('#show_no_result').hide();

    const baseUrl = this.document.location.origin;
    // console.log('Base URL:', baseUrl);
    this.items = [
      {
        value: 1,
        image: 'assets/img/1star.png',
      },
      {
        value: 2,
        image: 'assets/img/2star.png',
      },
      {
        value: 3,
        image: 'assets/img/3star.png',
      },
      {
        value: 4,
        image: 'assets/img/4star.png',
      },
      {
        value: 5,
        image: 'assets/img/5star.png',
      },
    ];
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }

    // this.city_id = 0;
    // this.state_id = 0;
    // this.property_type_id = 0;
    this.http
      .post(CommonService.api_url + '/property-type-list-internal', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
      })
      .subscribe((responseData: any) => {
        this.property_type = responseData['data'];
        // this.city_id=this.city_list[0].city_id;
      });
    this.http
      .post(CommonService.api_url + '/state-list-internal', {
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.state_list = responseData['data'];
        if (
          this.state_id == undefined ||
          this.state_id == null ||
          this.state_id == ''
        ) {
          // this.state_id = this.state_list[0].state_id;
          // this.onChangeState({ target: { value: this.state_id } });
        } else {
          this.onChangeState({ target: { value: this.state_id } });
        }
      });
    let search_property: any = localStorage.getItem('search_property');
    if (
      add_Property != undefined &&
      add_Property != null &&
      add_Property != ''
    ) {
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 4,
        center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
      });

      $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
      $('#onSubmitReview').show();

      $('#loadMore').hide();
      $('#show_no_result').show();
      $('#loadMoreSearch').hide();
      
      localStorage.removeItem('add_Property');
    } else {
      if (
        search_property != undefined &&
        search_property != null &&
        search_property != ''
      ) {
        this.search_property = JSON.parse(search_property);
        this.locations = this.search_property;
        this.onboundries();
        let draw = this.draw + 1;
        var recordsDisplayed = 10 * draw;
        let iTotalDisplayRecords:any=localStorage.getItem('iTotalDisplayRecords');
        // var totalRecords = responseData['iTotalRecords'];
        var totalRecords=iTotalDisplayRecords;
      
        var remainingRecords = totalRecords - recordsDisplayed;
        if (remainingRecords < 0) {
          $('#loadMoreSearch').hide();
          $('#loadMore').hide();
        } else {
          $('#loadMoreSearch').show();
          $('#onSubmitReview').hide();

          $('#loadMore').hide();
        }
      } else {
        let user_details: any = localStorage.getItem('agent_login');
        let user_id: any;
        let user_role_id: any;
        if (
          user_details == undefined ||
          user_details == null ||
          user_details == ''
        ) {
          user_id = 0;
        } else {
          let user = JSON.parse(user_details);
          user_role_id = user.user_role_id;
          user_id = user.user_id;
        }
        this.start = 0;
        this.draw = 0;
        let uploadData = new FormData();
        uploadData.append('oauth_key', CommonService.oauth_key);
        uploadData.append('user_id', user_id);
        uploadData.append('start', '0');
        uploadData.append('draw', '0');
        uploadData.append('length', '10');
        this.http
          .post(CommonService.api_url + '/get-all-property', uploadData)
          .subscribe((responseData: any) => {
            this.search_property = responseData['data'];
            this.locations = this.search_property;
            let draw = this.draw + 1;
            var recordsDisplayed = 10 * draw;
            var totalRecords = responseData['iTotalRecords'];
        
            var remainingRecords = totalRecords - recordsDisplayed;
            if (remainingRecords <= 0) {
              $('#loadMore').hide();
            } else {
              $('#loadMore').show();
              $('#onSubmitReview').hide();

            }
            this.onboundries();
          });
      }
    }
  }
 
  onboundries() {
    let boundries = this.calculateBoundaries(this.locations);
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 4,
      center: {
        lat: boundries?.center.property_latitude,
        lng: boundries?.center.property_longitude,
      },
    });

    for (let i = 0; i < this.locations.length; ++i) {
      const marker = new google.maps.Marker({
        position: {
          lat: parseFloat(this.locations[i].property_latitude),
          lng: parseFloat(this.locations[i].property_longitude),
        },
        map: map,
      });

      this.attachSecretMessage(
        marker,
        this.locations[i].property_id,
        this.locations[i].property_name,
        this.locations[i].property_details,
        this.locations[i].media_url
      );
    }
    localStorage.removeItem('search_property');
  }
  attachSecretMessage(
    marker: any,
    property_id: any,
    property_name: any,
    property_details: any,
    media_url: any
  ) {
    const infowindow = new google.maps.InfoWindow({
      content: `
        <div class="card">
          <a title="Bjelovar estate 1">
            <div class="img-block">
              <div class="overlay"></div>
              <div id="listing_carousel_30" class="carousel slide carousel-listing" data-ride="carousel" data-interval="false">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="${media_url}" alt="Bjelovar estate 1" style="height: 229px" class="d-block w-100 img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </a>
          <div class="card-body">
            <a title="Bjelovar estate 1">
              <p><i class="fas fa fa-map-marker"></i><span style=" font-size: 16px;
              color: #3c3c3c;
              font-weight: bold;"> ${property_details}</span></p>
            </a>
          </div>
        </div>
      `,
    });

    marker.addListener('click', () => {
      // infowindow.close();
      // infowindow.open(marker.get('map'), marker);
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close();
      }

      // Open the new InfoWindow
      infowindow.open(marker.get('map'), marker);

      // Update the current InfoWindow reference
      this.currentInfoWindow = infowindow;
    });
  }

  calculateBoundaries(locations: any) {
    if (locations.length == 0) {
      return null; // Return null if there are no locations
    }

    let leftmost = parseFloat(locations[0].property_longitude);
    let rightmost = parseFloat(locations[0].property_longitude);
    let topmost = parseFloat(locations[0].property_latitude);
    let bottommost = parseFloat(locations[0].property_latitude);

    locations.forEach((location: any) => {
      if (location.property_longitude < leftmost) {
        leftmost = parseFloat(location.property_longitude);
      }
      if (location.property_longitude > rightmost) {
        rightmost = parseFloat(location.property_longitude);
      }
      if (location.property_latitude > topmost) {
        topmost = parseFloat(location.property_latitude);
      }
      if (location.property_latitude < bottommost) {
        bottommost = parseFloat(location.property_latitude);
      }
    });
    // let latitude = (topmost + bottommost) / 2;
    // let longitude = (leftmost + rightmost) / 2;
    //     // Calculate the center of the boundaries

    //     if (!isNaN(latitude) && !isNaN(longitude)) {
    //    latitude = latitude;
    //    longitude = longitude;
    //     }
    //     else{
    //     latitude = locations[0].property_longitude;
    //     longitude= locations[0].property_latitude;
    //     }
    //    const center = {
    //     latitude:latitude,
    //     longitude:longitude,
    //     };
    const center = {
      property_latitude: (topmost + bottommost) / 2,
      property_longitude: (leftmost + rightmost) / 2,
    };

    return {
      leftmost,
      rightmost,
      topmost,
      bottommost,
      center,
    };
  }

  openModal(property_id: any) {
    localStorage.setItem('property_id', property_id);
    this.router.navigateByUrl('/property-details');
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onChangeState(event: any) {
    // API for city list
    this.city_list = [];
    this.city_area_list = [];
    let user_details: any = localStorage.getItem('agent_login');
    let user_id: any;
    let user_role_id: any;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_role_id = user.user_role_id;
      user_id = user.user_id;
    }
    const state_id = parseInt(event.target.value);
    // this.state_name = this.state_list.filter(
    //   (ele: any) => ele.state_id == event
    // )[0].state_name;
    if (state_id > 0) {
      this.http
        .post(CommonService.api_url + '/city-list-internal', {
          state_id: state_id,
          oauth_key: CommonService.oauth_key,
          user_id: user_id,
        })
        .subscribe((responseData: any) => {
          this.city_list = responseData['data'];
          if (
            this.city_id == undefined ||
            this.city_id == null ||
            this.city_id == ''
          ) {
            // this.city_id = this.city_list[0].city_id;
            // this.onChangeCity({ target: { value: this.city_id } });
          } else {
            this.city_id = this.city_id;
            this.onChangeCity({ target: { value: this.city_id } });
          }
        });
    }
  }
  // Change event of city dropdown
  onChangeCity(event: any) {
    // API for city area list
    this.city_area_list = [];
    let user_details: any = localStorage.getItem('agent_login');
    let user_id: any;
    let user_role_id: any;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_role_id = user.user_role_id;
      user_id = user.user_id;
    }

    const city_id = parseInt(event.target.value);
    if (city_id > 0) {
      this.http
        .post(CommonService.api_url + '/get-city-area-list-internal', {
          city_id: city_id,
          oauth_key: CommonService.oauth_key,
          user_id: user_id,
        })
        .subscribe((responseData: any) => {
          this.city_area_list = responseData['data'];
          if (
            this.city_area_id == undefined ||
            this.city_area_id == null ||
            this.city_area_id == ''
          ) {
            // this.city_area_id = 0;
          } else {
            this.city_area_id = this.city_area_id;
          }
        });
    }
  }
  searchProperty() {
    // if(this.validation()==true){
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let rating: any;
    let city_id: any;
    let city_area_id: any;
    let state_id: any;
    if (city_id != '') {
      city_id = parseInt(this.city_id);
    } else {
      city_id = 0;
    }
    if (city_area_id != '') {
      city_area_id = parseInt(this.city_area_id);
    } else {
      city_area_id = 0;
    }
    if (state_id != '') {
      state_id = parseInt(this.state_id);
    } else {
      state_id = 0;
    }
    if (rating != '') {
      rating = parseInt(this.rating);
    } else {
      rating = 0;
    }
    if (
      this.search_text == '' ||
      this.search_text == undefined ||
      this.search_text == null
    ) {
      this.search_text = '';
    }
    this.start = 0;
    this.draw = 0;
    this.http
      .post(CommonService.api_url + '/search-property', {
        city_id: city_id,
        state_id: state_id,
        property_area: city_area_id,
        property_type: this.property_type_id,
        property_address: this.search_text,
        review_score: rating,
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
        start: 0,
        draw: 0,
        length: 10,
      })
      .subscribe((responseData: any) => {
        // this.city_area_list = responseData['data'];
        if (responseData['is_successful'] == '1') {
          // this.router.navigateByUrl('/search');
          if (
            responseData['data'].length > 0 &&
            responseData['data'] != undefined &&
            responseData['data'] != null
          ) {
            // if (responseData['iTotalDisplayRecords'] <= 10) {
            //   $('#loadMoreSearch').hide();
            // } else {
            //   $('#loadMoreSearch').show();
            // }
            let draw = this.draw + 1;
            var recordsDisplayed = 10 * draw;
            var totalRecords = responseData['iTotalDisplayRecords'];
            var remainingRecords = totalRecords - recordsDisplayed;
            if (remainingRecords <= 0) {
              $('#loadMoreSearch').hide();
              $('#loadMore').hide();
            } else {
              $('#loadMoreSearch').show();
              $('#loadMore').hide();
              $('#onSubmitReview').hide();
            }
            // localStorage.setItem(
            //   'search_property',
            //   JSON.stringify(responseData['data'])
            // );
            // window.location.reload();
            this.search_property = responseData['data'];
            this.locations = responseData['data'];
            this.onboundries();
            $('#show_no_result').hide();
            $('#search-container-property').show();
          }
          if (
            responseData['data'].length == 0 &&
            responseData['data'] != undefined &&
            responseData['data'] != null
          ) {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 4,
              center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
            });
            $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
            $('#onSubmitReview').show();

            $('#loadMore').hide();
            $('#loadMoreSearch').hide();
            $('#search-container-property').css('display', 'none');

            $('#show_no_result').show();
          }
        }
      });
    // }
  }
  onLoadMoreClick() {
    this.draw = parseInt(this.draw) + 1;
    this.start = 10 * this.draw;
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let rating: any = parseInt(this.rating);
    let city_id: any = parseInt(this.city_id);
    let city_area_id: any = parseInt(this.city_area_id);
    let state_id: any = parseInt(this.state_id);
    if (city_id == '' || city_id == undefined || city_id == null) {
      city_id = 0;
    }
    if (
      city_area_id == '' ||
      city_area_id == undefined ||
      city_area_id == null
    ) {
      city_area_id = 0;
    }
    if (state_id == '' || state_id == undefined || state_id == null) {
      state_id = 0;
    }
    if (rating == '' || rating == undefined || rating == null) {
      rating = 0;
    }
    if (
      this.search_text == '' ||
      this.search_text == undefined ||
      this.search_text == null
    ) {
      this.search_text = '';
    }
    if (
      this.property_type_id == '' ||
      this.property_type_id == undefined ||
      this.property_type_id == null
    ) {
      this.property_type_id = 0;
    }

    let uploadData = new FormData();
    uploadData.append('oauth_key', CommonService.oauth_key);
    uploadData.append('user_id', user_id);
    uploadData.append('start', this.start);
    uploadData.append('draw', this.draw);
    uploadData.append('length', '10');
    this.http
      .post(CommonService.api_url + '/get-all-property', uploadData)

      .subscribe((responseData: any) => {
        // this.city_area_list = responseData['data'];
        if (responseData['is_successful'] == '1') {
          if (
            responseData['data'].length > 0 &&
            responseData['data'] != undefined &&
            responseData['data'] != null
          ) {
            let draw = this.draw + 1;
            var recordsDisplayed = 10 * draw;
            var totalRecords = responseData['iTotalRecords'];
            var remainingRecords = totalRecords - recordsDisplayed;
            if (remainingRecords <= 0) {
              $('#loadMore').hide();
            } else {
              $('#loadMore').show();
              $('#onSubmitReview').hide();

            }
            let data = responseData['data'];
            for (var i = 0; i < data.length; i++) {
              this.search_property.push(data[i]);
            }
            this.locations = this.search_property;
            this.onboundries();
            $('#show_no_result').hide();
            $('#search-container-property').show();
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 4,
              center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
            });

            $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
            $('#onSubmitReview').show();

            $('#loadMore').hide();
            $('#loadMoreSearch').hide();
            $('#search-container-property').css('display', 'none');
            $('#show_no_result').show();
          }
        } else {
          const map = new google.maps.Map(document.getElementById('map'), {
            zoom: 4,
            center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
          });

          $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
          $('#onSubmitReview').show();

          $('#loadMore').hide();
          $('#loadMoreSearch').hide();
          $('#search-container-property').css('display', 'none');
          $('#show_no_result').show();
        }
      });
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  onLoadMoreClickSearch() {
    this.draw = parseInt(this.draw) + 1;
    // this.length = parseInt(this.length) + 10;
    this.start = this.draw * 10;
    let user_id;
    let user_details: any = localStorage.getItem('agent_login');

    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }
    let rating: any;
    let city_id: any;
    let city_area_id: any;
    let state_id: any;
    if (city_id != '') {
      city_id = parseInt(this.city_id);
    } else {
      city_id = 0;
    }
    if (city_area_id != '') {
      city_area_id = parseInt(this.city_area_id);
    } else {
      city_area_id = 0;
    }
    if (state_id != '') {
      state_id = parseInt(this.state_id);
    } else {
      state_id = 0;
    }
    if (rating != '') {
      rating = parseInt(this.rating);
    } else {
      rating = 0;
    }
    // let uploadData = new FormData();
    // uploadData.append('oauth_key', CommonService.oauth_key);
    // uploadData.append('user_id', user_id);
    // uploadData.append('start', this.start);
    // uploadData.append('draw', this.draw);
    // uploadData.append('length', this.length);
    this.http
      .post(CommonService.api_url + '/search-property', {
        city_id: city_id,
        state_id: state_id,
        property_area: city_area_id,
        property_type: this.property_type_id,
        property_address: this.search_text,
        review_score: rating,
        user_id: user_id,
        oauth_key: CommonService.oauth_key,
        start: this.start,
        draw: this.draw,
        length: 10,
      })

      .subscribe((responseData: any) => {
        // this.city_area_list = responseData['data'];
        if (responseData['is_successful'] == '1') {
          if (
            responseData['data'].length > 0 &&
            responseData['data'] != undefined &&
            responseData['data'] != null
          ) {
            let draw = this.draw + 1;
            var recordsDisplayed = 10 * draw;
            var totalRecords = responseData['iTotalMatchRecords'];
            var remainingRecords = totalRecords - recordsDisplayed;
            if (remainingRecords <= 0) {
              $('#loadMoreSearch').hide();
              $('#loadMore').hide();
            } else {
              $('#loadMoreSearch').show();
              $('#onSubmitReview').hide();

              $('#loadMore').hide();
            }
            let data = responseData['data'];
            for (var i = 0; i < data.length; i++) {
              this.search_property.push(data[i]);
            }
            this.locations = this.search_property;
            this.onboundries();
            $('#show_no_result').hide();
            $('#search-container-property').show();
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 4,
              center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
            });

            $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
            $('#loadMore').hide();
            $('#loadMoreSearch').hide();
            $('#search-container-property').css('display', 'none');
            $('#show_no_result').show();
            $('#onSubmitReview').show();

            
          }
        } else {
          const map = new google.maps.Map(document.getElementById('map'), {
            zoom: 4,
            center: { lat: 43.7182412, lng: -79.3780581 }, // Centered at (0, 0) coordinates
          });

          $('#show_no_result').html("No results found. Please click on 'Submit Review' to add the property");
          $('#onSubmitReview').show();
          $('#loadMore').hide();
          $('#loadMoreSearch').hide();
          $('#search-container-property').css('display', 'none');
          $('#show_no_result').show();
        }
      });
    localStorage.removeItem('search_text');
    localStorage.removeItem('city_id');
    localStorage.removeItem('city_area_id');
    localStorage.removeItem('state_id');
    localStorage.removeItem('rating');
  }
  // validation(){
  //   $('.alert-danger').hide();
  //   let error_message = '';
  //   let isAtLeastOneFieldFilled = false;
  //   if (
  //     this.search_text!='' ||

  //     this.state_id != 0 ||  // Include 0 values in the check
  //     this.city_area_id != 0 ||
  //     this.city_id != 0 ||
  //     this.property_type_id != 0
  //   ) {
  //     isAtLeastOneFieldFilled = true;
  //   }
  // if(!isAtLeastOneFieldFilled){
  //   $('.alert-danger').html('At least one field is required for search.');
  //   $('.alert-success').hide();
  //   $('.alert-danger').show();
  //   return false;
  // }
  // else{
  //   return true;
  // }
  // }
  onSubmitReview(){
   this.router.navigateByUrl('/submit-review');
  }
}
