import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css',
  '../../assets/css/application.css',
  '../header/header.component.css',]
})
export class PrivacyPolicyComponent {
  cms_content: any;
cms_title:any;
  user_id: any;
  blog_categories: any[] = [];
  blog_popular_listing: any[] = [];
  ngOnInit() {
    window.scrollTo(0,0);
    let user_details: any = localStorage.getItem('agent_login');
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      this.user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      this.user_id = user.user_id;
    }

    this.http
      .post(CommonService.api_url + '/get-cms', {
        user_id: this.user_id,
        cms_id: 2,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.cms_content = responseData['data']['cms_content'][0]['cms_content'];
        this.cms_title=responseData['data']['cms_content'][0]['cms_name'] ;
      });
    this.http
      .post(CommonService.api_url + '/get-blog-categories', {
        user_id: this.user_id,
        oauth_key: CommonService.oauth_key,
      })
      .subscribe((responseData: any) => {
        this.blog_categories = responseData['data'];
      });
    this.http
      .post(CommonService.api_url + '/get-popular-reviews', {
        oauth_key: CommonService.oauth_key,
        user_id: this.user_id,
      })
      .subscribe((responseData: any) => {
        this.blog_popular_listing = responseData['data'];
      });
  }
  constructor(
    private router: Router,
    private commonService: CommonService,
    private http: HttpClient
  ) {}
  onCategoryClick(blog_category_id: any, category_name: any) {
    let user_details: any = localStorage.getItem('agent_login');
    let user_id;
    if (
      user_details == undefined ||
      user_details == null ||
      user_details == ''
    ) {
      user_id = 0;
    } else {
      let user = JSON.parse(user_details);
      user_id = user.user_id;
    }

    this.http
      .post(CommonService.api_url + '/get-Blogs-By-Category', {
        oauth_key: CommonService.oauth_key,
        user_id: user_id,
        blog_category_id: blog_category_id,
      })
      .subscribe((responseData: any) => {
        let blog_categories = JSON.stringify(responseData['data']);
        localStorage.setItem('blog_categories', blog_categories);
        localStorage.setItem('blog_category_name', category_name);
        this.router.navigateByUrl('/blog-category');
      });
  }
  onPopularListingClick(property_id: any) {
    localStorage.setItem('property_id', property_id);
    this.router.navigateByUrl('/property-details');
  }

}
